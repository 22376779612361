import React from "react";
import Header from "../components/DashboardArea/Header/Header";
import Sidebar from "../components/DashboardArea/Sidebar/Sidebar";

const BaseLayout = ({ children, addClass }) => {
  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dasboard main_container">
        <Header />
        <div className={`dashboard ${addClass ? "post_page_only" : ""} `}>
          <div className="container-fluid main_container">
            <div className="row">
              <div className="col-lg-12 col-sm-12">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
