import React, { useState, useEffect } from "react";
import "../LoginRegister/LoginRegister.css";
import axios from "axios";
import Url from "../Element/Api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Verified from "../../assets/verified.svg";
import LoginLayout from "../../layouts/LoginLayout";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshToken, setRegToken, setTokenExpTime } from "../../Redux/Action/Authaction";

function EmailVerified() {
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const regtoken = useSelector((state) => state.regToken);
  const [verificationMessage, setVerificationMessage] = useState("");

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(Url.BASEURL + "/broker/" + id?.id + "/verify/" + id?.token)
        .then((res) => {
          dispatch(setRegToken(res.data.accessToken));
          dispatch(setRefreshToken(res?.data?.refreshToken));
          dispatch(setTokenExpTime(res?.data?.expirationTime));
          setError("");
          setVerificationMessage("");
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message);
            if (err.response.data.message === "Email already verified") {
              setVerificationMessage("Your email address has already been verified.");
            }
          } else {
            setError("An error occurred while processing your request.");
          }
        });
    };

    fetchData();
  }, [id]);

  return (
    <LoginLayout>
      {regtoken && (
        <div className="email_verification verification_done">
          <h3>Email Verification</h3>
          <p>We noticed your email address has not been verified. By doing so, you will receive important notifications and information about your account.</p>
          <img src={Verified} alt="verified" />

          <h4>Your Email Address is Now Verified</h4>
          <button
            onClick={() => {
              navigate("/broker-profile");
            }}
            className="logreg_btn btn btn-primary"
          >
            Next
          </button>
        </div>
      )}

      {!regtoken && (
        <div className="email_verification verification_done">
          <h3>Email Verification</h3>
          <p>Your email address has been successfully verified. You can now continue to log in to your account.</p>
          <img src={Verified} alt="verified" />

          <h4>{verificationMessage}</h4>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="logreg_btn btn btn-primary"
          >
            Login
          </button>
        </div>
      )}
    </LoginLayout>
  );
}

export default EmailVerified;
