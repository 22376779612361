import React, { useState } from "react";
import axios from "axios";
import Slider from "rc-slider";
import Url from "../../Element/Api";
import { useNavigate } from "react-router";
import useAuth from "../../../hooks/useAuth";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setAgentType } from "../../../Redux/Action/Authaction";

const UpdateAgentTypeModal = ({ show, onClose }) => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [inputValue, setInputValue] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [agentType, setAgentTypeBroker] = useState(0);
  const brokerProfileDetails = useSelector((state) => state.brokerProfileDetails);

  const handleSave = () => {
    const updatedFormData = {
      numberOfAgents: value,
      agentType: agentType,
      firstname: brokerProfileDetails?.firstname,
      lastname: brokerProfileDetails?.lastname,
      phone: brokerProfileDetails?.phone?.toString(),
      email: brokerProfileDetails?.email,
      companyName: brokerProfileDetails?.companyName,
      bio: brokerProfileDetails?.bio,
      faxId: brokerProfileDetails?.contactDetails?.faxId,
      address: brokerProfileDetails?.contactDetails?.address,
      license: brokerProfileDetails?.contactDetails?.license,
      serviceArea: brokerProfileDetails?.serviceArea,
      city: brokerProfileDetails?.contactDetails?.city,
      state: brokerProfileDetails?.contactDetails?.state,
      zipCode: brokerProfileDetails?.contactDetails?.zipCode,
    };

    axios
      .post(`${Url.BASEURL}/broker/update-profile`, updatedFormData, config)
      .then((res) => {
        setIsDisable(false);
        dispatch(setAgentType(res?.data?.agentType));
        navigate("/choose-package");
        onClose();
      })
      .catch((error) => {
        console.log("err", error);
        handleClose();
      });
  };

  const handleClose = () => {
    setIsDisable(false);
    setAgentTypeBroker(0);
    setValue(0);
    setInputValue(0);
    onClose();
  };

  const onInputChange = (e) => {
    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 2000) {
      newValue = 2000;
    }
    newValue = newValue.toString();
    setValue(newValue);
    setInputValue(newValue);
  };

  const onSliderChange = (newValue) => {
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 2000) {
      newValue = 2000;
    }
    setValue(newValue);
    setInputValue(newValue);
  };

  return (
    <Modal className="realtor_modal" show={show} onHide={onClose} centered>
      <Modal.Body>
        <div className="pt-3 px-3">
          <h3 className="text-center">Become a Broker?</h3>

          <div className="mt-4">
            <div className="d-flex align-items-start">
              <label className="custom-checkbox-container" style={{ marginTop: "5px" }}>
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  value={agentType === 1}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setAgentTypeBroker(1);
                    } else {
                      setAgentTypeBroker(0);
                    }
                  }}
                />
                <span className="checkmark"></span>
              </label>
              <p style={{ margin: "0px", color: "#787D8C", fontSize: "16px" }}>Are you sure you want to switch your profile and add realtors in your profile?</p>
            </div>

            {agentType === 1 && (
              <div className="hidden_selector_realtor mt-5" style={{ marginLeft: "2px" }}>
                <div className="chauka px-4">
                  <input type="number" value={inputValue} onChange={onInputChange} className="form-control" />
                  <Slider min={0} max={2000} value={value} onChange={onSliderChange} className="my-slider" />
                </div>
                <p style={{ color: "#787D8C", fontSize: "14px" }}>Select the number of realtors you'd like to add to your profile.</p>
              </div>
            )}
          </div>
          <div className="delete-modal-actions gap-5">
            <Button disabled={isDisable || agentType === 0} className="primary" variant={"primary"} onClick={handleSave}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleClose} style={{ backgroundColor: "#fff", color: "#505564" }}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateAgentTypeModal;
