import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const data = [
  { month: "Jan 2023", medianList: 10000, medianSold: 200000 },
  { month: "Feb 2023", medianList: 200000, medianSold: 100000 },
  { month: "Mar 2023", medianList: 30000, medianSold: 1250 },
  { month: "Apr 2023", medianList: 502144, medianSold: 260000 },
  { month: "May 2023", medianList: 0, medianSold: 260000 },
  { month: "June 2023", medianList: 310000, medianSold: 521254 },
  { month: "July 2023", medianList: 310000, medianSold: 260000 },
  { month: "Aug 2023", medianList: 340000, medianSold: 290000 },
];

const ActivityChart = () => {
  const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#B4B9C8" className="tick-label">
          {payload.value}
        </text>
        r
      </g>
    );
  };

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;
    const formattedTick = `$${payload.value / 1000}K`;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dx={-16} textAnchor="end" fill="#B4B9C8" className="tick-label">
          {formattedTick}
        </text>
      </g>
    );
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="mutual_blocks my_top ">
          <div className="heading_inline d-flex">
            <div className="left_head">
              <h2>Activity Log</h2>
            </div>
            {/* <div className="right_head">
              <Link to="" className="details-link">
                More Details
              </Link>
            </div>*/}
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeWidth={0.5} vertical={false} stroke="#efefef" />
              <XAxis dataKey="month" tick={<CustomizedXAxisTick />} />
              <YAxis tick={<CustomizedYAxisTick />} domain={[0, 1000000]} />
              <Tooltip />
              <Area type="monotone" dataKey="medianSold" stackId="1" stroke="#007AFF" fill="#007AFF" fillOpacity={1} />
              <Area type="monotone" dataKey="medianList" stackId="1" stroke="#22A9E0" fill="#22A9E0" fillOpacity={1} />
            </AreaChart>
          </ResponsiveContainer>
          <div className="mean_median d-flex">
            <div className="left_mean">
              <span></span> Median List Price
            </div>
            <div className="right_mean">
              <span></span> Median Sold Price
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityChart;
