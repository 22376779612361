import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Url from "../Element/Api";
import "../Listing/MlsListing.css";
import { useSelector } from "react-redux";
import Sliders from "../../assets/s3.svg";
import useAuth from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import MlsListingData from "./MlsListingData";
import BaseLayout from "../../layouts/BaseLayout";
import ClipLoader from "react-spinners/ClipLoader";
import { NextLabel, PreviousLabel, handleDropdownClose } from "../../utils/utils";

function Mlslisting() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const userType = useSelector((state) => state.userType);
  // const userType = localStorage.getItem("userType");
  const [mlsData, setMlsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalMlsData, setTotalMlsData] = useState(0);
  const [selectedOption, setSelectedOption] = useState("All");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const itemsPerPage = 12;
  const totalResults = totalPage;
  const totalPages = Math.ceil(totalResults / itemsPerPage);
  const currentPageAdjusted = Math.max(currentPage, 1);
  const indexOfFirstItem = (currentPageAdjusted - 1) * itemsPerPage + 1;
  const indexOfLastItem = Math.min(currentPageAdjusted * itemsPerPage, totalMlsData);
  let AgentdropdownOptions;
  // const [statusFilter, setStatusFilter] = useState("");
  // const [priceSort, setPriceSort] = useState("");
  // const [dateSort, setDateSort] = useState("");
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const AgentdropdownOptions = ["Active", "Inactive", "Latest Posted", "Oldest Posted", "Latest Updated", "Oldest Updated", "Price (Low to High)", "Price (High to Low)" ];

  if (userType == 1) {
    AgentdropdownOptions = ["All", "Active", "Inactive", "Latest Posted", "Oldest Posted", "Latest Updated", "Oldest Updated", "Price (Low to High)", "Price (High to Low)"];
  } else {
    AgentdropdownOptions = ["All", "Latest Posted", "Oldest Posted", "Latest Updated", "Oldest Updated", "Price (Low to High)", "Price (High to Low)"];
  }

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  // const handleStatusFilterChange = (status) => {
  //   setStatusFilter(status);
  // };

  // const handlePriceSortChange = (sort) => {
  //   // map the frontend option to expected backend values
  //   const backendSort = sort === "lowToHigh" ? "lowToHigh" : "highToLow";
  //   setPriceSort(backendSort);
  // };

  // const handleDateSortChange = (sort) => {
  //   setDateSort(sort);
  // };

  // To get the mls listing, filter & search results
  const fetchData = async () => {
    setLoading(true);
    let queryParams = {
      page: currentPage,
      search: searchTerm,
    };
    if (selectedOption !== "All") {
      if (selectedOption === "Active" || selectedOption === "Inactive") {
        queryParams.status = selectedOption;
      } else if (selectedOption === "Price (Low to High)" || selectedOption === "Price (High to Low)") {
        queryParams.sortPrice = selectedOption === "Price (Low to High)" ? "lowToHigh" : "highToLow";
      } else if (AgentdropdownOptions.includes(selectedOption)) {
        queryParams.sortDate = selectedOption === "Latest Posted" ? "latestPosted" : selectedOption === "Oldest Posted" ? "oldestPosted" : selectedOption === "Latest Updated" ? "latestUpdated" : "oldestUpdated";
      }
    }
    try {
      const response = await axios.get(Url.BASEURL + `/mls/reso/global-search-result`, {
        params: queryParams,
        headers: config.headers,
      });
      setMlsData(response.data.properties.docs);
      setTotalMlsData(response.data.properties.total);
      setTotalPage(response.data.properties.pages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if ((currentPage, searchTerm?.length !== 0, selectedOption)) {
      fetchData();
    }
  }, [currentPage, searchTerm, selectedOption]);

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages listing_pager_only table_view_only">
        <h2>Listing</h2>
        {/* <div className="blog_add">
          <Link to="/add-blog">
            <div className="Add_new_blog btn update_btn">Add New</div>
          </Link>
        </div> */}
        <div className="listing_table_broker">
          <div className="row mb-2 curlter ">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett jeter">
              <div className="lisingenumbers">{`${indexOfFirstItem}-${indexOfLastItem} of ${totalMlsData} Results`}</div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Property Direction"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="btn_block">
                  <button className="btn btn-primary" onClick={() => setSearchTerm("")}>
                    Search
                  </button>
                </div> */}
              </div>
              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div
                      className="selected_option"
                      onClick={() => {
                        if (!isDropdownOpen) {
                          setIsDropdownOpen(true);
                        }
                      }}
                      style={{ backgroundImage: `url(${Sliders})` }}
                    >
                      {selectedOption || "All"}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p
                            key={index}
                            onClick={() => {
                              setSelectedOption(option);
                              setIsDropdownOpen(false);
                            }}
                          >
                            {option}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive mls_listing_only ">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="prop_wid_set" scope="col" style={{ width: "40%" }}>
                        Property Address
                      </th>
                      <th scope="col">
                        Published <span>&nbsp;&nbsp; - &nbsp;&nbsp;</span> Updated Date
                      </th>
                      <th scope="col">Local Realtor</th>
                      <th scope="col">Price</th>
                      {userType == 1 && <th scope="col">Status</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <div className="bholss">
                        <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                      </div>
                    ) : (
                      <>
                        {mlsData.length > 0 ? (
                          mlsData.map((mls, idx) => <MlsListingData key={idx} mls={mls} onSetMlsData={(value) => setMlsData(value)} />)
                        ) : (
                          <tr className="text-center">
                            <td
                              colSpan="9"
                              style={{
                                fontSize: 16,
                                marginTop: "10px",
                              }}
                            >
                              No data available
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {totalPage > 1 && (
                <div className="all_pagination">
                  <ReactPaginate
                    previousLabel={<PreviousLabel />}
                    nextLabel={<NextLabel />}
                    breakLabel={"..."}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      setCurrentPage(e.selected + 1);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
export default Mlslisting;
