import React from "react";

const DefaultLayout = ({ children }) => {
  return (
    <div className="dashboard-container">
      <div className="header-dasboard main_container">
        <div className="default-dashboard ">
          <div className="container-fluid main_container">
            <div className="row">
              <div className="col-lg-12">
                <div className="payment-broker">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
