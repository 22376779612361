import React, { useState } from "react";

const AddTagModal = ({ newTagName, handleInputChange, addTagHandler, onClose }) => {
  const [err, setErr] = useState("");

  return (
    <div className="modal fade tagger" id="addTagModal" tabIndex="-1" aria-labelledby="addTagModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="title_bar_setting tag_add_new">
              <h3>Tag Name</h3>

              <div className="form-group">
                <input autoFocus type="text" className="form-control" style={{ textTransform: "capitalize" }} value={newTagName} onChange={handleInputChange} placeholder="Write your tag" />
                {err !== "" && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", marginTop: "2px", fontSize: "13px" }}>{err}</p>}
              </div>
            </div>

            <div className="two_btns">
              <button
                className="btn update_btn saver"
                data-bs-dismiss="modal"
                onClick={() => {
                  setErr("");
                  if (newTagName === "") {
                    setErr("Tag name is not allowed to be empty");
                  } else {
                    addTagHandler();
                  }
                }}
              >
                Save
              </button>

              <button
                className="btn cancel_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setErr("");
                  onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTagModal;
