import "./Realtor.css";
import React, { useState } from "react";
import Logo from "../../assets/logo.png";
import Edit from "../../assets/pencil.svg";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";

function RealtorProfileSetup() {
  const [imagePreview, setImagePreview] = useState("https://via.placeholder.com/150");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container container_bg custom_principal_page">
        <div className="row ">
          <div className="col-lg-12">
            <div className="principal_broker">
              <div className="top_principal d-flex">
                <div className="left_principal image_uploader">
                  <img src={imagePreview} alt="Uploaded" className="responsive_image" />
                  <input type="file" onChange={handleImageChange} style={{ display: "none" }} id="imageInput" />
                  <label htmlFor="imageInput" className="edit-icon-image">
                    <img src={Edit} alt="" />
                  </label>
                </div>
                <div className="right_principal title_bar_setting">
                  <div className="single_entry">
                    <label>
                      Profile Tagline
                      <input type="text" className="form-control" placeholder="Premier Reality Group" />{" "}
                    </label>
                  </div>

                  <div className="principal_text_area">
                    <h3>Bio </h3>
                    <div className="form-group">
                      <textarea id="ampHeaderTitle" className="form-control area_control" placeholder="As a dedicated and knowledgeable real estate agent I can provide you with the highly specialized information that you will need to make the right decision. Its the combination of this unique market-knowledge and excellent negotiating skills that enable me to get you the optimal price for any property you plan to sell or buy." />
                    </div>
                  </div>
                </div>

                <div className="contact_detail title_bar_setting">
                  <h3 className="letter_darkbold">Contact Details</h3>

                  <div className="contract_rows d-flex">
                    <div className="left_contact_dt">
                      <div className="single_entry">
                        <input type="text" className="form-control" placeholder="20001 SW Tualatin Valley Hwy Beaverton, OR 97003" />
                      </div>
                    </div>
                    <div className="right_cont_dt">
                      <div className="single_entry">
                        <input type="text" className="form-control" placeholder="Licence : CASDD45744CCS" />
                      </div>
                    </div>
                  </div>

                  <div className="bottom_realtor_profiles second_input">
                    <h3 className="letter_darkbold">Meta Title</h3>

                    <div className="form-group">
                      <label htmlFor="ampHeaderTitle">This title will be appended to the page name for each of your pages, unless you override it by providing a page specific meta title in its Advanced Page Settings.</label>
                      <div className="d-flex custom_fcontrol">
                        <div className="left_farea">
                          <input type="text" id="metaTitleInput" className="form-control" placeholder="Banks Real Estate | All Professional Real Estates" />
                        </div>
                        <div className="right_farea">Should be no more than 8-10 words</div>
                      </div>
                    </div>

                    <div className="title_bar_setting second_input">
                      <h3 className="letter_darkbold">Meta Description</h3>
                      <div className="form-group">
                        <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                        <div className="d-flex custom_fcontrol">
                          <div className="left_farea">
                            <input type="text" id="metaDescriptionInput" className="form-control" placeholder="Banks Homes for Sale, Property Search in Banks" />
                          </div>
                          <div className="right_farea">Should be no more than 2 to 3 sentences</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="selector_btn">
                  <button className="btn update_btn">Explore Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealtorProfileSetup;
