import React from "react";
import { getInitials } from "../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";

const TeamMate = ({ mate, onToggleVisibility, hiddenBlocks, onSetOnSaveTeammateData, onSetIsPendingPopupVisible }) => {
  const navigate = useNavigate();
  let statusClass;
  let statusText = "";
  let id = mate._id;
  let phone = mate.phone;
  let email = mate.email;
  let status = mate.status;
  let lastname = mate.lastname;
  let firstname = mate.firstname;
  let imgSrc = mate.image ? mate.imageURL : "";
  let name = mate.firstname + " " + mate.lastname;

  const handleClick = () => {
    if (status === 2) {
      onSetIsPendingPopupVisible(true);
      onSetOnSaveTeammateData({ email, firstname, lastname, phone });
    } else {
      navigate(`/profile/${id}`);
    }
  };

  switch (status) {
    case 0:
      statusText = "Inactive";
      statusClass = "status-actives";
      break;
    case 1:
      statusText = "Active";
      statusClass = "status-inactives";
      break;
    case 2:
      statusText = "Pending";
      statusClass = "status-pendings";
      break;
    case 3:
      statusText = "Delinquents";
      statusClass = "status-delinquents";
      break;
    default:
      statusClass = "status-unknowns";
  }

  return (
    <div role="button" className={`teammatess ${hiddenBlocks[name] ? "hidden" : ""}`} onClick={handleClick}>
      {imgSrc ? <img src={imgSrc} alt={name} className="teammate-img" /> : <div className="teammate-initials">{getInitials(name)}</div>}
      <Link to={`/profile/${id}`} style={{ pointerEvents: status === 2 ? "none" : "auto" }}>
        <div className="teammate-info">
          <h3 className="text-capitalize">{name}</h3>
          <div className="two_icons d-flex">
            <div className="single_icons" onClick={onToggleVisibility}>
              <h2 className={statusClass}>{statusText}</h2>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TeamMate;
