import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Phone from "../../assets/ti1.svg";
import { useSelector } from "react-redux";
import Message from "../../assets/ti2.svg";
import ArrowUp from "../../assets/arrow_up.svg";
import ArrowDown from "../../assets/arrow_down.svg";
import { handleDropdownClose } from "../../utils/utils";

const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "200px",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 1 }),
};

const LeadData = ({ lead, realtors, assignLeadsToRealtors, isLastLead, onSetIsDropdown, totalItems }) => {
  const dropdownRef = useRef(null);
  const userType = useSelector((state) => state.userType);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [dropdownType, setDropdownType] = useState("");
  const [expandedLeads, setExpandedLeads] = useState({});

  const toggleDropdown = (postId, type) => {
    if (openDropdown === postId && dropdownType === type) {
      setOpenDropdown(null);
      setDropdownType("");
    } else {
      setOpenDropdown(postId);
      setDropdownType(type);
    }
  };

  const toggleExpansion = (leadId) => {
    setExpandedLeads((prevExpandedLeads) => ({
      ...prevExpandedLeads,
      [leadId]: !prevExpandedLeads[leadId],
    }));
  };

  useEffect(() => {
    const handleClose = () => {
      setOpenDropdown(null);
      setDropdownType("");
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  return (
    <tr key={lead._id}>
      <td className="text-capitalize">
        {lead.firstname} {lead.lastname}
      </td>

      <td className="font_set d-flex tset">
        {lead.selectedBudget && (
          <div className="lopa">
            {`${lead.selectedBudget.minPrice !== null ? `$${lead.selectedBudget.minPrice}` : ""} - ${lead.selectedBudget.maxPrice !== null ? `$${lead.selectedBudget.maxPrice}` : ""}`}
            {`${lead.selectedBudget.minMonthly !== null ? `$${lead.selectedBudget.minMonthly} / Mo` : ""}  ${lead.selectedBudget.maxMonthly !== null ? `$${lead.selectedBudget.maxMonthly} / Mo` : ""}`}
          </div>
        )}
      </td>

      <td className="author-tags">
        {lead.location} ({lead.zipCode})
      </td>

      <td className="author-tags leads_expand">
        {lead.propertyType && lead.propertyType.map((type, index) => <div key={index}>{expandedLeads[lead._id] || index < 1 ? type : null}</div>)}
        {lead.propertyType && lead.propertyType.length > 1 && <img src={expandedLeads[lead._id] ? ArrowUp : ArrowDown} alt={expandedLeads[lead._id] ? "Collapse" : "Expand"} onClick={() => toggleExpansion(lead._id)} style={{ cursor: "pointer", animation: `${expandedLeads[lead._id] ? "arrowUp" : "arrowDown"} 1s infinite` }} className="golu" />}
      </td>

      <td className="font_set commnts shements">
        <div className="two_bloker d-flex">
          <div role="button" className="single_blocker" onClick={() => toggleDropdown(lead._id, "phone")}>
            <img src={Phone} alt="Phone" />
          </div>
          <div role="button" className="single_blocker " onClick={() => toggleDropdown(lead._id, "email")}>
            <img src={Message} alt="Message" />
          </div>
        </div>
        {openDropdown === lead._id && (
          <div className="dropdown-content" ref={dropdownRef} style={{ position: "absolute" }}>
            {dropdownType === "phone" && <p> {lead.mobileNumber}</p>}
            {dropdownType === "email" && <p> {lead.email}</p>}
          </div>
        )}
      </td>

      {userType == 1 ? (
        <td>
          <div>
            <div className="single_blocker prockere">
              <Select
                options={realtors.map((realtor) => {
                  const isSelected = lead.realtorIds && lead.realtorIds.includes(realtor._id);
                  return {
                    value: realtor._id,
                    label: `${realtor.firstname || ""} ${realtor.lastname || ""}`, // Ensure realtor.firstname and realtor.lastname exist
                    isSelected,
                  };
                })}
                value={
                  lead.realtorIds.length > 0
                    ? lead.realtorIds.map((realtorId) => {
                        const realtor = realtors.find((r) => r._id == realtorId);
                        return {
                          value: realtorId,
                          label: `${realtor ? realtor.firstname : ""} ${realtor ? realtor.lastname : ""}`,
                        };
                      })
                    : [{ value: "", label: "Select a realtor" }]
                }
                className="basic-multi-select"
                styles={customStyles}
                isSearchable={true}
                onChange={(selectedOption) => {
                  const selectedLeadId = lead._id;
                  const selectedRealtorId = selectedOption.value;
                  assignLeadsToRealtors(selectedLeadId, selectedRealtorId);
                }}
                onMenuOpen={() => onSetIsDropdown(true)}
                onMenuClose={() => onSetIsDropdown(false)}
                menuPlacement={totalItems > 5 && isLastLead ? "top" : "bottom"}
                menuPosition="fixed"
              />
            </div>
          </div>
        </td>
      ) : null}
    </tr>
  );
};

export default LeadData;
