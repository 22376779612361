import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Url from "../Element/Api";
import "../PackagesProcess/Packages.css";
import useAuth from "../../hooks/useAuth";
import Cross from "../../assets/aClose.svg";
import Tick from "../../assets/aApprove.svg";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import DefaultLayout from "../../layouts/DefaultLayout";
import { Breadcrumbs, exceptThisSymbols } from "../../utils/utils";
import { setBrokerProfileDetails } from "../../Redux/Action/Authaction";

function ChoosePackage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [config, configFormData] = useAuth();
  const [loading, setLoading] = useState(true);
  const [agentType, setAgentType] = useState();
  const [tableData, setTableData] = useState([]);
  const [numberOfRealtor, setNumberOfRealtor] = useState(0);
  const brokerProfileDetails = useSelector((state) => state.brokerProfileDetails);
  const isOnBoarded = brokerProfileDetails?.stripe_account_id && brokerProfileDetails?.stripe_customer_id;

  useEffect(() => {
    axios
      .get(`${Url.BASEURL}/broker/profile`, configFormData)
      .then((response) => {
        if (isOnBoarded) {
          setNumberOfRealtor(0);
        } else {
          setNumberOfRealtor(response.data.numberOfAgents);
        }
        setAgentType(response.data.agentType);
        dispatch(setBrokerProfileDetails(response?.data));
      })
      .catch((error) => {
        console.error("Error fetching profile data:", error);
      });
  }, [isOnBoarded]);

  const handleNext = () => {
    if (isOnBoarded) {
      axios
        .post(`${Url.BASEURL}/broker/total-realtors`, { updatedNumberOfRealtors: numberOfRealtor, agentType: agentType }, config)
        .then(() => {
          localStorage.setItem("navigateFrom", "package");
          navigate("/pricing-review");
        })
        .catch((error) => console.log("err", error));
    } else {
      axios
        .post(`${Url.BASEURL}/broker/total-realtors`, { newNumberOfRealtors: numberOfRealtor, agentType: agentType }, config)
        .then(() => navigate("/packages-mls"))
        .catch((error) => console.error("Error Selecting MLS: ", error));
    }
  };

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      axios
        .get(`${Url.BASEURL}/broker/get-packages-info`, config)
        .then((res) => {
          setTableData(res.data);
          setLoading(false);
        })
        .catch((error) => console.error("Error fetching data:", error));
    };
    fetchData();
  }, []);

  const renderTableRows = () => {
    if (!tableData || tableData.length === 0) {
      return (
        <tr>
          <td colSpan="3">Loading...</td>
        </tr>
      );
    }
    return tableData
      .filter((el) => el.featureName !== "Monthly Rate")
      .map((row, index) => (
        <tr key={index}>
          <td>{row.featureName}</td>
          {agentType === 0 ? <td align="center">{typeof row.forSingleAgent === "number" ? <span style={{ fontSize: "20px", fontWeight: 700, color: "#646978" }}>{row.forSingleAgent}</span> : row.forSingleAgent ? <img src={Tick} alt="tick" /> : <img src={Cross} alt="cross" />}</td> : <td align="center">{typeof row.forBrokerSolution === "number" ? <span style={{ fontSize: "20px", fontWeight: 700, color: "#646978" }}>{row.forBrokerSolution}</span> : row.forBrokerSolution ? <img src={Tick} alt="tick" /> : <img src={Cross} alt="cross" />}</td>}
        </tr>
      ));
  };

  // Breadcrumbs data
  const breadcrumbData = [{ label: "Choose Your Package" }];

  const breadcrumbData2 = [{ path: "/", label: "Dashboard" }, { label: "Choose Your Package" }];

  return (
    <DefaultLayout>
      <Breadcrumbs crumbs={isOnBoarded ? breadcrumbData2 : breadcrumbData} />
      <h1>Choose your Package</h1>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="full_table_area choose_package_only">
            <div className=" mls_listing_invoice">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Features</th>
                    <th className="center-th" scope="col">
                      {agentType === 0 ? "Single Agent" : "Broker Solutions"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                        marginLeft: "23em",
                      }}
                    >
                      <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                    </div>
                  ) : (
                    <>
                      {renderTableRows()}
                      <tr>
                        <td>
                          Number of Included Agent Websites <span style={{ fontSize: "14px" }}>(Full Websites for your Agents)</span>
                        </td>
                        {agentType === 0 ? (
                          <td align="center">
                            <img src={Cross} alt="cross" />
                          </td>
                        ) : (
                          <td align="center" style={{ color: "#646978", fontSize: "14px", fontWeight: 400 }}>
                            How many Realtors? <br />{" "}
                            <input
                              className="realtor-input"
                              type="number"
                              min={0}
                              max={2000}
                              value={numberOfRealtor}
                              onChange={(e) => {
                                let newValue = parseInt(e.target.value);
                                if (newValue < 0) {
                                  newValue = 0;
                                } else if (newValue > 2000) {
                                  newValue = 2000;
                                }
                                newValue = newValue.toString();
                                setNumberOfRealtor(newValue);
                              }}
                              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                              style={{ width: "75px", height: "34px", borderRadius: "4px", textAlign: "center", border: "1px solid #fff", backgroundColor: "#F0F5FF", marginTop: "3px" }}
                            />
                          </td>
                        )}
                      </tr>
                      {tableData
                        .filter((el) => el.featureName === "Monthly Rate")
                        .map((item) => (
                          <tr>
                            <td>
                              {item?.featureName} <br />
                            </td>
                            <td align="center" style={{ color: "#3C4150", fontSize: "24px", fontWeight: 700 }}>
                              ${agentType === 0 ? 1 * item?.price : numberOfRealtor * item?.price} <br />
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    <td className="center-th">
                      <button className="next_btns" onClick={handleNext}>
                        Continue
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default ChoosePackage;
