import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import "../PackagesProcess/Packages.css";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../utils/utils";
import DefaultLayout from "../../layouts/DefaultLayout";

function PricingReview() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);
  const navigateFrom = localStorage.getItem("navigateFrom");
  const [billingCycle, setBillingCycle] = useState("Monthly");
  const brokerProfileDetails = useSelector((state) => state.brokerProfileDetails);
  const isOnBoarded = brokerProfileDetails?.stripe_account_id && brokerProfileDetails?.stripe_customer_id;
  const agentType = useSelector((state) => state.agentType);
console.log("agentType",agentType)
 const brokeragentType = brokerProfileDetails?.agentType ? brokerProfileDetails.agentType : agentType;

  const handleBillingCycleChange = (value) => {
    setBillingCycle(value);
  };

  useEffect(() => {
    const fetchPricingReview = async () => {
      try {
        const response = await axios.get(
          Url.BASEURL + `/broker/pricing-review`,
          
          config
        );
        setTableData(response.data.brokerDetails);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchPricingReview();
  }, [billingCycle]);

  // Render table rows dynamically
  const renderMlsData = () => {
    if (!tableData || tableData.length === 0) {
      return null;
    }
    return tableData.selectedMLS.map((row, index) => (
      <tr key={index}>
        <td className={row.isTotal ? "full_totaling" : ""}>
          {row.mlsName}
          {row.extraInfo && <span className="dynamic_numbers">{row.extraInfo}</span>}
        </td>
        <td className="second_one" align="right">
          ${billingCycle === "Annual" ? row.price : row.price}
          {billingCycle === "Annual" && <span className="left_connectors"> x12</span>}{" "}
        </td>
      </tr>
    ));
  };

  const renderAdditionalDetails = () => {
    const additionalDetails = [
      { label: "MLS Fees", value: tableData?.idxMlsFees },
      { label: brokeragentType.agentType === 0 ? "Single Broker Price" : "Realtors Total Price", value: brokeragentType.agentType === 0 ? tableData?.singleBrokerPrice : tableData?.realtorsPrice },
    ];

    return additionalDetails.map((detail, index) => (
      <tr key={index}>
        <td>{detail.label}</td>
        <td className="second_one" align="right">
          ${detail.value}
          {billingCycle === "Annual" && <span className="left_connectors"> x12</span>}
        </td>
      </tr>
    ));
  };

  const renderTotalCost = () => {
    const totalCost = tableData?.totalCost;
    const totalCostPackage = tableData?.realtorsPrice;
    const totalCostMls = Number(tableData?.idxMlsFees) + (Number(totalCost) - (Number(totalCostPackage) + Number(tableData?.idxMlsFees)));

    return (
      <tr>
        <td className="total_cost full_totaling">Total Cost</td>
        <td className="second_one" align="right">
          ${isOnBoarded ? (navigateFrom === "package" ? totalCostPackage : totalCostMls) : totalCost}
          {billingCycle === "Annual" && <span className="left_connectors"> (inclusive of 10% off)</span>}
        </td>
      </tr>
    );
  };

  // Breadcrumbs data
  const breadcrumbData = [{ path: "/choose-package", label: "Packages" }, { path: "/packages-mls", label: "Mls" }, { path: "/register-domain", label: "Domain" }, { label: "Pricing Review" }];

  // Breadcrumbs data for choose package
  const breadcrumbDataPackage = [{ path: "/", label: "Dashboard" }, { path: "/choose-package", label: "Packages" }, { label: "Pricing Review" }];

  // Breadcrumbs data for mls
  const breadcrumbDataMls = [{ path: "/", label: "Dashboard" }, { path: "/packages-mls", label: "Mls" }, { label: "Pricing Review" }];

  return (
    <DefaultLayout>
      <Breadcrumbs crumbs={isOnBoarded ? (navigateFrom === "package" ? breadcrumbDataPackage : breadcrumbDataMls) : breadcrumbData} />
      <h1>Pricing Review</h1>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader color="#22A9E0" loading={loading} size={100} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-12">
              <div className="full_connectror d-flex">
                <div className="left_connectors">Please review your account charges. You can choose to pay either monthly or annually. When paying annually, you save 10% compared to the regular monthly pricing!</div>
                <div className="right_connectors">
                  <div className="selectors">
                    <h3>Choose Billing Cycle :</h3>
                    <div className="contract_rows d-flex">
                      <div className="left_contact_dt d-flex">
                        <label className="custom-radio-container">
                          Monthly
                          <input type="radio" name="billingCycle" className="custom-radio" onChange={() => handleBillingCycleChange("Monthly")} checked={billingCycle === "Monthly"} />
                          <span className="radio-checkmark"></span>
                        </label>
                      </div>
                      <div className="right_cont_dt d-flex">
                        <label className="custom-radio-container tttp">
                          Annual (save 10% on monthly fees)
                          <input type="radio" name="billingCycle" className="custom-radio" onChange={() => handleBillingCycleChange("Annual")} checked={billingCycle === "Annual"} />
                          <span className="radio-checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-lg-12">
              <div className="full_table_area choose_package_only">
                <div className="mls_listing_invoice total_count_table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Pricing Review</th>
                        <th scope="col" className="rt_th">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isOnBoarded ? (navigateFrom === "package" ? null : renderMlsData()) : renderMlsData()}

                      {isOnBoarded ? (
                        navigateFrom === "package" ? (
                          <tr>
                            <td>Realtors Total Price</td>
                            <td className="second_one" align="right">
                              ${tableData?.realtorsPrice}
                              {billingCycle === "Annual" && <span className="left_connectors"> x12</span>}
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>MLS Fees</td>
                            <td className="second_one" align="right">
                              ${tableData?.idxMlsFees}
                              {billingCycle === "Annual" && <span className="left_connectors"> x12</span>}
                            </td>
                          </tr>
                        )
                      ) : (
                        renderAdditionalDetails()
                      )}
                      {renderTotalCost()}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td></td>
                        <td align="right" style={{ paddingRight: "12px" }}>
                          <button
                            className="next_btns"
                            onClick={() => {
                              navigate("/billing-info");
                            }}
                          >
                            Next
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </DefaultLayout>
  );
}

export default PricingReview;
