import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import axios from "axios";
import Url from "../../Element/Api";
import { useNavigate } from "react-router-dom";
import UserIcon1 from "../../../assets/up1.svg";
import UserIcon2 from "../../../assets/up2.svg";
import UserIcon4 from "../../../assets/up4.svg";
import { useSelector, useDispatch } from "react-redux";
import ChangePasswordModal from "./ChangePasswordModal";
import Whitecross from "../../../assets/white-cross.svg";
import { handleDropdownClose } from "../../../utils/utils";
import { setBrandingData, setLogOut, setProfileImage, setRefreshToken, setTokenExpTime, setUserType } from "../../../Redux/Action/Authaction";

const Header = () => {
  const notificationCount = 0;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const brokerId = useSelector((state) => state.brokerId);
  const usertype = useSelector((state) => state.userType);
  const fullname = useSelector((state) => state.fullname);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const proImage = useSelector((state) => state.profileImage);
  const companyName = useSelector((state) => state.companyname);
  const refreshToken = useSelector((state) => state.refreshToken);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  const handleLogOut = () => {
    axios
      .delete(`${Url.BASEURL}/broker/logout`, {
        data: {
          refreshToken: refreshToken,
        },
      })
      .then(() => {
        navigate("/login");
        dispatch(setLogOut());
        dispatch(setUserType(null));
        dispatch(setProfileImage(null));
        dispatch(setTokenExpTime(null));
        dispatch(setRefreshToken(null));
        dispatch(setBrandingData(null, null));
        localStorage.clear();
      });
  };

  useEffect(() => {
    const handleClose = () => {
      setIsSidebarOpen(false);
    };
    handleDropdownClose(sidebarRef, handleClose);
  }, []);

  return (
    <div className="header">
      <div className="left-section">
        <form>{/* <input type="text" placeholder="www.allprofessionalsre.com" /> */}</form>
        {/* <span className="icon-pencil"></span> */}
        {/* <span className="icon-eye"></span> */}
      </div>

      <div className="right-section">
        {usertype == 1 && (
          <button
            className="manage-leads-button"
            onClick={() => {
              window.open(`https://crm.wovnnelite.ai/login-into-dash/${brokerId}`, "_blank");
            }}
          >
            Manage Leads
          </button>
        )}

        {/*<div className="search-box">
          <input type="text" placeholder="Search" />
          <span className="icon-search"></span>
          </div>*/}

        <div role="button" className="bell_notification">
          <span className="icon-bell"></span>
          {notificationCount > 0 && <span className="notification_count">{notificationCount}</span>}
        </div>

        <>
          <div
            className="user_sidebar"
            onClick={() => {
              setIsSidebarOpen(!isSidebarOpen);
            }}
          >
            <img src={proImage} alt="user" className="user-image" />
          </div>

          {isSidebarOpen && (
            <div className={`user-profile ${isSidebarOpen ? "open" : ""}`} ref={sidebarRef}>
              <div className="profile-header">
                <button
                  className="close-button"
                  onClick={() => {
                    setIsSidebarOpen(!isSidebarOpen);
                  }}
                >
                  <img src={Whitecross} alt="Close Sidebar" className="cross-image" />
                </button>
                <div className="profile-image-container">
                  <img src={proImage} alt="Profile" className="profile-image" style={{ height: "100%" }} />
                </div>
                {usertype == 1 ? <p className="user-company text-capitalize">{fullname}</p> : null}
                {/* <p className="user-company">{fullname}</p> */}
                <h5 className="company_names">{companyName?.companyname}</h5>
              </div>

              <div className="profile-body">
                <div role="button" className="profile-link" onClick={() => navigate("/profile-broker")}>
                  <img src={UserIcon1} alt="User Icon" /> Profile
                </div>

                {/* {usertype == 1 ? (
                  <a href="/contact" className="profile-link"><img src={UserIcon2} alt="Support Icon" /> Contact Us</a>
                ) : (null)} */}

                {usertype == 1 ? (
                  <div role="button" className="profile-link" onClick={() => navigate("/about")}>
                    <img src={UserIcon2} alt="Settings Icon" /> About
                  </div>
                ) : null}

                <div
                  role="button"
                  className="profile-link"
                  onClick={() => {
                    setIsPasswordModalVisible(true);
                  }}
                >
                  <img src={UserIcon4} alt="Settings Icon" /> Change Password
                </div>
              </div>

              <button className="sign-out-button" onClick={handleLogOut}>
                Sign Out
              </button>
            </div>
          )}
        </>
      </div>

      <ChangePasswordModal show={isPasswordModalVisible} onHide={() => setIsPasswordModalVisible(false)} />
    </div>
  );
};

export default Header;
