import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { Modal } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

const RealtorPaymentModal = ({ show, onClose }) => {
  const [config, configFormData] = useAuth();
  const [paymentData, setPaymentData] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [billingCycle, setBillingCycle] = useState("Monthly");

  const handleBillingCycle = (value) => {
    setBillingCycle(value);
  };

  const handleContinue = () => {
    setIsDisable(true);
    axios
      .post(`${Url.BASEURL}/broker/realtor/realtor-setup-fee`, {}, configFormData)
      .then((response) => {
        if (response.data) {
          const value = response.data;
          const linkToNavigate = value.session.url;
          window.location.href = linkToNavigate;
          setIsDisable(false);
          onClose();
        }
      })
      .catch((err) => {
        setIsDisable(false);
        console.error("Error submitting form:", err);
      });
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(`${Url.BASEURL}/broker/realtor/realtor-pricing`, config)
        .then((res) => {
          console.log("res", res?.data);
          setPaymentData(res?.data);
        })
        .catch((err) => console.error("Error", err));
    };

    fetchData();
  }, []);

  return (
    <Modal show={show} onHide={onClose} backdrop="static" className="realtor-payment-modal rpm">
      <Modal.Body style={{ padding: "30px 0px" }}>
        <div>
          <h6>Proceed to Pay</h6>
          <div className="card-section">
            <div style={{ paddingRight: "10px" }}>
              <p>
                One Time Setup Fees Set by <span className="text-capitalize">{paymentData?.brokerName}</span>
              </p>
              <div className="card-desc bset_decsc">Your broker has set up a profile inclusion process, and to finalise the procedure, we kindly request you to make the necessary payment for the setup fees.</div>
            </div>
            <div className="setup-fees">$ {paymentData?.setUpFee}</div>
          </div>

          <div className="card-section carding">
            <span>Monthly Subscription</span>
            <div className="setup-fees">$ {paymentData?.seatPrice}</div>
          </div>

          {/* <div className="billing-choose">
            <div className="d-flex bcycle">
              <div className="payment_body_text">
                <span>Billing Cycle :</span>
              </div>
              <div className="d-flex halfing_set">
                <div className="d-flex ">
                  <label className="custom-radio-container-payment">
                    <span class="set_month_year">Monthly</span>
                    <input type="radio" name="billingCycle" className="custom-radio" onChange={() => handleBillingCycle("Monthly")} checked={billingCycle === "Monthly"} />
                    <span className="radio-checkmark-payment"></span>
                  </label>
                </div>
                <div className="d-flex" style={{ marginLeft: "30px" }}>
                  <label className="custom-radio-container-payment">
                    <span class="set_month_year">Annual (save 10% on monthly fees)</span>
                    <input type="radio" name="billingCycle" className="custom-radio" onChange={() => handleBillingCycle("Annual")} checked={billingCycle === "Annual"} />
                    <span className="radio-checkmark-payment"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="card-desc">You can choose to pay either monthly or annually. When paying annually, you save 10% compared to the regular monthly pricing!</div>
          </div> */}

          <div className="d-flex justify-content-center mt-2">
            <button disabled={isDisable} onClick={handleContinue} className="continue-button">
              Continue
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RealtorPaymentModal;
