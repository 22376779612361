import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../Element/Api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAuth from "../../hooks/useAuth";
import Shield from "../../assets/shield.svg";
import { useNavigate } from "react-router-dom";
import ModalMessage from "../ModalMessage/Modal";
import BaseLayout from "../../layouts/BaseLayout";
import { editorStyle, formats, modules } from "../../utils/utils";

const AboutPage = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [aboutData, setAboutData] = useState({
    title: "",
    mission: "",
    vision: "",
    description: "",
  });

  useEffect(() => {
    const fetchAboutData = () => {
      axios
        .get(Url.BASEURL + `/broker/about`, config)
        .then((response) => {
          const { data } = response;
          setAboutData({
            title: data.title || "",
            mission: data.mission || "",
            vision: data.vision || "",
          });
          setDescription(data.description || "");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchAboutData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const postData = {
      title: aboutData.title,
      mission: aboutData.mission,
      vision: aboutData.vision,
      description,
    };
    axios
      .post(Url.BASEURL + `/broker/about`, postData, config)
      .then((res) => {
        navigate("/");
      })
      .catch((error) => {
        setShowModal(false);
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          setErrors({ general: "Failed to update data. Please try again." });
        }
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setAboutData({ ...aboutData, [name]: value });
  };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages single_posts">
        <h2>About Us</h2>

        <p>Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>

        <div className="post-data title_bar_setting ">
          <div className="post_detail d-flex ">
            <div className="left_post_detail">
              <div className="single_entry">
                <label>
                  Title
                  <input type="text" className="form-control" name="title" value={aboutData.title} placeholder="Write your title here" onChange={handleChange} />
                </label>
                {errors.title && <p style={{ color: "red", fontSize: "12px" }}>{errors.title}</p>}
              </div>
            </div>

            <div className="right_post_detail">
              <div className="single_entry">
                <label>
                  Mission
                  <input type="text" className="form-control" value={aboutData.mission} name="mission" placeholder="Write your mission.." onChange={handleChange} />
                </label>
                {errors.mission && <p style={{ color: "red", fontSize: "12px" }}>{errors.mission}</p>}
              </div>
            </div>

            <div className="single_entry">
              <label>
                Vision
                <input type="text" className="form-control" name="vision" value={aboutData?.vision} placeholder="Write your vision.." onChange={handleChange} />
              </label>
              {errors.vision && <p style={{ color: "red", fontSize: "12px" }}>{errors.vision}</p>}
            </div>
          </div>

          <div className="editor" style={editorStyle}>
            <ReactQuill
              value={description}
              modules={modules}
              formats={formats}
              onChange={(html) => {
                setDescription(html);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  description: "",
                }));
              }}
            />
            {errors.description && <p style={{ color: "red", fontSize: "12px" }}>{errors.description}</p>}
          </div>

          <div className="two_btns">
            <button
              type="submit"
              className="btn update_btn"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Save
            </button>
          </div>
          <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={handleSubmit} message="Do you really want to save your About Us details?" img={Shield} leftButton="Cancel" rightButton="Save" />
        </div>
      </div>
    </BaseLayout>
  );
};

export default AboutPage;
