import React, { useState, useEffect } from "react";
import "../Blog/BlogPages";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import { ClipLoader } from "react-spinners";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import BaseLayout from "../../layouts/BaseLayout";
import AddTagModal from "./components/AddTagModal";
import EditTagModal from "./components/EditTagModal";

function ManageTags() {
  const [config] = useAuth();
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [newTagName, setNewTagName] = useState("");
  const [userData, setUserData] = useState({
    _id: "",
    name: "",
    description: "",
  });

  const handleInputChange = (e) => {
    setNewTagName(e.target.value);
  };

  const getTagList = () => {
    setLoading(true);
    axios
      .get(Url.BASEURL + "/manage-tag/all?", config)
      .then((value) => {
        setData(value.data.tags);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTagList();
  }, []);

  const addTagHandler = () => {
    const params = {
      name: newTagName,
      description: newTagName,
    };
    axios
      .post(Url.BASEURL + "/manage-tag/create?", params, config)
      .then((value) => {
        getTagList();
        setNewTagName("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRowHandler = (tagId) => {
    axios
      .delete(Url.BASEURL + "/manage-tag/" + tagId, config)
      .then((value) => {
        getTagList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editTagHandler = (tagId) => {
    axios
      .get(Url.BASEURL + "/manage-tag/" + tagId, config)
      .then((value) => {
        setUserData(value.data);
        setUserId(tagId);
        setNewTagName(value.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const updateHandle = () => {
    const params = {
      name: userData.name,
    };
    axios
      .put(Url.BASEURL + "/manage-tag/" + userId, params, config)
      .then((value) => {
        getTagList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages manage_tags_set table_view_only">
        <h2>Manage Tags</h2>
        <div className="blog_add">
          <button className="Add_new_blog btn update_btn" data-bs-toggle="modal" data-bs-target="#addTagModal">
            Add New
          </button>
        </div>

        <AddTagModal newTagName={newTagName} handleInputChange={handleInputChange} addTagHandler={addTagHandler} onClose={() => setNewTagName("")} />

        <p className="padd_app">Instructions: Tags are used to sort your content similar to a Category in the archive. Tags also provide a small search engine benefit as a keyword describing your content.</p>

        {loading ? (
          <div className="d-flex justify-content-center">
            <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <div>
            {data.length > 0 ? (
              <div className="tag_list d-flex">
                {data.map((tag, index) => {
                  return (
                    <div key={index} className="left_tags d-flex">
                      <div className="tag_name">
                        {tag.tag.name} <span className="count_tags"> ({tag.blogCount} Posts) </span>
                      </div>
                      <div className="tag_btns d-flex">
                        {/* <div className="view_tag">
                          <Link to="/view-path">
                            <span className="icon-eye"></span>
                          </Link>
                        </div> */}
                        <div className="tag_editing">
                          <span
                            role="button"
                            onClick={() => {
                              editTagHandler(tag.tag._id);
                              setUserId(tag.tag._id);
                            }}
                            className="icon-Pencil"
                            data-bs-toggle="modal"
                            data-bs-target="#addEditTagModal"
                          />
                        </div>
                        <div className="handle_delete">
                          <button
                            className="recycle_delete"
                            onClick={() => {
                              deleteRowHandler(tag.tag._id);
                            }}
                          >
                            <span className="icon-Recycle-Bin"></span>
                          </button>
                        </div>
                      </div>

                      <EditTagModal updateHandle={updateHandle} userData={userData} handleEditInputChange={handleEditInputChange} />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center mt-5">No tags available</div>
            )}
          </div>
        )}

        {/* <div className="two_btns">
              <button className="btn update_btn">Add Blog</button>
              <button className="btn cancel_btn">Draft</button>
            </div> */}
      </div>
    </BaseLayout>
  );
}

export default ManageTags;
