import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BrokerProfile.css";
import Url from "../Element/Api";
import Select from "react-select";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import useAuth from "../../hooks/useAuth";
import Edit from "../../assets/pencil.svg";
import { ClipLoader } from "react-spinners";
import Shield from "../../assets/shield.svg";
import { useNavigate } from "react-router-dom";
import ModalMessage from "../ModalMessage/Modal";
import BaseLayout from "../../layouts/BaseLayout";
import { useSelector, useDispatch } from "react-redux";
import { exceptThisSymbols, getFaxIdFormat } from "../../utils/utils";
import {
  setProfileImage,
  setFullName,
  setCompanyname,
} from "../../Redux/Action/Authaction";

function BrokerProfileEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [config, configFormData] = useAuth();
  const userType = useSelector((state) => state.userType);
  const agenttype = useSelector((state) => state.agentType);
  const [error, setError] = useState();
  const [imageURL, setImageURL] = useState("");
  const [agentType, setAgentType] = useState();
  const [loading, setLoading] = useState(true);
  const [imageFile, setImageFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [imagePreview, setImagePreview] = useState(
    "https://via.placeholder.com/150"
  );
  const [brokerFormData, setBrokerFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    companyName: "",
    bio: "",
    faxId: "",
    address: "",
    license: "",
    serviceArea: "",
    numberOfAgents: 0,
    city: "",
    state: "",
    zipCode: "",
  });

  const [realtorFormData, setRealtorFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    bio: "",
    address: "",
    license: "",
    profileTagline: "",
    metaTitle: "",
    metaDescription: "",
    serviceArea: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: "478px !important",
    }),
  };

  const handleCityChange = (selectedOptions) => {
    const valuesArray = selectedOptions.map((option) => option.value);
    setSelectedCities(valuesArray);
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  /* Range-slider */
  const onSliderChange = (newValue) => {
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 2000) {
      newValue = 2000;
    }
    setBrokerFormData({ ...brokerFormData, numberOfAgents: newValue });
  };

  const onInputChange = (e) => {
    // Ensure the input value is within the range of 0 to 2000
    let value = parseInt(e.target.value);
    if (value < 0) {
      value = 0;
    } else if (value > 2000) {
      value = 2000;
    }

    value = value.toString();
    setBrokerFormData({ ...brokerFormData, numberOfAgents: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setImageFile(file);
      setImageURL("");
    }
  };

  useEffect(() => {
    // Fetch pre-filled data on component mount
    const profileApiUrl =
      userType == 1
        ? `${Url.BASEURL}/broker/profile`
        : `${Url.BASEURL}/broker/realtor/realtor-profile`;
    axios
      .get(profileApiUrl, config)
      .then((response) => {
        if (userType == 1) {
          setBrokerFormData({
            firstname: response.data.firstname,
            lastname: response.data.lastname,
            phone: response.data.phone,
            email: response.data.email,
            companyName: response.data.companyName,
            bio: response.data.bio,
            faxId: response.data.contactDetails.faxId,
            address: response.data.contactDetails.address,
            license: response.data.contactDetails.license,
            serviceArea: response.data.serviceArea,
            numberOfAgents: response.data.numberOfAgents,
            city: response.data.contactDetails.city,
            state: response.data.contactDetails.state,
            zipCode: response.data.contactDetails.zipCode,
          });
          setSelectedCities(response.data.serviceArea);
          setImageURL(response.data.imageURL);
          setImagePreview(response.data.imageURL);
          setAgentType(response.data.agentType);
          setLoading(false);
        } else if (userType == 0) {
          setRealtorFormData({
            firstname: response.data.realtor.firstname,
            lastname: response.data.realtor.lastname,
            email: response.data.realtor.email,
            phone: response.data.realtor.phone,
            bio: response.data.realtor.bio,
            address: response.data.realtor.contactDetails.address,
            license: response.data.realtor.contactDetails.license,
            profileTagline: response.data.realtor.profileTagline,
            metaTitle: response.data.realtor.metaTitle,
            metaDescription: response.data.realtor.metaDescription,
            serviceArea: response.data.serviceArea,
            city: response.data.realtor?.contactDetails.city,
            state: response.data.realtor?.contactDetails.state,
            zipCode: response.data.realtor?.contactDetails.zipCode,
          });
        }
        setImageURL(response.data.realtor.image);
        setSelectedCities(response.data.realtor.serviceArea);
        setImagePreview(response.data.realtor.image);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching profile data:", error);
      });
  }, [userType]);

  useEffect(() => {
    const citiesApi =
      userType == 1
        ? Url.BASEURL + "/broker/get-all-cities"
        : Url.BASEURL + "/broker/realtor/get-all-cities-from-broker";
    const fetchCities = async () => {
      try {
        const response = await axios.get(citiesApi, config);
        const cities = response.data;
        const formattedCities = cities.map((city) => ({
          value: city,
          label: city,
        }));
        setCityOptions(formattedCities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, [userType]);

  const handleSave = () => {
    const updatedFormData = {
      ...(userType == 1 ? brokerFormData : realtorFormData),
      image: imageURL,
      serviceArea: selectedCities,
    };
    const formDataWithFile = new FormData();
    if (imageFile != null) {
      formDataWithFile.append("image", imageFile);
    }
    Object.entries(updatedFormData).forEach(([key, value]) => {
      formDataWithFile.append(key, value);
    });
    const apiEndpoint =
      userType == 1
        ? `${Url.BASEURL}/broker/update-profile`
        : `${Url.BASEURL}/broker/realtor/update-profile`;

    axios
      .post(apiEndpoint, formDataWithFile, configFormData)
      .then((response) => {
        if (userType === 1) {
          const { firstname, lastname } = response.data.brokerWithImageURL;
          let fullName = `${firstname} ${lastname}`;
          dispatch(setProfileImage(response.data.brokerWithImageURL.imageURL));
          dispatch(setFullName(fullName));
          dispatch(
            setCompanyname(response.data.brokerWithImageURL.companyName)
          );
          navigate("/");
        } else if (userType === 0) {
          const { firstname, lastname } = response.data.realtor;
          let fullName = `${firstname} ${lastname}`;
          dispatch(setProfileImage(response.data.realtor.image));
          dispatch(setFullName(fullName));
          navigate("/");
        }
      })
      .catch((error) => {
        setError(error.response.data.errors);
        setShowModal(false);
      });
  };

  const handleChangeBrokerData = (e) => {
    const { name, value } = e.target;
    const onlyAlphabets = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const onlyNumber = e.target.value.replace(/[^0-9 ]/, "");
    const numberAlphabets = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    const valueToSet =
      name == "profileTagline" ||
      name == "city" ||
      name == "state" ||
      name == "firstname" ||
      name == "lastname"
        ? onlyAlphabets
        : name == "zipCode" || name == "phone"
        ? onlyNumber
        : name == "faxId" || name == "license"
        ? numberAlphabets
        : value;

    setError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setBrokerFormData({
      ...brokerFormData,
      [name]: valueToSet,
    });
  };

  const handleChangeRealtorData = (e) => {
    const { name, value } = e.target;
    const onlyAlphabets = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const onlyNumber = e.target.value.replace(/[^0-9 ]/, "");
    const numberAlphabets = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    const valueToSet =
      name == "profileTagline" ||
      name == "city" ||
      name == "state" ||
      name == "firstname" ||
      name == "lastname"
        ? onlyAlphabets
        : name == "zipCode" || name == "phone"
        ? onlyNumber
        : name == "faxId" || name == "license"
        ? numberAlphabets
        : value;

    setError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setRealtorFormData({
      ...realtorFormData,
      [name]: valueToSet,
    });
  };

  const handleChange = (e) => {
    if (userType == 1) {
      handleChangeBrokerData(e);
    } else {
      handleChangeRealtorData(e);
    }
  };

  const handleZipCodeKeyDown = (e) => {
    if (e.target.value.length >= 5 && e.key !== "Backspace") {
      e.preventDefault();
    }

    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  const mystyle = {
    color: "var(--color-secondary-dark, #141928)",
    fontfamily: "Oxygen",
    fontsize: "16px",
    fontstyle: "normal",
    fontweight: 700,
    lineheight: "150%",
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks">
        <div className="login-register broker_edit_profile">
          <div className=" container-fluid custom_principal_page broker_profile_only">
            <div className="row ">
              <div className="col-lg-12">
                <div className="principal_broker">
                  {loading ? (
                    <div className="d-flex justify-content-center my-5">
                      <ClipLoader
                        color="#22A9E0"
                        loading={loading}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  ) : (
                    <div className="top_principal d-flex">
                      <div className="left_principal image_uploader">
                        <img
                          src={imagePreview}
                          alt="Uploaded"
                          className="responsive_image"
                        />
                        <input
                          type="file"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                          id="imageInput"
                        />
                        <label htmlFor="imageInput" className="edit-icon-image">
                          <img src={Edit} alt="edit" role="button" />
                        </label>
                        {error?.image && (
                          <p
                            style={{
                              color: "red",
                              fontWeight: "400",
                              marginLeft: "10px",
                              fontSize: "13px",
                            }}
                          >
                            {error?.image}
                          </p>
                        )}
                      </div>

                      <div className="right_principal title_bar_setting">
                        <div className="contact_detail title_bar_setting">
                          <div className="contract_rows d-flex">
                            <div className="left_contact_dt">
                              <div className="single_entry">
                                <label>
                                  First Name
                                  <input
                                    type="text"
                                    name="firstname"
                                    className="form-control"
                                    placeholder="John"
                                    value={
                                      userType == 1
                                        ? brokerFormData.firstname
                                        : realtorFormData.firstname
                                    }
                                    onChange={(e) => handleChange(e)}
                                  />
                                  {error?.firstname && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.firstname}
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                            <div className="right_cont_dt">
                              <div className="single_entry">
                                <label>
                                  Last Name
                                  <input
                                    name="lastname"
                                    type="text"
                                    className="form-control"
                                    placeholder="Scott"
                                    value={
                                      userType == 1
                                        ? brokerFormData.lastname
                                        : realtorFormData.lastname
                                    }
                                    onChange={(e) => handleChange(e)}
                                  />
                                  {error?.lastname && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.lastname}
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="contract_rows d-flex">
                            <div className="left_contact_dt">
                              <div className="single_entry">
                                <label>
                                  Email
                                  <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email "
                                    value={
                                      userType == 1
                                        ? brokerFormData.email
                                        : realtorFormData.email
                                    }
                                    onChange={(e) => handleChange(e)}
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>

                            <div className="right_cont_dt">
                              <div className="single_entry eye_set">
                                <label>
                                  Password
                                  <input
                                    type="password"
                                    className="form-control"
                                    placeholder="***************"
                                    disabled
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="contact_detail title_bar_setting ph_class">
                        <div className="contract_rows d-flex">
                          <div className="left_contact_dt">
                            <div className="single_entry">
                              <label
                                // style={{
                                //   color: "var(--color-secondary-dark, #141928)",
                                //   fontfamily: "Oxygen",
                                //   fontsize: "16px",
                                //   fontstyle: "normal",
                                //   fontweight: 700,
                                //   lineheight: "150%",
                                // }}
                              >
                                Phone No.
                              </label>
                              <input
                                type="text"
                                name="phone"
                                className="form-control"
                                placeholder="+1 888 544 4512"
                                pattern="[0-9]*"
                                value={
                                  userType == 1
                                    ? brokerFormData.phone
                                    : realtorFormData.phone
                                }
                                onChange={(e) => {
                                  const input = e.target.value;
                                  if (
                                    /^[0-9]*$/.test(input) &&
                                    input.length >= 0 &&
                                    input.length <= 15
                                  ) {
                                    handleChange(e);
                                  }
                                }}
                              />
                              {error?.phone && (
                                <p
                                  style={{
                                    color: "red",
                                    fontWeight: "400",
                                    marginLeft: "10px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {error?.phone}
                                </p>
                              )}
                            </div>
                          </div>

                          {userType == 0 ? (
                            <div className="right_cont_dt">
                              <div className="single_entry">
                                <label>
                                  Profile Tagline
                                  <input
                                    type="text"
                                    name="profileTagline"
                                    className="form-control"
                                    placeholder="Scott"
                                    value={realtorFormData.profileTagline}
                                    onChange={handleChangeRealtorData}
                                  />
                                  {error?.profileTagline && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.profileTagline}
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div className="right_cont_dt">
                              <div className="single_entry">
                                <label>
                                  Company name
                                  <input
                                    type="text"
                                    name="companyName"
                                    className="form-control"
                                    placeholder="Premier Realty Group"
                                    value={brokerFormData.companyName}
                                    onChange={handleChangeBrokerData}
                                  />
                                  {error?.companyName && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.companyName}
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="principal_text_area">
                        <h3>Bio </h3>
                        <div className="form-group">
                          <textarea
                            id="ampHeaderTitle"
                            name="bio"
                            maxLength={150}
                            className="form-control area_control"
                            placeholder="Write your bio here.."
                            value={
                              userType == 1
                                ? brokerFormData.bio
                                : realtorFormData.bio
                            }
                            onChange={(e) => handleChange(e)}
                          />
                          {error?.bio && (
                            <p
                              style={{
                                color: "red",
                                fontWeight: "400",
                                marginLeft: "10px",
                                fontSize: "13px",
                              }}
                            >
                              {error?.bio}
                            </p>
                          )}
                        </div>
                      </div>

                      {userType == 0 && (
                        <div className="tags_set">
                          <div
                            className=" contact_detail title_bar_setting"
                            style={{ marginTop: "20px" }}
                          >
                            <h3 className="letter_darkbold">Meta Title</h3>

                            <div className="form-group">
                              <label htmlFor="callMeNowPhone">
                                This title will be appended to the page name for
                                each of your pages, unless you override it by
                                providing a page specific meta title in its
                                Advanced Page Settings.
                              </label>

                              <div className="d-flex custom_fcontrol">
                                <div className="left_farea">
                                  <input
                                    type="text"
                                    id="metatitle"
                                    className="form-control"
                                    name="metaTitle"
                                    placeholder="Write your meta title"
                                    value={realtorFormData.metaTitle}
                                    onInput={(e) =>
                                      (e.target.value = e.target.value.replace(
                                        /[^a-zA-Z ]/g,
                                        ""
                                      ))
                                    }
                                    onChange={handleChangeRealtorData}
                                  />
                                  {error?.metaTitle && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.metaTitle}
                                    </p>
                                  )}
                                </div>
                                <div className="right_farea">
                                  Should be no more than 8-10 words
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" contact_detail title_bar_setting second_input">
                            <h3 className="letter_darkbold">Meta Description</h3>

                            <div className="form-group">
                              <label htmlFor="callMeNowPhone">
                                This meta description will be used for your
                                homepage, and as the default description for
                                each of your pages, unless you override it by
                                providing a page specific description in its
                                Advanced Page Settings.
                              </label>

                              <div className="d-flex custom_fcontrol">
                                <div className="left_farea">
                                  <input
                                    type="text"
                                    id="metatitle"
                                    className="form-control"
                                    name="metaDescription"
                                    placeholder="Write your meta title"
                                    value={realtorFormData.metaDescription}
                                    onInput={(e) =>
                                      (e.target.value = e.target.value.replace(
                                        /[^a-zA-Z ]/g,
                                        ""
                                      ))
                                    }
                                    onChange={handleChangeRealtorData}
                                  />
                                  {error?.metaDescription && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.metaDescription}
                                    </p>
                                  )}
                                </div>
                                <div className="right_farea">
                                  Should be no more than 8-10 words
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="contact_detail title_bar_setting">
                        <h3 className="letter_darkbold">Contact Details</h3>
                        <div className="contract_rows d-flex">
                          {userType == 1 ? (
                            <div className="left_contact_dt">
                              <div className="single_entry">
                                <label>
                                  Fax ID
                                  <input
                                    name="faxId"
                                    maxLength={15}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your Fax ID here"
                                    value={getFaxIdFormat(brokerFormData.faxId)}
                                    onChange={handleChangeBrokerData}
                                  />
                                  {error?.faxId && (
                                    <p
                                      style={{
                                        color: "red",
                                        fontWeight: "400",
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {error?.faxId}
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                          ) : null}

                          <div className="right_cont_dt ">
                            <div className="single_entry">
                              <label>
                                License No.
                                <input
                                  type="text"
                                  maxLength={10}
                                  name="license"
                                  className="form-control"
                                  placeholder="Enter your license no.."
                                  value={
                                    userType == 1
                                      ? brokerFormData.license
                                      : realtorFormData.license
                                  }
                                  onChange={(e) => handleChange(e)}
                                />
                                {error?.license && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      marginLeft: "10px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {error?.license}
                                  </p>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="contract_rows d-flex">
                          <div className="left_contact_dt ctbt">
                            <div className="single_entry">
                              <label>
                                Address
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control"
                                  placeholder="Enter your address"
                                  value={
                                    userType == 1
                                      ? brokerFormData.address
                                      : realtorFormData.address
                                  }
                                  onChange={(e) => handleChange(e)}
                                />
                                {error?.address && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      marginLeft: "10px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {error?.address}
                                  </p>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="contract_rows d-flex">
                          <div className=" only_forms_set d-flex ctbt">
                            <div className="single_entry">
                              <label>
                                City
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  placeholder="City"
                                  value={
                                    userType == 1
                                      ? brokerFormData.city
                                      : realtorFormData.city
                                  }
                                  onChange={(e) => handleChange(e)}
                                />
                                {error?.city && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      marginLeft: "10px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {error?.city}
                                  </p>
                                )}
                              </label>
                            </div>

                            <div className="single_entry">
                              <label>
                                State
                                <input
                                  type="text"
                                  name="state"
                                  className="form-control"
                                  placeholder="State"
                                  value={
                                    userType == 1
                                      ? brokerFormData.state
                                      : realtorFormData.state
                                  }
                                  onChange={(e) => handleChange(e)}
                                />
                                {error?.state && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      marginLeft: "10px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {error?.state}
                                  </p>
                                )}
                              </label>
                            </div>

                            <div className="single_entry">
                              <label>
                                Zip Code
                                <input
                                  type="number"
                                  name="zipCode"
                                  className="form-control"
                                  placeholder="Zip Code"
                                  value={
                                    userType == 1
                                      ? brokerFormData.zipCode
                                      : realtorFormData.zipCode
                                  }
                                  onChange={(e) => {
                                    if (e.target.value.length <= 5) {
                                      handleChange(e);
                                    }
                                  }}
                                  onKeyDown={handleZipCodeKeyDown}
                                />
                                {error?.zipCode && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontWeight: "400",
                                      marginLeft: "10px",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {error?.zipCode}
                                  </p>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* {userType == 1 && (
                          <div className="selectors">
                            <h3>Number of Agents</h3>
                            <div className="contract_rows d-flex">
                              {
                                agenttype == 0 ? (
                                  <div className="left_contact_dt">
                                    <label className="custom-radio-container">
                                      Independent
                                      <input value={agentType} type="radio" name="agentType" className="custom-radio" onChange={() => setAgentType(0)} checked={agentType === 0} />
                                      <span className="radio-checkmark"></span>
                                    </label>
                                  </div>
                                ) : (
                                  <div className="right_cont_dt d-flex">
                                    <label className="custom-radio-container">
                                      Multi Agents
                                      <input value={agentType} type="radio" name="agentType" className="custom-radio" onChange={() => setAgentType(1)} checked={agentType === 1} />
                                      <span className="radio-checkmark"></span>
                                    </label>
                                    {agentType === 1 && (
                                      <div className="hidden_selector">
                                        <div className="chauka">
                                          <input type="number" value={brokerFormData.numberOfAgents || 0} onChange={onInputChange} className="form-control" />
                                          <Slider min={0} max={2000} value={brokerFormData.numberOfAgents || 0} onChange={onSliderChange} className="my-slider" />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )} */}
                      </div>

                      <div className="contact_detail tds">
                        <h3 className="letter_darkbold">Service Area</h3>
                        <Select
                          // defaultValue={[colourOptions[2]]}
                          isMulti
                          name="cities"
                          menuPlacement="top"
                          menuPosition="fixed"
                          options={cityOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          closeMenuOnSelect={false}
                          styles={customStyles}
                          value={selectedCities.map((city) => ({
                            value: city,
                            label: city,
                          }))}
                          onChange={handleCityChange}
                        />
                      </div>

                      <div className="selector_btn">
                        <button
                          className="btn update_btn"
                          onClick={handleUpdateProfile}
                        >
                          Save
                        </button>
                      </div>
                      <ModalMessage
                        show={showModal}
                        handleClose={() => setShowModal(false)}
                        handleSave={handleSave}
                        message="Do you really want to update your Profile?"
                        img={Shield}
                        leftButton="Cancel"
                        rightButton="Save"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
export default BrokerProfileEdit;
