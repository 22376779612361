import React from "react";
import ReactSlider from "react-slider";
import Pending from "../../../assets/pending.gif";

const UploadingRealtorDataModal = ({ uploadPercentage, handleSliderChange, isUploadingModalOpen, closeModal, onSetIsUploadingModalOpen }) => {
  return (
    <div
      className={`modal fade ${isUploadingModalOpen ? "show" : ""}`}
      id="datauploading"
      style={{
        display: isUploadingModalOpen ? "block" : "none",
      }}
      tabIndex="-1"
      aria-hidden={!isUploadingModalOpen}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body csv-upload-modal">
            <h3>The realtor's profile data is uploading.</h3>
            <div className="uploader_image_slider">
              <img src={Pending} alt="pending" />
              <div className="calculation_zone">
                <div className="slider-container">
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={100} step={1} value={uploadPercentage} onChange={handleSliderChange} />
                  <div className="percentage-box" style={{ left: `${uploadPercentage}%` }}>
                    {uploadPercentage}%
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer csv_footer">
            <button type="button" className="btn btn-upload" onClick={() => onSetIsUploadingModalOpen(false)}>
              Minimize
            </button>
            <button type="button" className="btn btn-cancel" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadingRealtorDataModal;
