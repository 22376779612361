const initialState = {
  token: null,
  status: null,
  email: null,
  regEmail: null,
  regToken: null,
  refreshToken: null,
  profileImage: null,
  userType: null,
  companyname: null,
  firstName: null,
  lastName: null,
  brokerId: null,
  favicon: null,
  websiteLogo: null,
  setBrandingDataFetched: false,
  tokenExpTime: null,
  agentType: null,
  brokerProfileDetails: null,
  realtorProfileDetails: null,
  subscribed: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload.token,
      };
    case "DEL_TOKEN":
      return {
        ...state,
        token: null,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload.status,
      };
    case "SET_PROFILE":
      return {
        ...state,
        profileImage: action.payload.profileImage,
      };
    case "FIRSTNAME":
      return {
        ...state,
        firstName: action.payload.firstName,
      };
    case "LASTNAME":
      return {
        ...state,
        lastName: action.payload.lastName,
      };
    case "SET_FULLNAME":
      return {
        ...state,
        fullname: action.payload.fullname,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload.email,
      };
    case "SET_REGEMAIL":
      return {
        ...state,
        regEmail: action.payload.regEmail,
      };
    case "SET_REGTOKEN":
      return {
        ...state,
        regToken: action.payload.regToken,
      };
    case "SET_SUBSCRIBED":
      return {
        ...state,
        subscribed: action.payload.subscribed,
      };
    case "SET_TOKEN_EXP_TIME":
      return {
        ...state,
        tokenExpTime: action.payload.tokenExpTime,
      };
    case "SET_REFRESHTOKEN":
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
      };
    case "SET_CATEGORY":
      return {
        ...state,
        category: action.payload.category,
      };
    case "DEL_PASSWORD":
      return {
        ...state,
        password: null,
      };
    case "USER_TYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "AGENT_TYPE":
      return {
        ...state,
        agentType: action.payload,
      };
    case "USERTYPE":
      return {
        ...state,
        UserType: action.payload,
      };
    case "BROKER_ID":
      return {
        ...state,
        brokerId: action.payload,
      };
    case "SET_COMPANYNAME":
      return {
        ...state,
        companyname: action.payload,
      };
    case "SET_LOGOUT":
      return {
        ...state,
        regToken: null,
        refreshToken: null,
      };
    case "SET_BRANDING_DATA":
      return {
        ...state,
        favicon: action.payload.favicon,
        websiteLogo: action.payload.websiteLogo,
      };
    case "SET_BRANDING_DATA_FETCHED":
      return {
        ...state,
        setBrandingDataFetched: action.payload,
      };
    case "SET_BROKER_PROFILE_DATA":
      return {
        ...state,
        brokerProfileDetails: action.payload,
      };
    case "SET_REALTOR_PROFILE_DATA":
      return {
        ...state,
        realtorProfileDetails: action.payload,
      };
    default:
      return state;
  }
};
export default authReducer;
