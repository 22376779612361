import React, { useState, useEffect } from "react";
import axios from "axios";
import "../SettingPages.css";
import Url from "../../../Element/Api";
import { useNavigate } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import Shield from "../../../../assets/shield.svg";
import ModalMessage from "../../../ModalMessage/Modal";
import BaseLayout from "../../../../layouts/BaseLayout";

function GoogleAnalytics() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    metaTag: "",
    status: false,
  });
  const [errors, setErrors] = useState({
    code: "",
    metatag: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    const newValue = type === "checkbox" ? checked : value;
    const finalValue = name === "status" ? checked : newValue;
    setFormData({
      ...formData,
      [name]: finalValue,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to retrieve data
        const response = await axios.get(`${Url.BASEURL}/broker/google-analytics`, config);

        // If data is present, update the state for prefilling the form
        if (response.data) {
          setFormData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data using GET request when the component mounts
    fetchData();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setShowModal(true);
  };

  // To create or update the data
  const handleSave = async () => {
    setErrors("");
    try {
      // Make a POST request to update data
      await axios.post(`${Url.BASEURL}/broker/google-analytics`, formData, config);
      navigate("/");

      // You can handle success scenarios here (e.g., show a success message)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        setErrors({
          code: apiErrors.code || "",
          metatag: apiErrors.metatag || "",
        });
      } else {
        console.error("Error updating data:", error);
      }
      setShowModal(false);
    }
  };

  // For cancel button
  const handleCancel = () => {
    navigate("/");
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages ga">
        <h2>Google Analytics</h2>
        <p>
          You can use <a href="https://analytics.google.com/analytics/attribution/onboarding">Google Analytics</a> to track your website traffic. You must register with <a href="https://analytics.google.com/analytics/attribution/onboarding">Google Analytics</a> before activating this feature. Use the form below to set up the tracking option on your website.
        </p>

        <div className="title_bar_setting">
          <h3>
            Code Here <span className="smaller_text">It can take up to 24 hours for results to appear on Google Analytics.</span>
          </h3>
          <div className="form-group">
            <textarea id="code" className="form-control area_control" name="code" value={formData.code} onChange={handleChange} placeholder="Write your title" />
            {errors.code && <p style={{ color: "red", fontSize: "12px" }}>{errors.code}</p>}
          </div>

          <label className="custom-checkbox-container">
            <input type="checkbox" className="custom-checkbox" name="status" checked={formData.status} onChange={handleChange} />
            <span className="checkmark"></span>
            <span className="spannerr"> Include a “Get Pre-Approved” link </span>
          </label>
        </div>

        <div className="title_bar_setting second_input">
          <div className="form-group">
            <label htmlFor="callMeNowPhone">If Google requires you to add a verification meta-tag, add the "content" field for the meta-tag here:</label>
            <input type="text" id="metatag" className="form-control" name="metatag" value={formData.metatag} onChange={handleChange} placeholder="Write your meta tag here" />
            {errors.metatag && <p style={{ color: "red", fontSize: "12px" }}>{errors.metatag}</p>}
          </div>
        </div>
        <div className="two_btns">
          <button className="btn update_btn" onClick={handleUpdate}>
            Save
          </button>
          <button className="btn cancel_btn" onClick={handleCancel}>
            Cancel
          </button>
        </div>
        <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={handleSave} message="Do you really want to save your Google Analytics?" img={Shield} leftButton="Cancel" rightButton="Save" />
      </div>
    </BaseLayout>
  );
}

export default GoogleAnalytics;
