import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import axios from "axios";
import Url from "../../Element/Api";
import { LineChart, Line } from "recharts";
import { ClipLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";
import { getInitials } from "../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ActivityChart from "./components/ActivityChart";
import { setAgentType } from "../../../Redux/Action/Authaction";
import { setBrandingData } from "../../../Redux/Action/Authaction";
import DashboardHelpSection from "./components/DashboardHelpSection";
import { setBrandingDataFetched } from "../../../Redux/Action/Authaction";
import { setBrokerId, setBrokerProfileDetails, setRealtorProfileDetails } from "../../../Redux/Action/Authaction";

const blockData = [
  {
    iconClass: "icon-vector4",
    number: 0,
    text: "",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 400 }],
    color: "#007AFF",
    blockClass: "block-color1",
  },
  {
    iconClass: "icon-vector0",
    number: 0,
    text: "Leads",
    chartData: [{ uv: 200 }, { uv: 100 }, { uv: 200 }, { uv: 310 }, { uv: 20 }, { uv: 325 }, { uv: 800 }],
    color: "#FF9600",
    blockClass: "block-color2",
  },
  {
    iconClass: "icon-Vector1",
    number: 0,
    text: "Total Transactions",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 1200 }],
    color: "#28AC47",
    blockClass: "block-color3",
  },
  {
    iconClass: "icon-Vector2",
    number: 0, // Placeholder value, will be updated from API
    text: "Active Listings",
    chartData: [{ uv: 300 }, { uv: 500 }, { uv: 100 }, { uv: 600 }, { uv: 50 }, { uv: 100 }, { uv: 400 }],
    color: "#ED4949",
    blockClass: "block-color4",
  },
];

function getFaviconEl() {
  return document.getElementById("favicon");
}

const Dashboard = () => {
  const [config] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userType = useSelector((state) => state.userType);
  const favlogo = useSelector((state) => state.favicon);
  const regtoken = useSelector((state) => state.regToken);
  const agentType = useSelector((state) => state.agentType);
  const [revenue, setRevenue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [teamMates, setTeamMates] = useState([]);
  const [leadsCount, setLeadsCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalRealtor, setTotalRealtor] = useState(0);
  const [realtorCount, setRealtorCount] = useState(0);
  const [realtorsCount, setRealtorsCount] = useState(0);
  const [activeListings, setActiveListings] = useState(0);
  const [investedAmount, setInvestedAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);

  useEffect(() => {
    localStorage.removeItem("isPollingStart");
    setLoading(true);
    const profileApiUrl = userType == 1 ? `${Url.BASEURL}/broker/profile` : `${Url.BASEURL}/broker/realtor/realtor-profile`;
    axios
      .get(profileApiUrl, config)
      .then((response) => {
        if (userType == 1) {
          dispatch(setBrokerProfileDetails(response?.data));
          dispatch(setAgentType(response?.data?.agentType));
        } else if (userType == 0) {
          dispatch(setRealtorProfileDetails(response?.data?.realtor));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [userType]);

  useEffect(() => {
    const fetchBrandingData = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/broker/panel-brandings`, config);
        const { success, data } = response.data;

        if (success && data) {
          dispatch(setBrandingData(data.favicons, data.websiteLogos));
        } else {
          console.error("Failed to fetch branding data:", response.data);
        }
      } catch (error) {
        console.error("Error fetching branding data:", error);
      }
      dispatch(setBrandingDataFetched(true));
    };

    if (regtoken != null) {
      fetchBrandingData();
    }
  }, [regtoken]);

  useEffect(() => {
    const updateFavicon = () => {
      if (favlogo) {
        const favicon = getFaviconEl();
        if (favlogo) {
          favicon.href = favlogo;
        } else {
          const newFavicon = document.createElement("link");
          newFavicon.rel = "icon";
          newFavicon.href = favlogo;
          document.head.appendChild(newFavicon);
        }
      } else {
        console.log("Brandings data or favicon not found.");
      }
    };
    if (regtoken != null) {
      updateFavicon();
    }
  }, [favlogo, regtoken]);

  const TeamMate = ({ id, name, sales, imgSrc }) => (
    <div role="button" className="teammate" onClick={() => navigate(`/profile/${id}`)}>
      {imgSrc ? <img src={imgSrc} alt={name} className="teammate-img" /> : <div className="teammate-initials pitials">{getInitials(name)}</div>}
      <div className="teammate-info zero_dep">
        <h3 className="text-capitalize">{name.length > 15 ? name.substring(0, 16) + "..." : name}</h3>
        <div className="sales-link">{sales} Realtor</div>
      </div>
    </div>
  );

  useEffect(() => {
    if (regtoken != null) {
      setLoading(true);
      axios
        .get(Url.BASEURL + `/broker/all-realtors?pageSize=5&page=1`, config)
        .then((value) => {
          setTeamMates(value.data.realtors);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [regtoken]);

  useEffect(() => {
    if (regtoken != null) {
      axios
        .get(Url.BASEURL + `/broker/get-dashboard`, config)
        .then((response) => {
          const propertyCount = response.data.propertyCount;
          const transactionCount = response.data.transactionCount;
          const leadsCount = response.data.leadsCount;
          const totalPaidAmount = response.data.totalPaidAmount;
          const revenue = response.data.Revenue;
          const investedAmount = response.data.Investedamount;
          const totalRevenue = response.data.totalRevenue;
          const totalRealtor = response.data.totalRealtor;
          const realtorCount = response.data.RealtorCount;
          setActiveListings(propertyCount);
          setRealtorsCount(transactionCount);
          setLeadsCount(leadsCount);
          setTotalPaidAmount(totalPaidAmount);
          setRevenue(revenue);
          setInvestedAmount(investedAmount);
          setTotalRevenue(totalRevenue);
          setTotalRealtor(totalRealtor);
          setRealtorCount(realtorCount);
          setTransactionCount(transactionCount);
          dispatch(setBrokerId(response.data.userId));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [regtoken]);

  if (userType == 1) {
    const updatedBlockData = [...blockData];
    updatedBlockData[3].number = activeListings;
    updatedBlockData[1].number = leadsCount;
    updatedBlockData[2].number = realtorCount;
    updatedBlockData[0].number = totalRevenue?.toFixed(2);
    updatedBlockData[0].text = revenue;
    updatedBlockData[2].text = totalRealtor;
  } else if (userType == 0) {
    const updatedBlockData = [...blockData];
    updatedBlockData[3].number = activeListings;
    updatedBlockData[1].number = leadsCount;
    updatedBlockData[2].number = transactionCount;
    updatedBlockData[0].number = totalPaidAmount?.toFixed(2);
    updatedBlockData[0].text = investedAmount;
  }

  const blockHeaderData = agentType == 1 || userType == 0 ? blockData : blockData.filter((el) => el.text !== "Total Realtor");

  return (
    <div className="dashboard">
      {loading ? (
        <div className="d-flex justify-content-center mt-5">
          <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <div className="container-fluid main_container">
          <div className="row">
            <div className="col-lg-10 col-sm-12 wid_adjust">
              <div className="row abc">
                {blockHeaderData.map((block, index) => (
                  <div className={`${agentType == 1 || userType == 0 ? "col-lg-3" : "col-lg-4"} halowon col-sm-12 col-md-6 ${block.blockClass}`} key={index}>
                    <div className="block">
                      <div className="icon-bg">
                        <span className={block.iconClass}></span>
                      </div>
                      <div className="chart-space">
                        <LineChart width={100} height={50} data={block.chartData}>
                          <Line type="monotone" dataKey="uv" stroke={block.color} strokeWidth={3} dot={false} />
                        </LineChart>
                        <p>{block.chartText}</p>
                      </div>
                      <div className="number">{index === 0 ? `$${block.number}` : index === 1 ? `${block.number}` : block.number}</div>
                      <p className="blockp">{block.text}</p>
                    </div>
                  </div>
                ))}
              </div>

              <ActivityChart />
            </div>

            {userType == 1 && agentType == 1 ? (
              <div className="col-lg-2 col-sm-12 wid_set mater">
                <div className="mutual_blocks team_mamet">
                  <div className="heading_inline d-flex">
                    <div className="left_head">
                      <h2>Realtors</h2>
                    </div>
                    <div className="right_head">
                      <Link to="/realtor" className="details-link">
                        View All
                      </Link>
                    </div>
                  </div>
                  <div className="teammate_list d_board_only">{teamMates.length === 0 ? <p>No teammates found</p> : teamMates.filter((el) => el.status === 1).map((mate, idx) => <TeamMate key={idx} id={mate._id} name={mate?.firstname + " " + mate?.lastname} imgSrc={mate?.imageURL ? mate?.imageURL : ""} />)}</div>
                </div>
              </div>
            ) : null}
          </div>
          <DashboardHelpSection />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
