import React, { useState, useEffect } from "react";
import axios from "axios";
import "../SettingPages.css";
import Url from "../../../Element/Api";
import { useNavigate } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import Shield from "../../../../assets/shield.svg";
import ModalMessage from "../../../ModalMessage/Modal";
import BaseLayout from "../../../../layouts/BaseLayout";

function MetaTags() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    metaDescription: "",
    metaKeywords: "",
  });
  const [errors, setErrors] = useState({
    title: "",
    metaDescription: "",
    metaKeywords: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let truncatedValue = value;

    // Check if the input is metaDescription and truncate if necessary
    if (name === "metaDescription" && value.length > 140) {
      truncatedValue = value.substring(0, 140);
    }

    // Check if the input is title and truncate if necessary
    if (name === "title" && value.length > 70) {
      truncatedValue = value.substring(0, 70);
    }

    setFormData({
      ...formData,
      [name]: truncatedValue,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/broker/meta-tag`, config);
        if (response.data) {
          setFormData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSave = async () => {
    setErrors("");
    try {
      await axios.post(`${Url.BASEURL}/broker/meta-tag`, formData, config);
      navigate("/");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        const apiErrors = error.response.data.error;
        setErrors({
          title: error.response.data.error || "",
          metaDescription: "",
          metaKeywords: "",
        });
      } else if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        setErrors({
          title: apiErrors.title || "",
          metaDescription: apiErrors.metaDescription || "",
          metaKeywords: apiErrors.metaKeywords || "",
        });
      } else {
        console.error("Error updating data:", error);
      }
      setShowModal(false);
    }
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages mtags">
        <h2>Meta Tags</h2>
        <p>Instructions: Your Title, Description and Keywords Meta Tags are used by search engines to help rank your web pages. Please do not attempt to format the text or insert html coding.</p>

        <div className="title_bar_setting">
          <h3>Title</h3>

          <div className="form-group">
            <label htmlFor="ampHeaderTitle">This title will be appended to the page name for each of your pages, unless you override it by providing a page specific meta title in its Advanced Page Settings.</label>
            <div className="d-flex custom_fcontrol align-items-center">
              <div className="left_farea">
                <input
                  type="text"
                  id="metaTitleInput"
                  className="form-control"
                  placeholder="Write your title here"
                  name="title"
                  value={formData.title}
                  onChange={(e) => {
                    if (e?.target?.value?.split(" ").length <= 10) {
                      handleChange(e);
                    }
                  }}
                />
              </div>
              <div className="right_farea">Should be no more than 60-70 keywords</div>
            </div>
            {errors.title && <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{errors.title}</p>}
          </div>
        </div>

        <div className="center_border"></div>

        <div className="title_bar_setting second_input">
          <h3>Meta Description</h3>

          <div className="form-group">
            <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>
            <div className="d-flex custom_fcontrol align-items-center">
              <div className="left_farea">
                <input
                  type="text"
                  id="metaDescriptionInput"
                  className="form-control"
                  name="metaDescription"
                  value={formData.metaDescription}
                  onChange={(e) => {
                    var val = e.target.value;
                    var sentences = val.split(/[\.!?]+/);
                    const len = sentences.length - 1;
                    if (len <= 3) {
                      handleChange(e);
                    }
                  }}
                  placeholder="Write your meta description"
                />
              </div>
              <div className="right_farea">Should be no more than 130-140 keywords</div>
            </div>
            {errors.metaDescription && <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{errors.metaDescription}</p>}
          </div>
        </div>

        <div className="center_border"></div>

        <div className="title_bar_setting second_input">
          <h3>Meta Keywords</h3>
          <div className="form-group">
            <div className="d-flex custom_fcontrol align-items-center">
              <div className="left_farea">
                <input type="text" id="metaKeywordsInput" className="form-control" name="metaKeywords" value={formData.metaKeywords} onChange={handleChange} placeholder="Write your meta keywords" />
              </div>
              <div className="right_farea"></div>
            </div>
            {errors.metaKeywords && <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>{errors.metaKeywords}</p>}
          </div>
        </div>

        <div className="two_btns">
          <button className="btn update_btn" onClick={handleUpdate}>
            Save
          </button>
          <button
            className="btn cancel_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            Cancel
          </button>
        </div>

        <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={handleSave} message="Do you really want to save your Meta Tags?" img={Shield} leftButton="Cancel" rightButton="Save" />
      </div>
    </BaseLayout>
  );
}

export default MetaTags;
