import { useSelector } from "react-redux";

const useAuth = () => {
  const regtoken = useSelector((state) => state.regToken);

  const config = {
    headers: {
      Authorization: `Bearer ${regtoken}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  const configFormData = {
    headers: {
      Authorization: `Bearer ${regtoken}`,
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return [config, configFormData];
};

export default useAuth;
