import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../Element/Api";
import Select from "react-select";
import "./RealtorProfileSetup.css";
import Logo from "../../assets/logo.png";
import useAuth from "../../hooks/useAuth";
import { useDispatch } from "react-redux";
import Edit from "../../assets/pencil.svg";
import { exceptThisSymbols } from "../../utils/utils";
import RealtorPaymentModal from "./RealtorPaymentModal";
import { setProfileImage, setUserType } from "../../Redux/Action/Authaction";

function RealtorProfile() {
  const dispatch = useDispatch();
  const [config, configFormData] = useAuth();
  const [imageFile, setImageFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [imagePreview, setImagePreview] = useState("https://via.placeholder.com/150");
  const [userInputForprofileSetup, setUserInputForprofileSetup] = useState({
    profileTagline: "",
    bio: "",
    address: "",
    license: "",
    metaTitle: "",
    metaDescription: "",
    zipCode: "",
    city: "",
    state: "",
  });

  const [error, setError] = useState({
    profileTagline: "",
    bio: "",
    address: "",
    license: "",
    metaTitle: "",
    metaDescription: "",
    serviceArea: "",
    zipCode: "",
    city: "",
    state: "",
    image: "",
  });

  const handleCityChange = (selectedOptions) => {
    setError((prevErrors) => ({
      ...prevErrors,
      serviceArea: "",
    }));
    const valuesArray = selectedOptions.map((option) => option.value);
    setSelectedCities(valuesArray);
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(Url.BASEURL + "/broker/realtor/get-all-cities-from-broker", config);
        const cities = response.data;
        const formattedCities = cities.map((city) => ({ value: city, label: city }));
        setCityOptions(formattedCities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: "478px !important",
    }),
  };

  const handleChangeForProfileSetup = (e) => {
    const { name, value } = e.target;
    const onlyAlphabets = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const onlyNumber = e.target.value.replace(/[^0-9 ]/, "");
    const numberAlphabets = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    const valueToSet = name == "profileTagline" || name == "city" || name == "state" ? onlyAlphabets : name == "zipCode" ? onlyNumber : name == "faxId" || name == "license" ? numberAlphabets : value;

    setUserInputForprofileSetup({
      ...userInputForprofileSetup,
      [name]: valueToSet,
    });
    setError((prevError) => {
      const { [name]: removedError, ...rest } = prevError;
      return rest;
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
      // formData.append("image", file);
      const reader = new FileReader();
      setImageFile(file);
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSetUpFee = (e) => {
    axios
      .post(`${Url.BASEURL}/broker/realtor/realtor-setup-fee`, {}, configFormData)
      .then((response) => {
        if (response.data) {
          const value = response.data;
          const linkToNavigate = value.session.url;
          window.location.href = linkToNavigate;
          setIsDisable(false);
        }
      })
      .catch((err) => {
        console.error("Error submitting form:", err);
        setIsDisable(false);
      });
  };

  const handleSubmit = (e) => {
    setIsDisable(true);
    let formData = new FormData();
    if (!formData.has("profileTagline")) {
      formData.append("image", imageFile);
      formData.append("profileTagline", userInputForprofileSetup.profileTagline);
      formData.append("bio", userInputForprofileSetup.bio);
      formData.append("address", userInputForprofileSetup.address);
      formData.append("license", userInputForprofileSetup.license);
      formData.append("metaTitle", userInputForprofileSetup.metaTitle);
      formData.append("metaDescription", userInputForprofileSetup.metaDescription);
      formData.append("serviceArea", selectedCities);
      formData.append("city", userInputForprofileSetup.city);
      formData.append("state", userInputForprofileSetup.state);
      formData.append("zipCode", userInputForprofileSetup.zipCode);
    }

    axios
      .post(`${Url.BASEURL}/broker/realtor/add-details`, formData, configFormData)
      .then((response) => {
        const value = response.data;
        if (value && value.success) {
          dispatch(setUserType(value.userType));
          localStorage.setItem("userType", value?.userType);
          const image = value?.realtor?.image ? value?.realtor?.image : value?.broker?.image;
          dispatch(setProfileImage(Url.IMGURL + image));
          setIsDisable(false);
          setShowModal(true);
          // handleSetUpFee();

          // if (value.userType == 1) {
          //   navigate("/");
          // } else if (value.userType == 0) {
          //   navigate("/payment");
          // }
        }
      })
      .catch((err) => {
        const apiErrors = err.response?.data?.errors || {};
        setError({
          profileTagline: apiErrors.profileTagline || "",
          bio: apiErrors.bio || "",
          address: apiErrors.address || "",
          license: apiErrors.license || "",
          metaTitle: apiErrors.metaTitle || "",
          metaDescription: apiErrors.metaDescription || "",
          zipCode: apiErrors.zipCode || "",
          city: apiErrors.city || "",
          state: apiErrors.state || "",
          serviceArea: apiErrors.serviceArea || "",
          image: apiErrors.image || "",
        });
        setIsDisable(false);
      });
  };

  const handleZipCodeKeyDown = (e) => {
    if (e.target.value.length >= 5 && e.key !== "Backspace") {
      e.preventDefault();
    }
    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container container_bg custom_principal_page">
        <div className="row ">
          <div className="col-lg-12">
            <div className="principal_broker">
              <div className="top_principal d-flex">
                <div className="left_principal image_uploader">
                  <img src={imagePreview} alt="Uploaded" className="responsive_image" />
                  <input type="file" onChange={handleImageChange} style={{ display: "none" }} id="imageInput" accept=".png, .jpg, .jpeg" />
                  <label htmlFor="imageInput" className="edit-icon-image">
                    <img src={Edit} alt="" role="button" />
                  </label>
                  {error.image && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error.image}</p>}
                </div>
                <div className="right_principal title_bar_setting">
                  <div className="single_entry">
                    <label>
                      Profile Tagline
                      <input
                        name="profileTagline"
                        value={userInputForprofileSetup.profileTagline}
                        // onChange={(e) => {
                        //   const sanitizedValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
                        //   handleChangeForProfileSetup({
                        //     target: {
                        //       name: "profileTagline",
                        //       value: sanitizedValue,
                        //     },
                        //   });
                        // }}
                        onChange={handleChangeForProfileSetup}
                        type="text"
                        className="form-control light-grey-input"
                        placeholder="Write your profile tagline"
                      />
                      {error.profileTagline && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error.profileTagline}</p>}
                    </label>
                  </div>

                  <div className="principal_text_area">
                    <h3>Bio data </h3>
                    <div className="form-group">
                      <textarea
                        name="bio"
                        value={userInputForprofileSetup.bio}
                        // onChange={(e) => {
                        //   const sanitizedValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
                        //   handleChangeForProfileSetup({
                        //     target: {
                        //       name: "bio",
                        //       value: sanitizedValue,
                        //     },
                        //   });
                        // }}
                        onChange={handleChangeForProfileSetup}
                        id="ampHeaderTitle"
                        className="form-control area_control"
                        placeholder="Write your Bio.."
                      />
                      {error.bio && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error.bio}</p>}
                    </div>
                  </div>
                </div>

                <div className="contact_detail title_bar_setting">
                  <h3 className="letter_darkbold">Contact Details</h3>
                  <div className="contract_rows d-flex">
                    <div className="right_cont_dt">
                      <div className="single_entry">
                        <label>
                          License No.
                          <input
                            name="license"
                            maxLength={15}
                            value={userInputForprofileSetup.license}
                            // onChange={(e) => {
                            //   const value = e.target.value.replace(/[ .]/g, "");
                            //   handleChangeForProfileSetup({
                            //     target: {
                            //       name: "license",
                            //       value,
                            //     },
                            //   });
                            // }}
                            onChange={handleChangeForProfileSetup}
                            type="text"
                            className="form-control"
                            placeholder="Enter your license"
                          />
                          {error.license && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error.license}</p>}
                        </label>
                      </div>
                    </div>
                    <div className="left_contact_dt">
                      <div className="single_entry">
                        <label>
                          Address
                          <input
                            name="address"
                            value={userInputForprofileSetup.address}
                            // onChange={(e) => {
                            //   const sanitizedValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
                            //   handleChangeForProfileSetup({
                            //     target: {
                            //       name: "address",
                            //       value: e.target.value,
                            //     },
                            //   });
                            // }}
                            onChange={handleChangeForProfileSetup}
                            type="text"
                            className="form-control"
                            placeholder="Enter your address"
                          />
                          {error.address && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error.address}</p>}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact_detail title_bar_setting">
                  <div className=" only_forms_set d-flex ctbt">
                    <div className="single_entry">
                      <label>
                        City
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="City"
                          value={userInputForprofileSetup.city}
                          // onChange={(e) => {
                          //   const sanitizedValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
                          //   handleChangeForProfileSetup({
                          //     target: {
                          //       name: "city",
                          //       value: sanitizedValue,
                          //     },
                          //   });
                          // }}
                          onChange={handleChangeForProfileSetup}
                        />
                        {error?.city && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error?.city}</p>}
                      </label>
                    </div>

                    <div className="single_entry">
                      <label>
                        State
                        <input
                          type="text"
                          name="state"
                          className="form-control"
                          placeholder="State"
                          value={userInputForprofileSetup.state}
                          // onChange={(e) => {
                          //   const sanitizedValue = e.target.value.replace(/[^a-zA-Z ]/g, "");
                          //   handleChangeForProfileSetup({
                          //     target: {
                          //       name: "state",
                          //       value: sanitizedValue,
                          //     },
                          //   });
                          // }}
                          onChange={handleChangeForProfileSetup}
                        />
                        {error?.state && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error?.state}</p>}
                      </label>
                    </div>

                    <div className="single_entry">
                      <label>
                        Zip Code
                        <input
                          type="number"
                          name="zipCode"
                          className="form-control"
                          placeholder="Zip Code"
                          value={userInputForprofileSetup.zipCode}
                          // onChange={(e) => {
                          //   const sanitizedValue = e.target.value.replace(/[^0-9 ]/, "");
                          //   if (sanitizedValue.length <= 5) {
                          //     handleChangeForProfileSetup({
                          //       target: {
                          //         name: "zipCode",
                          //         value: sanitizedValue,
                          //       },
                          //     });
                          //   }
                          // }}
                          onChange={(e) => {
                            if (e.target.value.length <= 5) {
                              handleChangeForProfileSetup(e);
                            }
                          }}
                          onKeyDown={handleZipCodeKeyDown}
                        />
                        {error?.zipCode && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error?.zipCode}</p>}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="tags_set">
                  <div className=" contact_detail title_bar_setting" style={{ marginTop: "20px" }}>
                    <h3 className="letter_darkbold">Meta Title</h3>

                    <div className="form-group">
                      <label htmlFor="callMeNowPhone">This meta title will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                      <div className="d-flex custom_fcontrol">
                        <div className="left_farea">
                          <input
                            type="text"
                            id="metatitle"
                            className="form-control"
                            name="metaTitle"
                            placeholder="Write your meta title"
                            //  onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))}
                            onChange={handleChangeForProfileSetup}
                          />
                          {error.metaTitle && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.metaTitle}</p>}
                        </div>
                        <div className="right_farea"> Should be no more than 60-70 keywords</div>
                      </div>
                    </div>
                  </div>
                  <div className=" contact_detail title_bar_setting second_input">
                    <h3 className="letter_darkbold">Meta Description</h3>

                    <div className="form-group">
                      <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                      <div className="d-flex custom_fcontrol">
                        <div className="left_farea">
                          <input
                            type="text"
                            id="metatitle"
                            className="form-control"
                            name="metaDescription"
                            placeholder="Write your meta title"
                            // onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))}
                            onChange={handleChangeForProfileSetup}
                          />
                          {error.metaDescription && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.metaDescription}</p>}
                        </div>
                        <div className="right_farea">Should be no more than 130-140 words</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="selectors">
                  <h5>Service Area</h5>
                  <Select
                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                    isMulti
                    name="cities"
                    options={cityOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={customStyles}
                    value={selectedCities.map((city) => ({ value: city, label: city }))}
                    onChange={handleCityChange}
                  />
                  {error.serviceArea && <p style={{ color: "red", fontWeight: "400", fontSize: "13px" }}>{error.serviceArea}</p>}
                </div>
                <div className="selector_btn">
                  <button
                    disabled={isDisable}
                    onClick={() => {
                      handleSubmit();
                      // setShowModal(true);
                    }}
                    className="btn update_btn"
                  >
                    Explore Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RealtorPaymentModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}

export default RealtorProfile;
