import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../Element/Api";
import "./BrokerSetPayments.css";
import ReactSlider from "react-slider";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import Dollar from "../../assets/dollar.svg";
import Update from "../../assets/Update 1.png";
import BaseLayout from "../../layouts/BaseLayout";
import Percentage from "../../assets/percentage.svg";
import ConfirmationModal from "../ModalMessage/ConfirmationModal";

function BrokerSetPayments() {
  const defaultMargin = 10;
  const defaultLoanTerm = 5;
  const defaultSetUpCost = 25;
  const [config] = useAuth();
  const [loading, setLoading] = useState(true);
  const [buttonKey, setButtonKey] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [margin, setMargin] = useState(defaultMargin);
  const agentType = useSelector((state) => state.agentType);
  const [loanTerm, setLoanTerm] = useState(defaultLoanTerm);
  const [setUpCost, setSetUpCost] = useState(defaultSetUpCost);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const handleNumericChange = (value, setterFunction, maxValue) => {
    let numericValue = parseFloat(value) || 0;
    if (numericValue > maxValue) {
      numericValue = maxValue;
    }
    setterFunction(numericValue);
  };

  const handleSaveClick = (e, key) => {
    e.preventDefault();
    setButtonKey(key);
    setShowSaveConfirmation(true);
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(Url.BASEURL + `/broker/get-payment`, config)
        .then((response) => {
          setMargin(response.data.margin || defaultMargin);
          setLoanTerm(response.data.loanInterest || defaultLoanTerm);
          setSetUpCost(response.data.setUpFee || defaultSetUpCost);
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        })
        .finally(() => setLoading(false));
    };

    fetchData();
  }, []);

  const handleSave = (mate) => {
    let params = {};
    setIsDisable(true);
    switch (buttonKey) {
      case 0:
        params = { margin: margin === defaultMargin ? defaultMargin : parseFloat(margin), buttonKey: buttonKey };
        break;
      case 1:
        params = { setUpFee: setUpCost === defaultSetUpCost ? defaultSetUpCost : parseFloat(setUpCost), buttonKey: buttonKey };
        break;
      case 2:
        params = { loanInterest: loanTerm === defaultLoanTerm ? defaultLoanTerm : parseFloat(loanTerm), buttonKey: buttonKey };
        break;
      default:
        params = {};
    }
    axios
      .post(Url.BASEURL + `/broker/set-payment`, params, config)
      // setTeammates(teamMates => teamMates.filter(t => t._id !== mate._id));
      .then((response) => {
        setShowSaveConfirmation(false);
        // setMargin(response.data.margin);
        // setLoanTerm(response.data.loanInterest);
        // setSetUpCost(response.data.setUpFee);
        if (params.margin === defaultMargin) {
          setMargin(defaultMargin);
        }
        if (params.setUpFee === defaultSetUpCost) {
          setSetUpCost(defaultSetUpCost);
        }
        if (params.loanInterest === defaultLoanTerm) {
          setLoanTerm(defaultLoanTerm);
        }
        setIsDisable(false);
      })
      .catch((error) => {
        setIsDisable(false);
        console.error("Error deleting the realtor:", error);
      });
  };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages listing_pager_only table_view_only">
        <h2>Set Payments</h2>

        {loading ? (
          <div className="text-center py-5">
            <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <div className="margins_blocks">
            {agentType == 1 && (
              <>
                <div className="single_margin_block">
                  <div className="margin_headings">
                    Margin <span>0% - 100%</span>
                  </div>
                  <div className="payment_slider">
                    <div className="left_slader">
                      <input type="text" className="form-control" id="interestRate" value={margin} onChange={(e) => handleNumericChange(e.target.value, setMargin, 100)} />
                      <div className="icon_savees">
                        <img src={Percentage} alt="" />
                      </div>
                      <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={100} step={1} value={margin} onChange={(value) => setMargin(value)} />
                    </div>
                    <div className="right_slade">
                      <div className="saver_btn" onClick={(e) => handleSaveClick(e, 0)}>
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="paymnent_texting">
                    You have the capability to set percentage amount, inclusive of the base
                    <span className="pricing_pay">$25</span> established by Wovnn, along with an additional margin.
                  </div>
                </div>

                <div className="bdr_center"></div>

                <div className="single_margin_block">
                  <div className="margin_headings">
                    Set Up Fees <span>$0 - $5000</span>
                  </div>

                  <div className="payment_slider">
                    <div className="left_slader">
                      <input type="text" className="form-control" id="propertyCost" value={setUpCost} onChange={(e) => handleNumericChange(e.target.value, setSetUpCost, 5000)} />
                      <div className="icon_savees">
                        <img src={Dollar} alt="" />
                      </div>
                      <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={5000} step={10} value={setUpCost} onChange={(value) => setSetUpCost(value)} />
                    </div>
                    <div className="right_slade">
                      <div className="saver_btn" onClick={(e) => handleSaveClick(e, 1)}>
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="paymnent_texting">You have to set an amount for realtors adding their profiles under your brokerage on the website.</div>
                </div>

                <div className="bdr_center"></div>
              </>
            )}

            <div className="single_margin_block">
              <div className="margin_headings">
                Set Loan Interest <span>0% - 25%</span>
              </div>
              <div className="payment_slider">
                <div className="left_slader">
                  <input type="text" className="form-control" id="loanTerm" value={loanTerm} onChange={(e) => handleNumericChange(e.target.value, setLoanTerm, 25)} />
                  <div className="icon_savees">
                    <img src={Percentage} alt="" />
                  </div>
                  <ReactSlider className="horizontal-slider" thumbClassName="thumb" trackClassName="track" min={0} max={25} step={1} value={loanTerm} onChange={(value) => setLoanTerm(value)} />
                </div>
                <div className="right_slade">
                  <div className="saver_btn" onClick={(e) => handleSaveClick(e, 2)}>
                    Save
                  </div>
                </div>
              </div>

              <div className="paymnent_texting">Setting a clear loan percentage is crucial. It directly affects buyers making property purchases on our platform.</div>
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        disable={isDisable}
        type={"toggleVisibility"}
        confirmButtonText={"Update"}
        img={Update}
        onConfirm={handleSave}
        show={showSaveConfirmation}
        onClose={() => {
          setShowSaveConfirmation(false);
        }}
        confirmText={"Do you really want to update this process? This process can change anytime."}
      />
    </BaseLayout>
  );
}

export default BrokerSetPayments;
