import React from "react";
import Cross from "../../assets/close.svg";
import Shield from "../../assets/shield.svg";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ show, type, action, confirmText, confirmButtonText, onConfirm, onClose, img, disable }) => {
  return (
    <Modal className="delete-modal" show={show} onHide={onClose} centered>
      <Modal.Body>
        <div className="delete-modal-content">
          <div className="delete-modal-header">
            <img src={img ? img : Shield} alt="" />
            <h5>Are you sure?</h5>
            <img src={Cross} alt="" className="modal_deklete" onClick={onClose} />
          </div>
          <p>{confirmText}</p>
          <div className="delete-modal-actions">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            {type === "toggleVisibility" && (
              <Button disabled={disable ? disable : false} className={`primary ${action === "Inactive" ? "active-action" : ""}`} variant={action === "Inactive" ? "danger" : "primary"} onClick={onConfirm}>
                {confirmButtonText}
              </Button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
