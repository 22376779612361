import "./App.css";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Leads from "./components/Leads/Leads";
import { ProtectedRoute, ProtectedRoutewithoutSubscribed } from "./Protectedroute";
import Url from "../src/components/Element/Api";
import EditBlog from "./components/Blog/EditBlog";
import Profile from "./components/Realtor/Profile";
import AddBlog from "./components/Blog/SingleBlog";
import Support from "./components/Support/Support";
import Mls from "./components/PackagesProcess/Mls";
import BlogPages from "./components/Blog/BlogPages";
import AboutPage from "./components/About/AboutPage";
import Realtor from "./components/Realtor/TeamMates";
import ManageTags from "./components/Blog/ManageTags";
import BlogListing from "./components/Blog/Bloglisting";
import MlsListing from "./components/Listing/MlsListing";
import PaymentSet from "./components/Realtor/PaymentSet";
import Step1 from "./components/PackageMlsSelector/Step1";
import Step2 from "./components/PackageMlsSelector/Step2";
import Step3 from "./components/PackageMlsSelector/Step3";
import RegisterRealtor from "./components/Realtor/Register";
import Packages from "./components/PackagesProcess/Packages";
import PageNotFound from "./components/Element/PageNotFound";
import RealtorProfile from "./components/Realtor/RealtorProfile";
import SetPayments from "./components/Payments/BrokerSetPayments";
import Transactions from "./components/Transactions/Transactions";
import BrokerProfileEdit from "./components/Profile/BrokerProfile";
import BillingInfo from "./components/PackagesProcess/BillingInfo";
import Listings from "./components/DashboardArea/Listings/Listings";
import DashboardArea from "./components/DashboardArea/DashboardArea";
import RelatorSubDomain from "./components/Realtor/RealtorSubDomain";
import TeamMates from "./components/DashboardArea/TeamMates/TeamMates";
import ChoosePackage from "./components/PackagesProcess/ChoosePackage";
import PricingReview from "./components/PackagesProcess/PricingReview";
import ForgotPassword from "./components/LoginRegister/ForgotPassword";
import PaymentFailed from "./components/PackagesProcess/PaymentFailed";
import RegisterDomain from "./components/PackagesProcess/RegisterDomain";
import Search from "./components/DashboardArea/SettingPages/Search/Search";
import RealtorProfileSetup from "./components/Realtor/RealtorProfileSetup";
import RealtorPackage from "./components/PackageMlsSelector/RealtorPackage";
import EmailVerified from "./components/PackageMlsSelector/VerifiedSuccessful";
import PaymentSuccessfull from "./components/PackagesProcess/PaymentSuccessfull";
import AmpPages from "./components/DashboardArea/SettingPages/AmpPages/AmpPages";
import MetaTags from "./components/DashboardArea/SettingPages/MetaTags/MetaTags";
import Branding from "./components/DashboardArea/SettingPages/Branding/Branding";
import EmailVerification from "./components/PackageMlsSelector/EmailVerification";
import RealtorDashboardArea from "./components/DashboardArea/RealtorDashboardArea";
import ExtraCode from "./components/DashboardArea/SettingPages/ExtraCode/ExtraCode";
import BrokerProfileSetup from "./components/PackageMlsSelector/BrokerProfileSetup";
import ListingDisplay from "./components/DashboardArea/SettingPages/ListingDisplay/ListingDisplay";
import GoogleAnalytics from "./components/DashboardArea/SettingPages/GoogleAnalytics/GoogleAnalytics";
import { setBrandingData, setLogOut, setProfileImage, setRefreshToken, setRegToken, setTokenExpTime, setUserType } from "./Redux/Action/Authaction";

function App() {
  let today = new Date();
  const dispatch = useDispatch();
  const tokenExpTime = useSelector((state) => state.tokenExpTime);
  const refreshToken = useSelector((state) => state.refreshToken);
  let currentTime = moment.utc(today).local().format("YYYY-MMM-DD h:mm:ss A");
  var expTime = moment.utc(tokenExpTime).local().format("YYYY-MMM-DD h:mm:ss A");
  let remainingTime = moment(expTime).diff(moment(currentTime), "seconds");
  const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];
  let timer;

  // axios.interceptors.response.use(
  //   function (response) {
  //     return response;
  //   },
  //   function (error) {
  //     const errorMessage = error;
  //     if (errorMessage.response.status === 403) {
  //       handleRefreshToken();
  //     }
  //     return Promise.reject(error);
  //   }
  // );

  const handleLogout = () => {
    axios
      .delete(`${Url.BASEURL}/broker/logout`, {
        data: {
          refreshToken: refreshToken,
        },
      })
      .then(() => {
        dispatch(setLogOut());
        dispatch(setProfileImage(null));
        dispatch(setUserType(null));
        dispatch(setTokenExpTime(null));
        dispatch(setRefreshToken(null));
        dispatch(setBrandingData(null, null));
        localStorage.clear();
        window.location.href = "/login";
      });
  };

  const handleRefreshToken = () => {
    axios
      .post(`${Url.BASEURL}/broker/refresh-token`, { refreshToken: refreshToken })
      .then((res) => {
        dispatch(setRegToken(res?.data?.accessToken));
        dispatch(setTokenExpTime(res?.data?.expirationTime));
      })
      .catch(() => {
        handleLogout();
      });
  };

  useEffect(() => {
    if (refreshToken !== null) {
      const interval = setInterval(() => {
        handleRefreshToken();
      }, 115 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [refreshToken]);

  useEffect(() => {
    if (remainingTime < 0 && refreshToken !== null) {
      console.log("remainingTime called", remainingTime);
      handleRefreshToken();
    }
  }, [refreshToken, remainingTime]);

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      handleLogout();
    }, 120 * 60 * 1000); // 10000ms = 10secs. You can change the time.
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  return (
    <div className="app-container">
      <BrowserRouter>
        {/*  <Navigation /> */}

        <Routes>
          {/* <Route path="dashboard" index element={<DashboardArea />} />
          <Route path="realtor-dashboard" index element={<RealtorDashboardArea />} />
          <Route path="team-mates" index element={<TeamMates />} />
          <Route path="listings" index element={<Listings />} />
          <Route path="amp-pages" index element={<AmpPages />} /> 
          <Route path="google-analytics" index element={<GoogleAnalytics />} />
          <Route path="extra-code" index element={<ExtraCode />} />
          <Route path="meta-tags" index element={<MetaTags />} />
          <Route path="listing-display" index element={<ListingDisplay />} />
          <Route path="search" index element={<Search />} />
          <Route path="branding" index element={<Branding />} />
          <Route path="step_2" index element={<Step2 />} />
          <Route path="step_3" index element={<Step3 />} />
          <Route path="realtor-package" index element={<RealtorPackage />} /> 
          <Route path="invite" index element={<RegisterRealtor />} />
          <Route path="/id/:invite" index element={<RegisterRealtor />} />
          <Route path="/invite/:id/verify" index element={<RegisterRealtor />} />
          <Route path="/invite/:id/verify/:token" index element={<RegisterRealtor />} />  
          <Route path="/" index element={<DashboardArea />} />
        */}

          <Route path="/login" index element={<Step1 />} />
          <Route path="email-verify" index element={<EmailVerification />} />
          <Route path="verified" index element={<EmailVerified />} />
          <Route path="/id/:verified" index element={<EmailVerified />} />
          <Route path="/verified/:id/:token" index element={<EmailVerified />} />
          <Route path="forgot-password" index element={<ForgotPassword />} />
          {/* <Route path="/test" index element={<LoginRegister />} /> */}
          <Route path="invite" index element={<RegisterRealtor />} />
          <Route path="/id/:invite" index element={<RegisterRealtor />} />
          <Route path="/invite/:id/verify" index element={<RegisterRealtor />} />
          <Route path="/invite/:id/verify/:token" index element={<RegisterRealtor />} />
          <Route path="/payment-success" index element={<PaymentSuccessfull />} />
          <Route path="/payment-success/:stripe_id" index element={<PaymentSuccessfull />} />
          <Route path="/payment-fail" index element={<PaymentFailed />} />
          <Route path="*" element={<PageNotFound />} />

          {/* Protected Routes without subscribed */}
          <Route element={<ProtectedRoutewithoutSubscribed />}>
            <Route path="broker-profile" index element={<BrokerProfileSetup />} />
            <Route path="/payment" index element={<PaymentSet />} />           
            <Route path="/choose-package" index element={<ChoosePackage />} />
            <Route path="/packages-mls" index element={<Mls />} />
            <Route path="/register-domain" index element={<RegisterDomain />} />
            <Route path="/pricing-review" index element={<PricingReview />} />
            <Route path="/billing-info" index element={<BillingInfo />} />
            <Route path="/realtor-profile" index element={<RealtorProfile />} />
          </Route>

          {/* Protected Routes  */}
          <Route element={<ProtectedRoute />}>
            <Route path="/" index element={<DashboardArea />} />
            <Route path="/packages" index element={<Packages />} />
            <Route path="/support" index element={<Support />} />
            <Route path="team-mates" index element={<TeamMates />} />
            <Route path="listings" index element={<Listings />} />
            <Route path="/realtor" index element={<Realtor />} />
            <Route path="/leads" index element={<Leads />} />
            <Route path="realtor-dashboard" index element={<RealtorDashboardArea />} />
            <Route path="listing-display" index element={<ListingDisplay />} />
            <Route path="google-analytics" index element={<GoogleAnalytics />} />
            <Route path="amp-pages" index element={<AmpPages />} />
            <Route path="extra-code" index element={<ExtraCode />} />
            <Route path="meta-tags" index element={<MetaTags />} />
            <Route path="search" index element={<Search />} />
            <Route path="branding" index element={<Branding />} />
            <Route path="step_2" index element={<Step2 />} />
            <Route path="step_3" index element={<Step3 />} />
            <Route path="/blogs" index element={<BlogListing />} />
            <Route path="/allblogs" index element={<BlogPages />} />
            <Route path="/add-blog" index element={<AddBlog />} />
            <Route path="/edit" index element={<EditBlog />} />
            <Route path="/edit/:blog_id" index element={<EditBlog />} />


            <Route path="/realtor-subdomain" index element={<RelatorSubDomain />} />
            <Route path="/manage-tags" index element={<ManageTags />} />
            <Route path="/profile-realtor" index element={<RealtorProfileSetup />} />
            <Route path="/profile" index element={<Profile />} />
            <Route path="/profile/:id" index element={<Profile />} />
            <Route path="/listing-mls" index element={<MlsListing />} />
            <Route path="/profile-broker" index element={<BrokerProfileEdit />} />
            <Route path="/set-payments" index element={<SetPayments />} />
            <Route path="/transactions" index element={<Transactions />} />
            <Route path="/about" index element={<AboutPage />} />
            <Route path="/realtor-package" index element={<RealtorPackage />} />
            {/* <Route path="/contact" index element={<Contact />} /> */}
          </Route>
        </Routes>

        {/* <Elements stripe={stripePromise}>
          <HomePage />
        </Elements> */}
        {/*   <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
