import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Url from "../Element/Api";
import useApi from "../../hooks/useApi";
import "../PackagesProcess/Packages.css";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";
import Stripe from "../../assets/stripe.svg";
import PackageInfo from "./package-info-modal";
import Update from "../../assets/Update 1.png";
import BaseLayout from "../../layouts/BaseLayout";
import "react-circular-progressbar/dist/styles.css";
import MlsImg from "../../assets/Rectangle 2509.png";
import StripeImage from "../../assets/Ellipse 37.png";
import { CircularProgressbar } from "react-circular-progressbar";
import ConfirmationModal from "../ModalMessage/ConfirmationModal";
import UpdateAgentTypeModal from "./components/UpdateAgentTypeModal";

function Packages() {
  const navigate = useNavigate();
  const [action, setAction] = useState();
  const [config, configFormData] = useAuth();
  const [profileData, handleProfileApi] = useApi();
  const [packageInfoData, handlePackageApi, packageInfoErr, loading] = useApi();
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isStripeLogin, setIsStripeLogin] = useState(true);
  const [billingCycle, setBillingCycle] = useState("Monthly");
  const [showAgentTypeModal, setShowAgentTypeModal] = useState(false);

  useEffect(() => {
    handleProfileApi("broker/profile", configFormData);
    handlePackageApi(`broker/get-broker-current-package-info`, config);
  }, []);

  useEffect(() => {
    setBillingCycle(packageInfoData?.data?.billingCycle);
    setAction(packageInfoData?.data?.subscriptionStatus);
  }, [packageInfoData]);

  const handleBillingCycle = (value) => {
    if (value !== billingCycle) {
      setShowModal(true);
    }
    setBillingCycle(value);
  };

  const handleCancelSubscription = () => {
    const dataToSend = {
      action: action === 0 ? 1 : 0,
    };
    axios
      .post(`${Url.BASEURL}/broker/update-subscription`, dataToSend, config)
      .then((res) => {
        setAction(action === 0 ? 1 : 0);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleUpdatePlan = () => {
    const dataToSend = {
      billingCycle: billingCycle,
    };
    axios
      .post(`${Url.BASEURL}/broker/change-billing-cycle`, dataToSend, config)
      .then((res) => {
        // setAction(action === 0 ? 1 : 0);
        setShowModal(false);
        handlePackageApi(`broker/get-broker-current-package-info`, config);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setShowModal(false);
      });
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages set_payments">
        <h2 style={{ marginBottom: "35px" }}>Package</h2>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <div className="real_mls d-flex">
            <div className="left_real_list">
              <div className="left_real_list_realtor">
                <div className="mls_bts_row d-flex">
                  <div className="rel_heading">Realtor</div>
                  {profileData?.agentType === 1 && (
                    <div className="rel_links" role="button" onClick={() => navigate("/realtor")}>
                      View All
                    </div>
                  )}
                </div>

                {profileData?.agentType === 0 ? (
                  <div className=" gap-5" style={{ marginTop: "20px", textAlign: "center" }}>
                    <div className="numbers_mls">Zero realtors found! Enhance your profile by adding realtors or updating settings.</div>
                    {/* <div role="button" className="add-realtor-link" onClick={() => setShowAgentTypeModal(true)}>
                      Add Realtors
                    </div> */}
                  </div>
                ) : (
                  <div className="d-flex gapping">
                    <div style={{ width: 105, height: 105 }}>
                      <CircularProgressbar value={packageInfoData?.data?.percentageUsedSeats ?? 0} text={`${Math.round(packageInfoData?.data?.percentageUsedSeats ?? 0)}%`} />
                    </div>
                    <div className="main_numbers">
                      <span className="number_left">{packageInfoData?.data?.remainingSeats ?? 0}</span> / <span className="number_right">{packageInfoData?.data?.totalSeatsOfBroker}</span>
                      <div className="mls_text">
                        You have {packageInfoData?.data?.remainingSeats ?? 0} empty seats {/*<a href="/choose-package">Add Realtors here</a>*/}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="left_real_list_mls">
                <div className="mls_bts_row d-flex">
                  <div className="rel_heading">MLS</div>
                  <div
                    className="rel_links"
                    role="button"
                    // onClick={() => navigate("/packages-mls")}
                  >
                    Add more MLS
                  </div>
                </div>

                <div className="mls_looping">
                  {packageInfoData?.data?.selectedMLS?.map((mls, idx) => (
                    <div key={idx} className="single_mls_detail d-flex">
                      <div className="left_mls_image">
                        <img src={MlsImg} alt="mls" />
                      </div>
                      <div className="right_mls_data">
                        <h2>{mls.mlsName}</h2>
                        <p>{mls.description}</p>
                        <h3>$ {mls.price}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="right_real_mls">
              <div className="right_real_mls_payment">
                <div className="mls_bts_row d-flex">
                  <div className="rel_heading">Payment</div>
                  {/* <div className="rel_links" role="button" onClick={() => navigate("/choose-package")}>
                        View All
                      </div>*/}
                </div>

                <div className="mls_bts_row d-flex" style={{ marginTop: "20px", paddingRight: "30px" }}>
                  <div className="payment_body_text">
                    Last Billing : <span>{packageInfoData?.data?.subscriptionStartDate ? moment.utc(packageInfoData?.data?.subscriptionStartDate).format("DD MMM YYYY") : null}</span>
                  </div>
                  <div className="payment_body_text">
                    Next Billing : <span>{packageInfoData?.data?.subscriptionEndDate ? moment.utc(packageInfoData?.data?.subscriptionEndDate).format("DD MMM YYYY") : null}</span>
                  </div>
                </div>

                <div className="d-flex maker" style={{ marginTop: "40px" }}>
                  <div className="payment_body_text">
                    <span>Billing Cycle :</span>
                  </div>
                  <div className="d-flex cpflex ctbtt" style={{ marginLeft: "10px" }}>
                    <div className="d-flex">
                      <label className="custom-radio-container-payment">
                        Monthly
                        <input type="radio" name="billingCycle" className="custom-radio" onChange={() => handleBillingCycle("Monthly")} checked={billingCycle === "Monthly"} />
                        <span className="radio-checkmark-payment"></span>
                      </label>
                    </div>
                    <div className="d-flex cpflex tflex" style={{ marginLeft: "15px" }}>
                      <label className="custom-radio-container-payment">
                        Annual (save 10% on monthly fees)
                        <input type="radio" name="billingCycle" className="custom-radio" onChange={() => handleBillingCycle("Annual")} checked={billingCycle === "Annual"} />
                        <span className="radio-checkmark-payment"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="payment_body_text ctbtt" style={{ marginTop: "7px" }}>
                  You can choose to pay either monthly or annually. When paying annually, you save 10% compared to the regular monthly pricing!
                </div>
              </div>

              <div className="right_real_mls_payment margin_Top">
                <div className="rel_heading">Domain</div>
                <div className="payment_body_text" style={{ marginTop: "20px" }}>
                  Wovnn Unique ID :{" "}
                  <a className="domain-link" target="_blank" rel="noreferrer" href={`https://${packageInfoData?.data?.wovnnUniqueId}.oregonhomeseeker.com`}>
                    {packageInfoData?.data?.wovnnUniqueId}
                  </a>
                </div>
                <div className="payment_body_text" style={{ marginTop: "10px" }}>
                  Domain Connected with :{" "}
                  <a className="domain-link" target="_blank" rel="noreferrer" href={`https://${packageInfoData?.data?.wovnnUniqueId}.oregonhomeseeker.com`}>
                    {packageInfoData?.data?.subdomainName}
                  </a>{" "}
                  (Edit)
                </div>
              </div>

              <div style={{ display: "none" }}>
                {isStripeLogin ? (
                  <div className="right_real_mls_payment d-flex margin_Top">
                    <img src={StripeImage} alt="" />
                    <div>
                      <div className="payment_body_text" style={{ marginTop: "20px" }}>
                        Stripe login with : <a href="#">Jerome Bell</a>
                      </div>
                      <button className="payment-logout" style={{ marginTop: "30px" }}>
                        Log Out
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="right_real_mls_payment d-flex margin_Top">
                    <img src={Stripe} alt="" />
                    <div>
                      <div className="payment_body_text" style={{ marginTop: "20px" }}>
                        Kindly note that a Stripe login is required for all payments.
                      </div>
                      <button className="payment-logout" style={{ marginTop: "30px" }}>
                        Login Now
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {action === 1 ? (
                <div className="right_real_mls_payment d-flex justify-content-between align-items-center margin_Top" style={{ backgroundColor: "#F7F8F9" }}>
                  <div className="subscription_text">Want to stop your Wovnn subscription?</div>
                  <button
                    className="payment-logout"
                    onClick={() => {
                      setShowAlert(true);
                    }}
                  >
                    Cancel Now
                  </button>
                </div>
              ) : (
                <div className="right_real_mls_payment d-flex justify-content-between align-items-center margin_Top" style={{ backgroundColor: "#F7F8F9" }}>
                  <div className="subscription_text">Considering continuing your Wovnn subscription?</div>
                  <button
                    className="payment-logout"
                    onClick={() => {
                      setShowAlert(true);
                    }}
                  >
                    Activate Now
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Update Plan Modal */}
      {/* <ConfirmationModal
        type={"toggleVisibility"}
        img={Update}
        onConfirm={handleUpdatePlan}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          handlePackageApi(`broker/get-broker-current-package-info`, config);
        }}
        confirmButtonText={"Update"}
        confirmText={"Do you really want to update this process? This process can change anytime."}
      /> */}
      <PackageInfo
        show={showModal}
        onClose={() => setShowModal(false)}
        billingCycle={billingCycle}
        handleUpdatePlan={handleUpdatePlan} // Pass the handleUpdatePlan function
        packageInfoData={packageInfoData}
        config={config}
        handlePackageApi={handlePackageApi}
      />

      {/* Update Subscription Modal */}
      <ConfirmationModal
        type={"toggleVisibility"}
        onConfirm={() => {
          handleCancelSubscription();
          setShowAlert(false);
        }}
        show={showAlert}
        onClose={() => setShowAlert(false)}
        confirmButtonText={"Save"}
        confirmText={"Do you really want to save this records? This process can change anytime."}
      />

      {/* Update Agent Type Modal */}
      <UpdateAgentTypeModal show={showAgentTypeModal} onClose={() => setShowAgentTypeModal(false)} />
    </BaseLayout>
  );
}

export default Packages;
