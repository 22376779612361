import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Url from "../Element/Api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import useAuth from "../../hooks/useAuth";
import Close from "../../assets/closer.png";
import Shield from "../../assets/shield.svg";
import { useNavigate } from "react-router-dom";
import ModalMessage from "../ModalMessage/Modal";
import BaseLayout from "../../layouts/BaseLayout";
import "react-datepicker/dist/react-datepicker.css";
import defaultUploaderIcon from "../../assets/uploader.svg";
import { editorStyle, formats, handleDropdownClose, modules } from "../../utils/utils";

const SingleBlog = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [image, setImage] = useState("");
  const [config, configFormData] = useAuth();
  const [manageTags, setManageTags] = useState([]);
  const [finalImage, setFileImage] = useState(null);
  const [includeImageInFormData, setIncludeImageInFormData] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [allowComment, setAllowComment] = useState(false);
  const lastname = useSelector((state) => state.lastName);
  const firstname = useSelector((state) => state.firstName);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [error, setError] = useState({
    title: "",
    Author: "",
    description: "",
    tags: "",
    meta_title: "",
    meta_description: "",
    image: "",
  });

  const [blogData, setBlogData] = useState({
    title: "",
    Author: firstname + " " + lastname,
    // description: "",
    // tags: "",
    meta_title: "",
    meta_description: "",
    image: "",
  });

  const selectedTagsString = selectedTags.join(", ");

  const handleDateChange = (date) => {
    const currentDate = new Date(); // Current date and time
    if (date > currentDate) {
      setIsScheduled(true);
    } else if (date.getDate() === currentDate.getDate() && date.getHours() >= currentDate.getHours() && date.getMinutes() >= currentDate.getMinutes()) {
      setIsScheduled(true);
    } else {
      setIsScheduled(false);
    }
    setStartDate(date);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileImage(event.target.files[0]);
    setError((prevError) => ({ ...prevError, image: "" })); // Clear image error message
    if (file && file.type.substr(0, 5) === "image") {
      setImage(URL.createObjectURL(file));
      setIncludeImageInFormData(true);
    } else {
      setImage(null);
    }
  };

  const handleFeaturedRemove = () => {
    if (image) {
      URL.revokeObjectURL(image);
      setImage(null);
      setIncludeImageInFormData(false);
    }
  };

  const handleCheckboxChange = (tag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  // const handleTitleChange = (e) => {
  //   setTitle(e.target.value);
  //   setError((prevError) => ({ ...prevError, title: "" }));
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Check if the input field is meta_title or meta_description
    if (name === "meta_title") {
      const trimmedValue = value.slice(0, 70);
      setBlogData({
        ...blogData,
        [name]: trimmedValue,
      });
    } else if (name === "meta_description") {
      const trimmedValue = value.slice(0, 140);
      setBlogData({
        ...blogData,
        [name]: trimmedValue,
      });
    } else {
      setBlogData({
        ...blogData,
        [name]: value,
      });
    }
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleTagsChange = () => {
    setError((prevError) => ({ ...prevError, tags: "" }));
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDescriptionChange = (html) => {
    setDescription(html);
    setError((prevError) => ({ ...prevError, description: "" }));
  };

  const handleSubmit = async (event) => {
    setError("");
    const formData = new FormData();
    formData.append("title", blogData.title);
    formData.append("Author", firstname + " " + lastname);
    formData.append("description", description);
    formData.append("tags", selectedTags);
    // formData.append("image", finalImage);
    if (includeImageInFormData && finalImage) {
      formData.append('image', finalImage);
    }
    formData.append("meta_title", blogData.meta_title);
    formData.append("meta_description", blogData.meta_description);
    formData.append("allow_comments", allowComment);
    formData.append("status", event);
    if (event === 0) {
      formData.append("scheduled_date", startDate);
    }
    axios
      .post(Url.BASEURL + "/blog/create", formData, configFormData)
      .then(() => {
        setShowModal(false);
        navigate("/blogs");
      })
      .catch((err) => {
        setShowModal(false);
        setShowScheduleModal(false);
        if (err.response && err.response.data && err.response.data.error) {
          const apiErrors = err.response.data.error;
          setError({
            title: apiErrors.title || "",
            Author: apiErrors.Author || "",
            description: apiErrors.description || "",
            tags: apiErrors.tags || "",
            meta_title: apiErrors.meta_title || "",
            meta_description: apiErrors.meta_description || "",
            image: apiErrors.image || "",
          });
        } else {
          setError({ general: "An error occurred while processing your request." });
        }
      });
  };

  useEffect(() => {
    axios
      .get(Url.BASEURL + `/manage-tag/all`, config)
      .then((value) => {
        setManageTags(value.data.tags);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages single_posts">
        <h2>Blog Posts</h2>
        <p>Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>

        <form encType="multipart/form-data">
          <div className="post-data title_bar_setting ">
            <div className="post_detail d-flex ">
              <div className="left_post_detail">
                <div className="single_entry">
                  <label>
                    Title
                    <input type="text" name="title" className="form-control" value={blogData.title} onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))} onChange={handleInputChange} placeholder="Enter your title here" />
                  </label>
                  <div className="edit_link d-flex">
                    <div className="left_link_edit_title">
                      <input type="text" className="edit_control" defaultValue="https://www.allprofessionalsre.com/banks-real-estate-all-professional-real-estates" readOnly={isReadOnly} onBlur={() => setIsReadOnly(true)} />
                      <div className="title error">{error.title && <p style={{ color: "red", fontSize: "12px" }}>{error.title}</p>}</div>
                      {/* <input type="text" label="text" defaultValue="https://www.allprofessionalsre.com/banks-real-estate-all-professional-real-estates" /> */}
                    </div>
                    <div
                      className="edit_icon"
                      onClick={() => {
                        setIsReadOnly(!isReadOnly);
                      }}
                    >
                      <span className="icon-Pencil1"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_post_detail">
                <div className="single_entry">
                  <label>
                    Author Name
                    <input type="text" name="Author" disabled className="form-control text-capitalize" value={blogData.Author} onChange={handleInputChange} placeholder="Enter Author Name" /> {error.Author && <p style={{ color: "red", fontSize: "17px" }}>{error.Author}</p>}
                  </label>
                </div>
              </div>
            </div>

            <div className="editor" style={editorStyle}>
              <ReactQuill value={description} onChange={handleDescriptionChange} modules={modules} formats={formats} />
              {error.description && <p style={{ color: "red", fontSize: "12px" }}>{error.description}</p>}
            </div>

            <div className="tefe d-flex">
              <div className="l_tefe">
                <div className="tags_selector">
                  <div ref={dropdownRef} className="single_entry select_single">
                    <label>
                      Tags
                      <input type="text" name="tags" className="form-control" id="tags" value={selectedTagsString} placeholder="Select Tags for this Post" readOnly onClick={handleTagsChange} />
                    </label>

                    {isDropdownOpen && (
                      <div className="tags_dropdown">
                        {manageTags?.length === 0 ? (
                          <div className="list_checks pt-2 ">No Tag</div>
                        ) : (
                          manageTags?.map((tag) => (
                            <div className="list_checks" key={tag}>
                              <label className="custom-checkbox-container">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  checked={selectedTags.includes(tag.tag.name)}
                                  onChange={() => {
                                    handleCheckboxChange(tag.tag.name);
                                  }}
                                />
                                <span className="checkmark"></span>
                                {tag.tag.name}
                              </label>
                            </div>
                          ))
                        )}
                      </div>
                    )}

                    <div className="d-flex pt-1 align-items-center justify-content-between">
                      <div>{error.tags && <p style={{ color: "red", fontSize: "12px" }}>{error.tags}</p>}</div>
                      <p role="button" className="add-tags-link" onClick={() => navigate("/manage-tags")}>
                        Add Tags
                      </p>
                    </div>
                  </div>
                </div>

                <div className="date_published">
                  <div className="single_entry date_icon">
                    <label>
                      {isScheduled ? "Schedule Date and Time" : "Publish Date and Time"}
                      <DatePicker selected={startDate} onChange={handleDateChange} showTimeSelect dateFormat="Pp" className="all_dates" />
                    </label>
                  </div>
                </div>

                <div className="two labels_checks d-flex">
                  <label className="custom-checkbox-container">
                    <input type="checkbox" name="allow_comments" className="custom-checkbox" checked={allowComment} onChange={(e) => setAllowComment(allowComment ? false : true)} />
                    <span className="checkmark"></span>
                    Allow Comments
                  </label>

                  {/* <label className="custom-checkbox-container mdl">
                    <input type="checkbox" name="publish_post" className="custom-checkbox" checked={publish} onChange={(e) => setPublish(publish ? false : true)} />
                    <span className="checkmark"></span>
                    Publish this post
                  </label> */}
                </div>
              </div>

              <div className="r_tefe">
                <div className="right_link_comp">
                  <h3>Featured Image</h3>
                  <div className="favicon_uploader">
                    {image ? (
                      <>
                        <div className="close_fav" onClick={handleFeaturedRemove}>
                          <img src={Close} alt="remove" />
                        </div>
                        <div>
                          <img src={image} alt="Uploaded featured Icon" className="Featured_image" />
                        </div>
                      </>
                    ) : (
                      <label htmlFor="featured" className="favicon_label">
                        <img src={defaultUploaderIcon} alt="Featured Uploader" />
                        <p>Upload Featured Image</p>
                        {error.image && <p style={{ color: "red", fontSize: "12px" }}>{error.image}</p>}
                      </label>
                    )}
                    <input
                      type="file"
                      // accept="image/*"
                      // onChange={handleFeaturedUpload}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      className="favicon_input"
                      id="featured"
                      accept=".png, .jpg, .jpeg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="center_border"></div>

            <div className="tags_set">
              <div className="title_bar_setting">
                <h3>Meta Title</h3>
                <div className="form-group">
                  <label htmlFor="callMeNowPhone">This title will be appended to the page name for each of your pages, unless you override it by providing a page specific meta title in its Advanced Page Settings.</label>
                  <div className="d-flex custom_fcontrol">
                    <div className="left_farea">
                      <input type="text" id="metatitle" name="meta_title" className="form-control" value={blogData.meta_title} placeholder="Enter your meta title here" onChange={handleInputChange} onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))} />
                      {error.meta_title && <p style={{ color: "red", fontSize: "12px" }}>{error.meta_title}</p>}
                    </div>
                    <div className="right_farea">Should be no more than 60-70 keywords</div>
                  </div>
                </div>
              </div>

              <div className="title_bar_setting second_input">
                <h3>Meta Description</h3>
                <div className="form-group">
                  <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>
                  <div className="d-flex custom_fcontrol">
                    <div className="left_farea">
                      <input type="text" id="metaDescription" className="form-control" name="meta_description" value={blogData.meta_description} placeholder="Enter your meta description here" onChange={handleInputChange} onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))} />
                      {error.meta_description && <p style={{ color: "red", fontSize: "12px" }}>{error.meta_description}</p>}
                    </div>
                    <div className="right_farea">Should be no more than 130-140 keywords</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="two_btns">
              {isScheduled ? (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setShowScheduleModal(true);
                  }}
                  className="btn update_btn"
                >
                  Schedule Blog
                </button>
              ) : (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    setShowModal(true);
                  }}
                  className="btn update_btn"
                >
                  Add Blog
                </button>
              )}

              <button
                onClick={(event) => {
                  event.preventDefault();
                  setShowDraftModal(true);
                  // setMyState(0);
                  // handleSubmit(0);
                  // handleDraft(0);
                }}
                className="btn cancel_btn"
              >
                Draft
              </button>

              {/* schedule blog modal */}
              <ModalMessage show={showScheduleModal} handleClose={() => setShowScheduleModal(false)} handleSave={() => handleSubmit(2)} message="Do you really want to schedule this blog?" img={Shield} leftButton="Cancel" rightButton="Schedule" />

              {/* add blog modal */}
              <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={() => handleSubmit(1)} message="Do you really want to add this blog?" img={Shield} leftButton="Cancel" rightButton="Save" />

              {/* draft blog modal */}
              <ModalMessage show={showDraftModal} handleClose={() => setShowDraftModal(false)} handleSave={() => handleSubmit(3)} message="Do you really want to draft this blog?" img={Shield} leftButton="Cancel" rightButton="Draft" />
            </div>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
};

export default SingleBlog;
