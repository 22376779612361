import React, { useState, useEffect, useRef } from "react";
import "./Leads.css";
import axios from "axios";
import Url from "../Element/Api";
import LeadData from "./LeadData";
import useAuth from "../../hooks/useAuth";
import Sliders from "../../assets/s3.svg";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import BaseLayout from "../../layouts/BaseLayout";
import { NextLabel, PreviousLabel, handleDropdownClose } from "../../utils/utils";

function Leads() {
  const dropdownRef = useRef(null);
  const [config] = useAuth();
  const userType = useSelector((state) => state.userType);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [realtors, setRealtors] = useState([]);
  const AgentdropdownOptions = ["All", "Monthly(Low To High)", "Monthly(High To Low)", "Investment(Low To High)", "Investment(High To Low)"];
  const indexOfLastItem = currentPage * totalItems;
  const indexOfFirstItem = indexOfLastItem - totalItems;

  const PostsDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      let apiUrl = userType === 1 ? `${Url.BASEURL}/broker/all-leads` : `${Url.BASEURL}/broker/leads`;

      const queryParams = new URLSearchParams();

      if (currentPage) {
        queryParams.append("page", currentPage);
      }
      if (selectedFilter) {
        queryParams.append("sortBy", selectedFilter);
      }
      if (searchTerm) {
        queryParams.append("searchTerm", searchTerm.trim());
      }

      // Append the query parameters to the API URL if any parameters exist
      if (queryParams.toString()) {
        apiUrl += `?${queryParams.toString()}`;
      }
      if (userType == 1) {
        const response = await axios.get(apiUrl, config);
        if (response.data) {
          setLeads(response?.data?.leads);
          setTotalPages(response?.data?.totalPages);
          setTotalItems(response?.data?.totalItems);
        }
      } else {
        const response = await axios.get(apiUrl, config);
        if (response.data) {
          setLeads(response?.data?.accessibleLeads);
          setTotalPages(response?.data?.totalPages);
          setTotalItems(response?.data?.totalItems);
        }
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm, selectedFilter]);

  // useEffect(() => {
  //   if (searchTerm.trim() !== "") {
  //     axios
  //       .get(Url.BASEURL + `/broker/search-leads?term=${searchTerm}&page=${currentPage}`, config)
  //       .then((value) => {
  //         setLeads(value.data.leads);
  //         setTotalPage(value.data.totalPages);
  //         setTotalItem(value.data.totalItems);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [currentPage, searchTerm, selectedFilter]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    // Map the selected option to the corresponding filter value
    let filterValue = null;
    switch (option) {
      case "Monthly(Low To High)":
        filterValue = "monthlyBudgetLowToHigh";
        break;
      case "Monthly(High To Low)":
        filterValue = "monthlyBudgetHighToLow";
        break;
      case "Investment(Low To High)":
        filterValue = "priceLowToHigh";
        break;
      case "Investment(High To Low)":
        filterValue = "priceHighToLow";
        break;
      default:
        filterValue = null;
    }
    setSelectedFilter(filterValue);
  };

  useEffect(() => {
    const fetchRealtors = async () => {
      try {
        // setLoading(true);
        let allRealtors = [];
        let currentPage = 1;
        let totalPages = 1;

        do {
          const response = await axios.get(`${Url.BASEURL}/broker/all-realtors?page=${currentPage}`, config);
          allRealtors = [...allRealtors, ...response.data.realtors.filter((realtor) => realtor.status === 1)];
          totalPages = response.data.totalPages;
          currentPage++;
        } while (currentPage <= totalPages);

        setRealtors(allRealtors);
      } catch (error) {
        console.log("Error fetching realtors:", error);
      }
      // finally {
      //   setLoading(false);
      // }
    };

    fetchRealtors();
  }, [isDropdown]);


  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  // const toggleDropdownforAssign = async (leadId) => {
  //   // Call fetchRealtors to fetch data for the specific lead's dropdown
  //   await fetchRealtors();
  //   // Toggle the dropdown for the clicked lead
  //   setOpenDropdownId(openDropdownId === leadId ? null : leadId);
  //   // Automatically assign leads to realtors when the dropdown is closed
  //   if (openDropdownId === leadId) {
  //     // assignLeadsToRealtors(leadId);
  //   }
  // };

  const assignLeadsToRealtors = async (leadId, realtorId) => {
    try {
      const payload = {
        leadId: leadId,
        realtorIds: [realtorId], // Pass the selected realtor ID
      };
      const response = await axios.post(`${Url.BASEURL}/broker/assign-leads-to-realtors`, payload, config);
      const updatedLeads = leads.map((lead) => {
        if (lead._id === leadId) {
          return { ...lead, realtorIds: [realtorId] };
        }
        return lead;
      });
      setLeads(updatedLeads);
    } catch (error) {
      console.log("Error assigning leads:", error);
    }
  };

  // const toggleExpansion = (leadId) => {
  //   setExpandedLeads((prevExpandedLeads) => ({
  //     ...prevExpandedLeads,
  //     [leadId]: !prevExpandedLeads[leadId],
  //   }));
  // };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages table_view_only">
        <h2>Leads</h2>
        <div className=" farea_leads posting_area">
          <div className="row mb-2 curlter">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett jeter">
              <div className="lisingenumbers">{`${indexOfFirstItem + 1}-${indexOfLastItem} of ${totalItems} Results`}</div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Location"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                      {selectedOption}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p key={index} onClick={() => handleOptionSelect(option)}>
                            {option}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive leader_set">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "25%" }}>
                        Name with Message
                      </th>
                      <th scope="col" style={{ width: "20%" }}>
                        Budget
                      </th>
                      <th scope="col" style={{ width: "15%" }}>
                        Location (Zip Code)
                      </th>
                      <th scope="col" style={{ width: "10%" }}>
                        Property Type
                      </th>
                      <th scope="col" style={{ width: "10%" }}>
                        Contact
                      </th>
                      {userType == 1 ? (
                        <th className="usera" scope="col" style={{ width: "25%" }}>
                          Assign
                        </th>
                      ) : null}
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <div className="bholss">
                        <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                      </div>
                    ) : (
                      <>
                        {leads?.length > 0 ? (
                          leads.map((lead, index) => {
                            const isLastLead = index >= leads?.length - 2;
                            return <LeadData key={index} lead={lead} realtors={realtors} totalItems={leads.length} assignLeadsToRealtors={assignLeadsToRealtors} isLastLead={isLastLead} onSetIsDropdown={(value) => setIsDropdown(value)} />;
                          })
                        ) : (
                          <tr className="text-center">
                            <td
                              colSpan="9"
                              style={{
                                fontSize: 16,
                                marginTop: "10px",
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              No Leads Found
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>

              {totalPages > 1 && (
                <div className="all_pagination">
                  <ReactPaginate
                    previousLabel={<PreviousLabel />}
                    nextLabel={<NextLabel />}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      setCurrentPage(e.selected + 1);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
export default Leads;
