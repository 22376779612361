import React, { useState, useEffect } from "react";
import "../SettingPages.css";
import axios from "axios";
import Url from "../../../Element/Api";
import { useNavigate } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import Shield from "../../../../assets/shield.svg";
import ModalMessage from "../../../ModalMessage/Modal";
import BaseLayout from "../../../../layouts/BaseLayout";

function ExtraCode() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    headerCode: "",
    footerCode: "",
  });
  const [errors, setErrors] = useState({
    headerCode: "",
    footerCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/broker/extra-code`, config);
        if (response.data) {
          setFormData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setShowModal(true);
  };

  const handleSave = async () => {
    setErrors("");
    try {
      const { _id, brokerId, __v, ...cleanedFormData } = formData;

      await axios.post(`${Url.BASEURL}/broker/extra-code`, cleanedFormData, config);
      navigate("/");

      // You can handle success scenarios here (e.g., show a success message)
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        setErrors({
          headerCode: apiErrors.headerCode || "",
          footerCode: apiErrors.footerCode || "",
        });
      } else {
        console.error("Error updating data:", error);
      }
      setShowModal(false);
    }
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages ga">
        <h2>Extra Code</h2>
        <p>Use at your own risk. Malformed code you put here can completely break your website.</p>

        <div className="title_bar_setting">
          <h3>Header Code</h3>
          <div className="form-group">
            <textarea id="headerCodeTextArea" className="form-control area_control" name="headerCode" value={formData.headerCode} onChange={handleChange} placeholder="Enter your header code here" />
            {errors.headerCode && <p style={{ color: "red", fontSize: "12px" }}>{errors.headerCode}</p>}
          </div>
        </div>
        <div className="center_border"></div>

        <div className="title_bar_setting second_input">
          <h3>Footer Code</h3>
          <div className="form-group">
            <textarea id="footerCodeTextArea" className="form-control area_control" name="footerCode" value={formData.footerCode} onChange={handleChange} placeholder="Enter your footer code here" />
            {errors.footerCode && <p style={{ color: "red", fontSize: "12px" }}>{errors.footerCode}</p>}
          </div>
        </div>
        <div className="two_btns">
          <button className="btn update_btn" onClick={handleUpdate}>
            Save
          </button>
          <button
            className="btn cancel_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            Cancel
          </button>
        </div>
        <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={handleSave} message="Do you really want to save your desired Extra Code?" img={Shield} leftButton="Cancel" rightButton="Save" />
      </div>
    </BaseLayout>
  );
}

export default ExtraCode;
