import axios from "axios";
import Api from "../../../Element/Api";
import useAuth from "../../../../hooks/useAuth";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTwitter, faDribbble, faGoogle } from "@fortawesome/free-brands-svg-icons";

const DashboardHelpSection = () => {
  const [config] = useAuth();
  const [helpCenter, setHelpCenter] = useState(null);

  useEffect(() => {
    axios
      .get(Api.BASEURL + `/broker/get-help-centre`, config)
      .then((response) => {
        setHelpCenter(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="row hatters">
      <div className="col-lg-6 col-md-12 col-sm-12 passder">
        <div className="mutual_blocks borrom_blocks">
          <h3>Help center</h3>
          <p>Get answers to most of your questions here in the help centre.</p>
          <p className="top_set">Our friendly customers service team is standing by to help!</p>
          <div className="icon_text_area">
            {helpCenter && helpCenter[0] && helpCenter[0].helpAndSupport && (
              <>
                <div className="iconing">
                  <span class="icon-call icon_mut"></span>
                  <span className="text_set">+{helpCenter[0].helpAndSupport.supportPhone}</span>
                </div>
                <div className="iconing">
                  <span class="icon-fax icon_mut"></span>
                  <span className="text_set">+{helpCenter[0].helpAndSupport.supportFax}</span>
                </div>
                <div className="iconing">
                  <span class="icon-message icon_mut"></span>
                  <span className="text_set">{helpCenter[0].helpAndSupport.supportEmail}</span>
                </div>
                <div className="iconing">
                  <span class="icon-calender icon_mut"></span>
                  <span className="text_set">Schedule an Appointment</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div className="mutual_blocks ">
          <div className="social_area">
            <p>Sharing your Real Estate website on a regular basis will boost your visit as well as catch the eye of anyone searching for a Real Estate agent at the time.</p>
            <div className="share_links d-flex">
              <div className="single_social">
                <a href="/">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </div>
              <div className="single_social">
                <a
                  href="/"
                  // href="https://twitter.com/YourProfile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
            </div>
            <div className="followme">
              <h4>Follow Us</h4>
              <div className="share_links d-flex">
                <div className="single_social">
                  <a
                    href="/"
                    // href="https://www.facebook.com/YourPage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </div>
                <div className="single_social">
                  <a
                    href="/"
                    // href="https://twitter.com/YourProfile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </div>
                <div className="single_social">
                  <a
                    href="/"
                    // href="https://dribbble.com/YourProfile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faDribbble} />
                  </a>
                </div>
                <div className="single_social">
                  <a
                    href="/"
                    // href="https://plus.google.com/YourProfile"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faGoogle} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHelpSection;
