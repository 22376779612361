import React, { useState } from "react";
import "./Step.css";
import Edit from "../../assets/edi.svg";
import Delete from "../../assets/del.svg";
import Maincard from "../../assets/card.svg";
import Arrow from "../../assets/arrow_right.svg";
import BaseLayout from "../../layouts/BaseLayout";
import { formatCardNumber } from "../../utils/utils";
import ConfirmationModal from "../ModalMessage/ConfirmationModal";

const initialCardData = { cardNumber: "4242 4242 4242 4242", expiryMonth: "12", expiryYear: "30", cardCVC: "210", cardHolderName: "Isabella", isDefauft: false, isEditable: false };
const initialErrData = { cardNumber: "", expiryMonth: "", expiryYear: "", cardCVC: "", cardHolderName: "" };

const RealtorPackage = () => {
  const [err, setErr] = useState(initialErrData);
  const [isAddNew, setIsAddNew] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [cardDetails, setCardDetails] = useState([initialCardData]);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const handleAddCard = (card, index) => {
    if (card.cardNumber === "") {
      setErr((prevErrors) => ({
        ...prevErrors,
        cardNumber: "Card Number is required",
      }));
    } else if (card.expiryMonth === "") {
      setErr((prevErrors) => ({
        ...prevErrors,
        expiryMonth: "Expiry Month is required",
      }));
    } else if (card.expiryYear === "") {
      setErr((prevErrors) => ({
        ...prevErrors,
        expiryYear: "Expiry Year is required",
      }));
    } else if (card.cardCVC === "") {
      setErr((prevErrors) => ({
        ...prevErrors,
        cardCVC: "CVV is required",
      }));
    } else if (card.cardHolderName === "") {
      setErr((prevErrors) => ({
        ...prevErrors,
        cardHolderName: "Card Holder Name is required",
      }));
    } else {
      setIsAddNew(false);
      const newCardDetails = [...cardDetails];
      newCardDetails[index].isEditing = false;
      // setCardDetails(newCardDetails);
    }
  };

  const handleCardDetailChange = (index, e) => {
    const { name, value } = e.target;
    const newCardDetails = [...cardDetails];
    newCardDetails[index][name] = value;
    setErr((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setCardDetails(newCardDetails);
  };

  const handleAddNewCard = (e) => {
    setIsAddNew(e.target.checked);
    if (e.target.checked) {
      setCardDetails([...cardDetails, { cardNumber: "", expiryMonth: "", expiryYear: "", cardCVC: "", cardHolderName: "", isDefauft: false, isEditable: true }]);
      setCurrentSlide(currentSlide + 1);
    }
  };

  const handleSetDefaultCard = (e, card) => {
    console.log("card", card);
  };

  const handleDeleteCard = (index) => {
    const newCardDetails = [...cardDetails];
    newCardDetails.splice(index, 1);
    setCardDetails(newCardDetails);
    if (currentSlide >= newCardDetails.length) {
      setCurrentSlide(newCardDetails.length - 1);
    }
    setIsAddNew(false);
    setErr(initialErrData);
  };

  const handleEditCard = (index) => {
    const newCardDetails = cardDetails.map((card, i) => ({
      ...card,
      isEditing: i === index,
    }));
    setCardDetails(newCardDetails);
    setCurrentSlide(index);
  };

  /* popup */
  const handleClose = () => {
    setShowSaveConfirmation(false);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();
    setShowSaveConfirmation(true);
    setCardToDelete(index);
  };

  const handleSlideChange = (val) => {
    setCurrentSlide(val);
    setIsAddNew(false);
    setErr(initialErrData);
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages traansaction table_view_only happing ">
        <h2>Package</h2>
        <div className="inner_mutual_block">
          <h3>Payments</h3>
          <div className="both_billing d-flex">
            <div className="first_billing">
              <span className="billing_heading">Last Billing: </span> <span className="billing_date">8 Mar 2024</span>
            </div>
            <div className="first_billing second_billing">
              <span className="billing_heading">Next Billing: </span> <span className="billing_date">14 Apr 2024</span>
            </div>
          </div>

          <div className="billing-cycle">
            <label className="billing-cycle-label">Billing Cycle:</label>
            <div className="radio-options">
              <label className="custom-radio monthly">
                <input type="radio" value="monthly" checked={billingCycle === "monthly"} onChange={() => setBillingCycle("monthly")} />
                <span className="radio-text">Monthly</span>
              </label>
              <label className="custom-radio annual">
                <input type="radio" value="annual" checked={billingCycle === "annual"} onChange={() => setBillingCycle("annual")} />
                <span className="radio-text">Annual (save 10% on monthly fees)</span>
              </label>
            </div>
          </div>
          <div className="bottom_tft">You can choose to pay either monthly or annually. When paying annually, you save 10% compared to the regular monthly pricing!</div>
        </div>

        <div className="add-new-card-container shelter">
          <label className="custom-checkbox-container">
            <input type="checkbox" checked={isAddNew} onChange={handleAddNewCard} />
            <span className="checkmark"></span>
            <span className="checkbox-label">Add New Card</span>
          </label>
        </div>

        <div id="cardCarousel" className="carousel slide peppn" key={cardDetails.length}>
          <div className="carousel-inner">
            {cardDetails.map((card, index) => {
              const isLastSlide = index === cardDetails.length - 1;

              return (
                <div key={index} className={`carousel-item ${index === currentSlide ? "active" : ""}`}>
                  <div key={index} className="payment-block">
                    <div className="payment_area d-flex">
                      <div className="right_payment_area">
                        <div className="card_detail" style={{ position: "relative" }}>
                          <img src={Maincard} alt="card" id="cardTypeLogo" style={{ width: "100%", height: "auto" }} />
                          <div className="card_number" id="displayCardNumber">
                            {card.cardNumber ? formatCardNumber(card.cardNumber) : "XXXX XXXX XXXX XXXX"}
                          </div>
                          <div className="card_expiry" id="displayExpiry">
                            <span>Valid: </span>
                            {card.expiryMonth && card.expiryYear ? `${card.expiryMonth}/${card.expiryYear.slice(-2)}` : "MM/YY"}
                          </div>
                          <div className="card_name" id="displayName">
                            {card.cardHolderName ? card.cardHolderName : "Cardholder Name"}
                          </div>
                        </div>
                      </div>

                      <div className={`left_payment_area ${isLastSlide ? "" : "faded"}`}>
                        <div className="two_sectors d-flex">
                          <div className="sector_one">
                            <label>Card Number</label>
                            <p>Enter the card number</p>
                          </div>
                          <div className="sector_two">
                            <input
                              type="text"
                              disabled={!card.isEditable}
                              className="form-control"
                              id="cardNumber"
                              name="cardNumber"
                              placeholder="XXXX XXXX XXXX XXXX"
                              value={formatCardNumber(card.cardNumber)}
                              onChange={(e) => {
                                handleCardDetailChange(index, e);
                              }}
                            />
                            {err?.cardNumber && <p style={{ color: "red", fontWeight: "400", marginLeft: "0px", fontSize: "13px" }}>{err?.cardNumber}</p>}
                          </div>
                        </div>

                        {/* Expiry Date Inputs */}
                        <div className="two_sectors d-flex special_sector">
                          <div className="sector_one">
                            <label>Expiry Date</label>
                            <p>Enter the expiration date of the card</p>
                          </div>
                          <div className="sector_two d-flex sector_set">
                            <div>
                              <input
                                type="text"
                                disabled={!card.isEditable}
                                className="form-control"
                                id="expiryMonth"
                                name="expiryMonth"
                                placeholder="MM"
                                pattern="\d*"
                                inputMode="numeric"
                                maxLength="2"
                                value={card.expiryMonth}
                                onChange={(e) => {
                                  handleCardDetailChange(index, e);
                                }}
                              />
                              {err?.expiryMonth && <p style={{ color: "red", fontWeight: "400", marginLeft: "0px", fontSize: "13px" }}>{err?.expiryMonth}</p>}
                            </div>
                            <span>/</span>
                            <div>
                              <input
                                type="text"
                                disabled={!card.isEditable}
                                className="form-control"
                                id="expiryYear"
                                name="expiryYear"
                                placeholder="YYYY"
                                pattern="\d*"
                                inputMode="numeric"
                                maxLength="4"
                                value={card.expiryYear}
                                onChange={(e) => {
                                  handleCardDetailChange(index, e);
                                }}
                              />
                              {err?.expiryYear && <p style={{ color: "red", fontWeight: "400", marginLeft: "0px", fontSize: "13px" }}>{err?.expiryYear}</p>}
                            </div>
                          </div>
                        </div>

                        {/* CVC Number Input */}
                        <div className="two_sectors d-flex">
                          <div className="sector_one">
                            <label>CVC Number</label>
                            <p>Enter the card verification code from the back of the card</p>
                          </div>
                          <div className="sector_two">
                            <input
                              type="text"
                              disabled={!card.isEditable}
                              className="form-control"
                              id="cardCVC"
                              name="cardCVC"
                              placeholder="Card CVC"
                              pattern="\d*"
                              inputMode="numeric"
                              maxLength="4"
                              value={card.cardCVC}
                              onChange={(e) => {
                                handleCardDetailChange(index, e);
                              }}
                            />
                            {err?.cardCVC && <p style={{ color: "red", fontWeight: "400", marginLeft: "0px", fontSize: "13px" }}>{err?.cardCVC}</p>}
                          </div>
                        </div>

                        {/* Cardholder Name Input */}
                        <div className="two_sectors d-flex">
                          <div className="sector_one">
                            <label>Cardholder Name</label>
                            <p>Enter the cardholder's name</p>
                          </div>
                          <div className="sector_two">
                            <input
                              type="text"
                              disabled={!card.isEditable}
                              className="form-control"
                              id="cardHolderName"
                              name="cardHolderName"
                              placeholder="Cardholder Name"
                              value={card.cardHolderName}
                              onChange={(e) => {
                                handleCardDetailChange(index, e);
                              }}
                            />
                            {err?.cardHolderName && <p style={{ color: "red", fontWeight: "400", marginLeft: "0px", fontSize: "13px" }}>{err?.cardHolderName}</p>}
                          </div>
                        </div>

                        {/* <div className="add-new-card-container shelter maker_card default_card">
                        <label className="custom-checkbox-container">
                          <input type="checkbox" />
                          <span className="checkmark"></span>
                          <span className="checkbox-label">Make Default Card</span>
                        </label>
                      </div> */}

                        {card.isEditable && (
                          <div className="add_cancel d-flex">
                            <div className="payment_btnew">
                              <button onClick={() => handleAddCard(card, index)} className="pps haloss">
                                Add Card Now
                              </button>
                            </div>
                            <div className="payment_btnew">
                              <button className="pps" onClick={() => handleDeleteCard(index)}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Carousel control buttons */}
                  {cardDetails?.length > 1 && (
                    <div className="ddl">
                      <div className="caro-left_right d-flex">
                        <div className="caro_lefts">
                          <div className="caro_control d-flex">
                            <div
                              className="left_arrowa"
                              data-bs-target="#cardCarousel"
                              data-bs-slide="prev"
                              onClick={() => {
                                handleSlideChange((currentSlide - 1 + cardDetails.length) % cardDetails.length);
                              }}
                            >
                              <img src={Arrow} alt="Arrow" />
                            </div>

                            <div className="middle_aro_data slide_counting">
                              {currentSlide + 1}/{cardDetails.length}
                            </div>

                            <div
                              className="right_arrowa"
                              data-bs-target="#cardCarousel"
                              data-bs-slide="next"
                              onClick={() => {
                                handleSlideChange((currentSlide + 1) % cardDetails.length);
                              }}
                            >
                              <img src={Arrow} alt="Arrow" />
                            </div>
                          </div>
                        </div>

                        <div className="caro_rights">
                          <div className="edit_del d-flex">
                            {index > 0 && (
                              <div className="editing_slide" onClick={() => handleEditCard(index)}>
                                <img src={Edit} alt="edit" />
                              </div>
                            )}
                            <div className="delete_slide" onClick={(e) => handleDelete(e, index)}>
                              <img src={Delete} alt="delete" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="add-new-card-container shelter maker_card">
                        <label className="custom-checkbox-container">
                          <input type="checkbox" checked={card?.isDefauft} onChange={(e) => handleSetDefaultCard(e, card)} />
                          <span className="checkmark"></span>
                          <span className="checkbox-label">Default Card</span>
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={showSaveConfirmation}
        onClose={handleClose}
        onConfirm={() => {
          if (cardToDelete !== null) {
            handleDeleteCard(cardToDelete);
          }
          handleClose();
        }}
        type="toggleVisibility"
        action={"Inactive"}
        confirmButtonText={"Delete"}
        confirmText={"Do you really want to Delete this records? This process cannot be undone."}
      />
    </BaseLayout>
  );
};

export default RealtorPackage;
