import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Url from "../../Element/Api";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Dots from "../../../assets/three_dots.svg";
import Comments from "../../../assets/comments.svg";
import DeleteAlert from "../../ModalMessage/DeleteAlert";
import { handleDropdownClose } from "../../../utils/utils";

const BlogListData = ({ post, onSetPosts }) => {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [deleteId, setDeleteId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const handleClose = () => {
      setDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const handleDelete = () => {
    axios
      .delete(Url.BASEURL + `/blog/${deleteId}`, config)
      .then(() => {
        onSetPosts((currentPosts) => currentPosts.filter((post) => post._id !== deleteId));
        setDropdownOpen(false);
      })
      .catch((error) => {
        console.log("Error deleting post:", error);
      });
  };

  return (
    <>
      <tr key={post._id}>
        <td>{post.title}</td>
        <td className="author-tags">{post.Author}</td>
        <td className="author-tags">{Array.isArray(post.tags) ? post.tags.join(", ") : post.tags}</td>
        <td className="font_set">
          <div className="d-block">Publishes at</div>
          <div className="d-block">
            {new Date(post.createdAt).toLocaleDateString("en-US", {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            }) +
              ", " +
              new Date(post.createdAt).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
          </div>
        </td>
        <td className="font_set commnts">
          <img src={Comments} alt="Comments" />({post && post.comments ? post.comments.length : 0})
        </td>
        <td>
          <div className="dropdown dropper">
            <div
              className="text-primary dropdown-toggle"
              role="button"
              id={`dropdownMenuLink-${post._id}`}
              onClick={() => {
                setDropdownOpen(dropdownOpen === post._id ? false : post._id);
              }}
            >
              <img src={Dots} alt="Edit" />
            </div>

            {dropdownOpen === post._id && (
              <div ref={dropdownRef} className={`dropdown-menu${dropdownOpen === post._id ? " show" : ""}`} aria-labelledby={`dropdownMenuLink-${post._id}`}>
                <a className="dropdown-item" target="_blank" rel="noreferrer" href={`http://wovnn-api-user.s3-website-us-west-1.amazonaws.com/single-blog/${post._id}`}>
                  <span class="icon-eye1"></span> View
                </a>
                <Link className="dropdown-item" to={"/edit/" + post._id}>
                  <span class="icon-Pencil1"></span> Edit
                </Link>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setDeleteId(post._id);
                  }}
                >
                  <span class="icon-Recycle-Bin"></span> Delete
                </button>
              </div>
            )}
          </div>
        </td>
      </tr>
      <DeleteAlert
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleConfirm={() => {
          handleDelete();
          setShowDeleteModal(false);
        }}
      />
    </>
  );
};

export default BlogListData;
