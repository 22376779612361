export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const setToken = (token) => ({
  type: "SET_TOKEN",
  payload: {
    token,
  },
});

export const delToken = (token) => ({
  type: "DEL_TOKEN",
});

export const setEmail = (email) => ({
  type: "SET_EMAIL",
  payload: {
    email,
  },
});

export const setFirstName = (firstName) => ({
  type: "FIRSTNAME",
  payload: {
    firstName,
  },
});

export const setLastName = (lastName) => ({
  type: "LASTNAME",
  payload: {
    lastName,
  },
});

export const setRegEmail = (regEmail) => ({
  type: "SET_REGEMAIL",
  payload: {
    regEmail,
  },
});

export const setRegToken = (regToken) => ({
  type: "SET_REGTOKEN",
  payload: {
    regToken,
  },
});

export const setSubscribed = (subscribed) => ({
  type: "SET_SUBSCRIBED",
  payload: {
    subscribed,
  },
});

export const setRefreshToken = (refreshToken) => ({
  type: "SET_REFRESHTOKEN",
  payload: {
    refreshToken,
  },
});

export const setTokenExpTime = (tokenExpTime) => ({
  type: "SET_TOKEN_EXP_TIME",
  payload: {
    tokenExpTime,
  },
});

export const setStatus = (status) => ({
  type: "SET_STATUS",
  payload: {
    status,
  },
});

export const setParentCategory = (category) => ({
  type: "SET_CATEGORY",
  payload: {
    category,
  },
});

export const delPassword = (password) => ({
  type: "DEL_PASSWORD",
});

export const setProfileImage = (profileImage) => ({
  type: "SET_PROFILE",
  payload: {
    profileImage,
  },
});

export const setCompanyname = (companyname) => ({
  type: "SET_COMPANYNAME",
  payload: {
    companyname,
  },
});

export const setFullName = (fullname) => ({
  type: "SET_FULLNAME",
  payload: {
    fullname,
  },
});

export const setUserType = (userType) => ({
  type: "USER_TYPE",
  payload: userType,
});

export const setAgentType = (agentType) => ({
  type: "AGENT_TYPE",
  payload: agentType,
});

export const UserType = (UserType) => ({
  type: "USERTYPE",
  payload: UserType,
});

export const setBrokerId = (brokerId) => ({
  type: "BROKER_ID",
  payload: brokerId,
});

export const setLogOut = () => ({
  type: "SET_LOGOUT",
});

export const setBrandingData = (favicon, websiteLogo) => ({
  type: "SET_BRANDING_DATA",
  payload: {
    favicon,
    websiteLogo,
  },
});

export const setBrandingDataFetched = (setBrandingDataFetched) => ({
  type: "SET_BRANDING_DATA_FETCHED",
  payload: setBrandingDataFetched,
});

export const setBrokerProfileDetails = (details) => ({
  type: "SET_BROKER_PROFILE_DATA",
  payload: details,
});

export const setRealtorProfileDetails = (details) => ({
  type: "SET_REALTOR_PROFILE_DATA",
  payload: details,
});
