import React from "react";

const EditTagModal = ({ updateHandle, userData, handleEditInputChange }) => {
  return (
    <div className="modal fade tagger" id="addEditTagModal" tabIndex="-1" aria-labelledby="addEditTagModal" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="title_bar_setting tag_add_new">
              <h3>Edit Tag Name</h3>

              <div className="form-group">
                <input type="text" style={{ width: "309px" }} className="form-control" name="name" value={userData.name} onChange={handleEditInputChange} />
              </div>
            </div>

            <div className="two_btns">
              <button data-bs-dismiss="modal" className="btn update_btn saver" onClick={updateHandle}>
                Save
              </button>
              <button className="btn cancel_btn" data-bs-dismiss="modal" aria-label="Close">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTagModal;
