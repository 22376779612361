import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../Blog/BlogPages.css";
import Url from "../Element/Api";
import Sliders from "../../assets/s3.svg";
import useAuth from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";
import BaseLayout from "../../layouts/BaseLayout";
import BlogListData from "./components/BlogListData";
import { NextLabel, PreviousLabel, handleDropdownClose } from "../../utils/utils";

function Bloglisting() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [posts, setPosts] = useState([]);
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dtaftBlogs, setDtaftBlogs] = useState(0);
  const [totalBlogs, setTotalBlogs] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [publishBlog, setPublishblog] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scheduleBlog, setScheduleBlog] = useState(0);
  const [totalPublish, setTotalPublish] = useState(0);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const PostsDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      let apiUrl = Url.BASEURL + "/blog/all?";

      if (currentPage) {
        apiUrl += "page=" + currentPage;
      }

      if (status) {
        apiUrl += "&status=" + status;
      }

      apiUrl += "&tag=" + selectedFilter;

      if (searchTerm) {
        apiUrl += "&term=" + searchTerm;
      }

      axios
        .get(apiUrl, config)
        .then((response) => {
          setPosts(response?.data?.data?.blogs);
          setTotalBlogs(response?.data?.data?.allBlogsCount);
          setTotalPages(response?.data?.data?.totalPages);
          setPublishblog(response?.data?.data?.publishedBlogsCount);
          setDtaftBlogs(response?.data?.data?.draftedBlogsCount);
          setScheduleBlog(response?.data?.data?.scheduleBlogCount);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData();
  }, [currentPage, status, selectedFilter, searchTerm]);

  useEffect(() => {
    const fetchFilterOptions = () => {
      axios
        .get(Url.BASEURL + `/manage-tag/all`, config)
        .then((res) => {
          setFilterOptions(res.data.tags);
        })
        .catch((error) => {
          console.error("Error fetching filter options:", error);
        });
    };

    fetchFilterOptions();
  }, []);

  const handleFilterSelect = async (tag) => {
    setSelectedFilter(tag);
    axios
      .get(Url.BASEURL + `/manage-tag/filter-blog?tag=${tag}`, config)
      .then((response) => {
        setPosts(response.data.blogs);
        setTotalBlogs(response.data.totalItems);
        setTotalPages(response.data.totalPages);
        setTotalPublish(response.data.publishedBlogsCount);
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      })
      .finally(() => setIsDropdownOpen(false));
  };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages blogger_area table_view_only stable_view">
        <h2>Blog Posts</h2>
        <div className="blog_add blog_stag">
          <Link to="/add-blog">
            <div className="Add_new_blog btn update_btn">Add New</div>
          </Link>
        </div>

        <div className="posting_area">
          <div className="row mb-3 curlter pulter">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett paltu jeter swagger">
              <div className={`${status === 0 ? "tab-color" : ""} tab-section`} onClick={() => setStatus(0)}>
                All ({totalBlogs})
              </div>
              <div className={`${status === 1 ? "tab-color" : ""} tab-section`} onClick={() => setStatus(1)}>
                <span>Published</span> ({publishBlog})
              </div>
              <div className={`${status === 3 ? "tab-color" : ""} tab-section`} onClick={() => setStatus(3)}>
                <span>Drafts</span> ({dtaftBlogs})
              </div>
              <div className={`${status === 2 ? "tab-color" : ""} tab-section`} onClick={() => setStatus(2)}>
                <span>Schedule</span> ({scheduleBlog})
              </div>
            </div>

            <div className="col-md-12 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search blog here..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>

                {/* <div className="btn_block">
                      <button className="btn btn-primary" onClick={() => setSearchTerm('')}>Search</button>
                    </div> */}
              </div>

              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className="drop_data">
                    <div className="selected_option" onClick={PostsDropdown} style={{ backgroundImage: `url(${Sliders})` }}>
                      {selectedFilter || "Filters"}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {filterOptions.length === 0 ? (
                          <p>No Options</p>
                        ) : (
                          filterOptions.map(({ tag }, index) => (
                            <p key={index} onClick={() => handleFilterSelect(tag.name)}>
                              {tag.name}
                            </p>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive">
                {loading ? (
                  <div className="text-center py-5">
                    <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                  </div>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "40%" }}>
                          Post Title
                        </th>
                        <th scope="col">Author</th>
                        <th scope="col">Tags</th>
                        <th scope="col">Date</th>
                        <th scope="col">Comments</th>
                        <th scope="col">Edit</th>
                      </tr>
                    </thead>

                    <tbody>
                      {posts?.length === 0 ? (
                        <tr className="text-center">
                          <td
                            colSpan="9"
                            style={{
                              fontSize: 16,
                              marginTop: "10px",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          >
                            No Blogs Found
                          </td>
                        </tr>
                      ) : (
                        posts.map((post, idx) => <BlogListData key={idx} post={post} onSetPosts={(value) => setPosts(value)} />)
                      )}
                    </tbody>
                  </table>
                )}
              </div>

              {totalPages > 1 && (
                <div className="all_pagination">
                  <ReactPaginate
                    previousLabel={<PreviousLabel />}
                    nextLabel={<NextLabel />}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      setCurrentPage(e.selected);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Bloglisting;
