import React from "react";

const LimitExceedModal = ({ isOpen, closeModal, notSentDueToLimit }) => {
  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      id="datauploaded"
      style={{
        display: isOpen ? "block" : "none",
      }}
      tabIndex="-1"
      aria-hidden={!isOpen}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body csv-upload-modal">
            <h3 style={{ fontSize: "18px", color: "#000" }}>Realtor's limit exceded.</h3>

            <div className="uploader_image_slider">
              <div className="calculation_zone">
                <div className="slider-container">
                  <p style={{ fontSize: "14px" }}>Cannot send invitations to last {notSentDueToLimit} realtors of csv sheet, invitation limit exceded for your account.</p>
                </div>
              </div>
            </div>
            <div className="modal-footer csv_footer">
              <button type="button" className="btn btn-cancel" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LimitExceedModal;
