import React, { useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Mls1 from "../../assets/mls1.png";
import Mls2 from "../../assets/mls2.png";
import Mls3 from "../../assets/mls3.png";
import Mls4 from "../../assets/mls4.png";
import Mls5 from "../../assets/mls5.png";
import Cross from "../../assets/close.png";
import Search from "../../assets/search.png";
import Building from "../../assets/building.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialMlsData = [
  { image: Mls1, alt: "mls" },
  { image: Mls2, alt: "mls" },
  { image: Mls3, alt: "mls" },
  { image: Mls4, alt: "mls" },
  { image: Mls5, alt: "mls" },
];

const Step3 = () => {
  const email = useSelector((state) => state.email);
  const [url, setUrl] = useState("");
  const [input, setInput] = useState("");
  const [mlsInput, setMlsInput] = useState("");
  const [showMain, setShowMain] = useState(true);
  const [locations, setLocations] = useState([]);
  const [mlsData, setMlsData] = useState(initialMlsData);
  const [selectedOption, setSelectedOption] = useState(2);
  const [selectedAgentRange, setSelectedAgentRange] = useState("1-10");

  const handleIndependentSubmit = async (priceIdForAgent) => {
    try {
      const res = await axios.post(Url.BASEURL + `/broker/create-subscription`, {
        email: email,
        priceId: priceIdForAgent,
        agentType: "",
      });

      if (res.status === 200) {
        const { session } = res.data;
        setUrl(session.url);
      } else {
        throw res.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBrokerageSubmit = async (selectedAgentRange) => {
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      let priceIdForBrokerage = "";
      if (selectedAgentRange === "1-10") {
        priceIdForBrokerage = "price_1O9QUVSECkIUp6TqWyj7UNIT";
      } else if (selectedAgentRange === "11-50") {
        priceIdForBrokerage = "price_1O9QUVSECkIUp6Tq8bsayIMg";
      } else if (selectedAgentRange === "51-100") {
        priceIdForBrokerage = "price_1O9QUVSECkIUp6TqGH9y5jpG";
      } else if (selectedAgentRange === "+100") {
        priceIdForBrokerage = "price_1O9QUVSECkIUp6Tqwm17xtNY";
      }

      const res = await axios.post(
        Url.BASEURL + `/broker/create-subscription`,
        {
          email: email,
          priceId: priceIdForBrokerage,
        },
        { headers }
      );

      if (res.status === 200) {
        const { session } = res.data;
        window.location.href = session.url;
        setUrl(session.url);
      } else {
        const { session } = res.data;
        window.location.href = session.url;
        throw res.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input && !locations.includes(input)) {
      setLocations([...locations, input]);
      setInput("");
    }
  };

  const handleDelete = (loc) => {
    setLocations(locations.filter((location) => location !== loc));
  };

  const handleMlsSubmit = (e) => {
    e.preventDefault();
    if (mlsInput && !mlsData.some((d) => d.image === mlsInput)) {
      setMlsData([...mlsData, { image: mlsInput, alt: "mls" }]);
      setMlsInput("");
    }
  };

  const handleMlsDelete = (data) => {
    setMlsData(mlsData.filter((d) => d.image !== data.image));
  };

  return (
    <div>
      <div className={`container container_bg second-options ${showMain ? "d-block" : ""}`}>
        <div className="row">
          <div className="col-lg-12">
            {/* Top Three Options */}
            <div className="top-options">
              <button
                style={{
                  backgroundColor: "#80C342",
                  color: "#fff",
                  borderBottomRightRadius: selectedOption === 2 ? "0px" : "inherit",
                  borderTopRightRadius: selectedOption === 2 ? "0px" : "inherit",
                }}
              >
                Signup
              </button>
              <button
                style={{
                  backgroundColor: selectedOption === 2 ? "#80C342" : "#D4EEDA",
                  color: selectedOption === 2 ? "#fff" : "#141928",
                  borderTopLeftRadius: selectedOption === 2 ? "0px" : "inherit",
                  borderBottomLeftRadius: selectedOption === 2 ? "0px" : "inherit",
                }}
              >
                Choose your Package
              </button>
              <button
                style={{
                  backgroundColor: "#D4EEDA",
                  color: selectedOption === 2 ? "#141928" : "#B4B9C8",
                }}
              >
                What is your MLS?
              </button>
            </div>

            <div className="mls">
              <div className="working_area">
                <form onSubmit={handleSubmit}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text image_set bdr1">
                        <img src={Location} alt="location" />
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Add your working area..." value={input} onChange={(e) => setInput(e.target.value)} />
                    <div className="input-group-append">
                      <span className="input-group-text image_set bdr2">
                        <img src={Search} alt="search" />
                      </span>
                    </div>
                  </div>
                </form>

                <div className="d-flex flex-wrap">
                  {locations.map((loc) => (
                    <div key={loc} className="m-2 d-flex align-items-center   location_seting">
                      {loc}
                      <button className="btn btn-sm ml-2 delete_location" onClick={() => handleDelete(loc)}>
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* add-mls */}

              <div className="working_area add mls-images">
                <form onSubmit={handleMlsSubmit}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text image_set bdr1">
                        <img src={Building} alt="Brokerage Solutions" />
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Add your MLS" value={mlsInput} onChange={(e) => setMlsInput(e.target.value)} />
                    <div className="input-group-append">
                      <button type="submit" className="input-group-text  second_search">
                        <img src={Search} alt="Search" />
                      </button>
                    </div>
                  </div>
                  <div className="mls_locations">
                    {mlsData.map((data) => (
                      <div key={data.image} className="location_all">
                        <img src={data.image} alt={data.alt} />
                        <button className="btn btn-sm ml-2 delete_mls" onClick={() => handleMlsDelete(data)}>
                          <img src={Cross} alt="Delete" />
                        </button>
                      </div>
                    ))}
                  </div>
                </form>
              </div>

              {/* add-mls */}

              <Link to={url}>
                <button onClick={() => handleBrokerageSubmit(selectedAgentRange)} className="explore_more">
                  Explore More
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
