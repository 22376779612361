import React from "react";
import "./DashboardArea.css";
import Header from "./Header/Header";
import SidebarRealtor from "./Sidebar/SidebarRealtor";
import RealtorDashboard from "./Dashboard/RealtorDashboard";

function RealtorDashboardArea() {
  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <SidebarRealtor />
      </div>
      <div className="header-dashboard main_container">
        <Header />
        <RealtorDashboard />
      </div>
    </div>
  );
}

export default RealtorDashboardArea;
