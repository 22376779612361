import "./Sidebar.css";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ArrowRight from "../../../assets/left-arrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DashboardLogo from "../../../assets/white-default-logo.svg";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userType = useSelector((state) => state.userType);
  const agentType = useSelector((state) => state.agentType);
  const websitelogo = useSelector((state) => state.websiteLogo);
  const [isBlogsExpanded, setIsBlogsExpanded] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);
  const isbrandingdata = useSelector((state) => state.setBrandingDataFetched);

  const brokerOptions = [
    { name: "Dashboard", iconClass: "icon-dashboard", link: "/" },
    { name: "Listings", iconClass: "icon-location", link: "/listing-mls" },
    { name: "Leads", iconClass: "icon-magnet", link: "/leads" },
    {
      name: "Realtor",
      iconClass: "icon-users",
      link: "/realtor",
      hasBottomBorder: true,
      specialClass: false,
    },
    // { name: "Edit Website", iconClass: "icon-pencil", link: "#" },
    {
      name: "Blogs",
      iconClass: "icon-blogs",
      link: "#",
      hasBottomBorder: true,
      specialClass: true,
      setting_only: true,
      blog_only: true,
      children: [
        { name: "Posts", link: "/blogs", iconClass: "icon-tag1" },
        {
          name: "Manage Tags",
          link: "/manage-tags",
          iconClass: "icon-document",
        },
        //  {
        //   name: "Default",
        //   link: "/listing-display",
        //   iconClass: "icon-grid",
        //  },
      ],
    },
    //  { name: "MLS", iconClass: "icon-mls", link: "#" },
    { name: "Set Payments", iconClass: "icon-mls", link: "/set-payments" },
    // { name: "Traffic", iconClass: "icon-traffic", link: "#" },
    {
      name: "Packages",
      iconClass: "icon-packages",
      // link: "/choose-package",
      link: "/packages",
      // hasBottomBorder: true,
      // specialClass: true,
    },
    {
      name: "Transactions",
      iconClass: "icon-document",
      link: "/transactions",
      hasBottomBorder: true,
      specialClass: true,
    },
    {
      name: "Settings",
      iconClass: "icon-Setting",
      link: "#",
      setting_only: true,
      children: [
        { name: "Branding", link: "/branding", iconClass: "icon-image" },
        //  { name: "Search", link: "/search", iconClass: "icon-Magnifier" },
        // {
        //  name: "Listing Display",
        //  link: "/listing-display",
        //  iconClass: "icon-eye",
        // },
        { name: "Meta Tags", link: "/meta-tags", iconClass: "icon-tag" },
        { name: "Extra Code", link: "/extra-code", iconClass: "icon-Arrow" },
        {
          name: "Google Analytics",
          link: "/google-analytics",
          iconClass: "icon-google",
        },
        { name: "Amp pages", link: "/amp-pages", iconClass: "icon-show-chart" },
      ],
    },
    { name: "Support", iconClass: "icon-help", link: "/support" },
  ];

  const realtorOptions = [
    { name: "Dashboard", iconClass: "icon-dashboard", link: "/" },
    { name: "Listings", iconClass: "icon-location", link: "/listing-mls" },
    { name: "Leads", iconClass: "icon-magnet", link: "/leads" },
    {
      name: "Packages",
      iconClass: "icon-packages",
      link: "/realtor-package",
    },
    // {
    //   name: "Blogs",
    //   iconClass: "icon-blogs",
    //   link: "#",
    //   hasBottomBorder: true,
    //   specialClass: true,
    //   setting_only: true,
    //   blog_only: true,
    //   children: [
    //     { name: "Posts", link: "/blogs", iconClass: "icon-tag1" },
    //     {
    //       name: "Manage Tags",
    //       link: "/manage-tags",
    //       iconClass: "icon-document",
    //     },
    //     //  {
    //     //   name: "Default",
    //     //   link: "/listing-display",
    //     //   iconClass: "icon-grid",
    //     //  },
    //   ],
    // },
    {
      name: "Transactions",
      iconClass: "icon-document",
      link: "/transactions",
      hasBottomBorder: true,
      specialClass: true,
    },
  ];

  const agentTypeBrokerOptions = agentType == 1 ? brokerOptions : brokerOptions.filter((el) => el.name !== "Realtor");

  const userTypeOptions = userType == 1 ? agentTypeBrokerOptions : realtorOptions;

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleOptionClick = (e, option) => {
    e.preventDefault();
    if (option.name === "Settings") {
      setIsSettingsExpanded(!isSettingsExpanded);
    } else if (option.name === "Blogs") {
      setIsBlogsExpanded(!isBlogsExpanded);
    }
  };

  return (
    <>
      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
      </button>

      <div className={`sidebar ${isCollapsed ? "collapsed" : ""} ${isSidebarVisible ? "visible" : ""}`}>
        <div className="logo main-logo">
          <NavLink to="/">{isbrandingdata && <img src={websitelogo ? websitelogo : DashboardLogo} alt="Logo" className={isCollapsed ? "logo-image-small" : "logo-image"} />}</NavLink>
        </div>

        <ul className="options">
          <div className={`middle_sidebar ${isCollapsed ? "collapsed" : ""}`}>
            {userTypeOptions.map((option, index) => (
              <li
                key={index}
                className={`option ${option.hasBottomBorder ? "border-bottom-class" : ""} ${option.specialClass ? "special-class" : ""} ${option.setting_only ? "setting_only" : ""} ${option.blog_only ? "blog_only" : ""}`}
                onClick={(e) => {
                  handleOptionClick(e, option);
                }}
              >
                <NavLink to={option.link} className={`option-link ${isCollapsed ? "collapsed" : ""}`} activeClassName="">
                  <span className={`option-icon ${option.iconClass}`}></span>
                  {!isCollapsed && (
                    <>
                      {option.children ? (
                        <>
                          {option.name}
                          <FontAwesomeIcon icon={faChevronDown} className={`icon-chevron-down ${option.name === "Settings" && isSettingsExpanded ? "rotated" : ""} ${option.name === "Blogs" && isBlogsExpanded ? "rotated" : ""}`} />
                        </>
                      ) : (
                        option.name
                      )}
                    </>
                  )}
                </NavLink>

                {option.children && ((option.name === "Settings" && isSettingsExpanded) || (option.name === "Blogs" && isBlogsExpanded)) && (
                  <ul className="sub-options">
                    {option.children.map((child, idx) => (
                      <li key={idx} className="sub-option">
                        <NavLink to={child.link} activeClassName="">
                          <span className={child.iconClass}></span>
                          {!isCollapsed && child.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </div>
        </ul>

        <button
          className={`collapse-btn btn-custom ${isCollapsed ? "collapsed" : ""}`}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <img src={ArrowRight} alt="Collapse Icon" className="arrow-icon" />
          {!isCollapsed && " Collapse"}
        </button>
      </div>
    </>
  );
};

export default Sidebar;
