import React, { useRef, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import Google from "../../assets/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { setRegToken, setTokenExpTime, setRefreshToken, setUserType, setAgentType, setEmail, setProfileImage, setFullName, setCompanyname, setLastName, setFirstName, setSubscribed } from "../../Redux/Action/Authaction";

const LoginSection = ({ activeSection, loginError, onSetLoginError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userInputForLogin, setUserInputForLogin] = useState({
    emailForLogin: "",
    passwordForLogin: "",
  });

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmitLogin();
    }
  };

  const handleChangeForLogin = (e) => {
    const { name, value } = e.target;
    setUserInputForLogin({
      ...userInputForLogin,
      [name]: value,
    });
    onSetLoginError((prevError) => {
      const { [name]: removedError, ...rest } = prevError;
      return rest;
    });
  };

  const handleSubmitLogin = (e) => {
    setLoading(true);
    const params = {
      email: userInputForLogin.email,
      password: userInputForLogin.password,
    };
    axios
      .post(Url.BASEURL + "/broker/login", params)
      .then((value) => {
        if (value.data.success !== false) {
          console.log("okk", value?.data?.subscribed)
          const fullName = `${value?.data?.user?.firstname} ${value?.data?.user?.lastname}`;
          setLoading(false);
          dispatch(setUserType(value?.data?.userType));
          localStorage.setItem("userType", value.data?.userType);
          dispatch(setSubscribed(value?.data?.subscribed));
          // if (value?.data?.user?.setup_fee_payment_status) {
          //   dispatch(setSubscribed(value?.data?.user?.setup_fee_payment_status));
          // }

          dispatch(setRegToken(value?.data?.accessToken));
          dispatch(setRefreshToken(value?.data?.refreshToken));
          dispatch(setTokenExpTime(value?.data?.expirationTime));
          dispatch(setEmail(value?.data?.user?.email));
          dispatch(setFirstName(value?.data?.user?.firstname));
          dispatch(setLastName(value?.data?.user?.lastname));
          dispatch(setCompanyname(value?.data?.user?.companyName));
          dispatch(setAgentType(value?.data?.user?.agentType));
          dispatch(setFullName(fullName));
          dispatch(setProfileImage(Url.IMGURL + value?.data?.user?.image));

          if (value.data.userType == 0) {
            console.log(value?.data)
            // UserType 0 corresponds to "Realtor"
            if (value?.data?.contactDetails === false) {
              console.log("fallhere")
              navigate("/realtor-profile");
            } else if (value?.data?.setup_fee_payment_status === false) {
              window.location.href = value?.data?.sessionUrl;
            } else if (value?.data?.login === true) {
              navigate("/");
            }
          } else if (value?.data?.userType == 1) {
            // UserType 1 corresponds to "Broker"
            if (value?.data?.contactDetails === false) {
              navigate("/broker-profile");
            } else if (value?.data?.setUpFee === false || value?.data?.margin === false || value?.data?.loanInterest === false) {
              navigate("/payment");
            } else if (value?.data?.totalRealtorsCalculated === false && value?.data?.agentType === 1) {
              navigate("/choose-package");
            } else if (value?.data?.mlsChosen === false) {
              navigate("/packages-mls");
            } else if (value?.data?.subdomainSaved === false) {
              localStorage.setItem("navigateFrom", "login");
              navigate("/register-domain");
            } else if (value?.data?.pricingReviewCalculated === false || value?.data?.billingInfoSaved === false) {
              navigate("/pricing-review");
            } else if (value?.data?.accountId === false) {
              localStorage.setItem("isPollingStart", "true");
              window.location.href = value?.data?.link;
            } else if (value?.data?.subscribed === false) {
              window.location.href = value?.data?.sessionUrl;
            } else if (value?.data?.login === true) {
              navigate("/");
              // const subdomain = value?.data?.subdomain;
              // window.location.href = subdomain;
            } else {
              console.log("called");
            }
          }
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          const apiErrors = err?.response?.data?.errors;
          onSetLoginError(apiErrors || {});
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loginWithGoogle = () => {
    const googleAuthUrl = `${Url.BASEURL}/broker/social/auth/google`;
    const width = 600;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const options = `width=${width},height=${height},left=${left},top=${top},modal=yes,centerscreen=yes`;

    const googleWindow = window.open(googleAuthUrl, "Google Authentication", options);
    window.addEventListener("message", async (event) => {
      if (event.source === googleWindow) {
        const data = event.data;
        if (data.success) {
          setLoading(false);
          dispatch(setUserType(data?.userType));
          dispatch(setRegToken(data?.accessToken?.token));
          dispatch(setTokenExpTime(data?.accessToken?.expirationTime));
          dispatch(setRefreshToken(data?.refreshToken));
          dispatch(setEmail(data?.user?.email));
          dispatch(setFirstName(data?.user?.firstname));
          dispatch(setLastName(data?.user?.lastname));
          dispatch(setCompanyname(data?.user?.companyName));
          dispatch(setFullName(data?.user?.firstname));
          dispatch(setAgentType(data?.user?.agentType));
          dispatch(setProfileImage(Url.IMGURL + data?.user?.image));
          if (data?.userType == 1) {
            // UserType 1 corresponds to "Broker"
            if (data?.contactDetails === false) {
              navigate("/broker-profile");
            } else if (data?.setUpFee === false || data?.margin === false || data?.loanInterest === false) {
              navigate("/payment");
            } else if (data?.totalRealtorsCalculated === false && data?.agentType === 1) {
              navigate("/choose-package");
            } else if (data?.mlsChosen === false) {
              navigate("/packages-mls");
            } else if (data?.subdomainSaved === false) {
              navigate("/register-domain");
            } else if (data?.pricingReviewCalculated === false || data?.billingInfoSaved === false) {
              navigate("/pricing-review");
            } else if (data?.accountId === false) {
              window.location.href = data?.link;
            } else if (data?.subscribed === false) {
              window.location.href = data?.sessionUrl;
            } else if (data?.login === true) {
              navigate("/");
            } else {
              console.log("called");
            }
          }
        } else {
          googleWindow.close();
        }
      }
    });
  };

  return (
    <>
      {loginError?.message && <p style={{ color: "red", fontSize: "13px", textAlign: "center" }}>{loginError?.message}</p>}
      <div className={`section mt-4 login ${activeSection === "login" ? "active" : "d-none"}`}>
        <div>
          <div className="mb-3">
            <input type="email" name="email" className="form-control" placeholder="Email Address*" value={userInputForLogin.email} onChange={handleChangeForLogin} onKeyDown={handleKeyDown} ref={emailInputRef} />
            {loginError.email && <p style={{ color: "red", fontSize: "13px" }}>{loginError.email}</p>}
          </div>
          <div className="mb-3">
            <input type="password" name="password" className="form-control" placeholder="Password*" value={userInputForLogin.password} onChange={handleChangeForLogin} onKeyDown={handleKeyDown} />
            {loginError.password && <p style={{ color: "red", fontSize: "13px" }}>{loginError.password}</p>}
          </div>
          <div className="forget_process">
            <button className="fget_main">
              <Link to="/forgot-password">Forgot Password?</Link>
            </button>
          </div>
          <div className="loggers d-flex">
            <div className="logger_all">
              <button onClick={handleSubmitLogin} className="">
                {loading ? (
                  <>
                    <span>Login</span>
                    <ClipLoader color="#ffffff" loading={loading} size={20} aria-label="Loading Spinner" data-testid="loader" />
                  </>
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div className="loggert_two google_signin">
              <button className="" onClick={loginWithGoogle}>
                <img src={Google} alt="google" /> Sign in with Google
              </button>
            </div>
          </div>
          {/* <div className="two_log_btns d-flex">
            <button onClick={handleSubmitLogin} className="logreg_btn btn btn-primary cus_logger">
              Login
            </button>
            <button className="google_signin" onClick={loginWithGoogle}>
              <img src={Google} alt="google" /> Sign in with Google
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LoginSection;
