import React from "react";
import "../Blog/BlogPages.css";
import ManageTags from "../Blog/ManageTags";
import BlogListing from "../Blog/Bloglisting";
import DefaultSettingBlog from "../Blog/SingleBlog";
import SingleBlog from "../Blog/BlogDefaultSetting";

function BlogPages() {
  return (
    <div className="setting-pages">
      <BlogListing />
      <SingleBlog />
      <ManageTags />
      <DefaultSettingBlog />
    </div>
  );
}

export default BlogPages;
