import axios from "axios";
import Url from "../../Element/Api";
import React, { useState } from "react";
import useAuth from "../../../hooks/useAuth";
import Upload from "../../../assets/upload.svg";

const CsvUploadModal = ({ isCsvModalOpen, closeModal, onSetNotSentDueToLimit, onOpenLimitModal }) => {
  const [config, configFormData] = useAuth();
  const [error, setError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [alreadyExistsRealtor, setAlreadyExistsRealtor] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState("CSV sheet upload here");

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setSelectedFileName(event.target.files[0]?.name);
  };

  const handleClose = () => {
    closeModal();
    setError("");
    setIsDisable(false);
    setSelectedFile(null);
    onSetNotSentDueToLimit(0);
    setAlreadyExistsRealtor([]);
    setSelectedFileName("CSV file Uploaded here");
  };

  const uploadCSVFile = () => {
    setIsDisable(true);
    const formData = new FormData();
    formData.append("csvFile", selectedFile);
    axios
      .post(Url.BASEURL + `/broker/invite-realtors-from-csv`, formData, configFormData)
      .then((res) => {
        closeModal();
        setIsDisable(false);
        setSelectedFile(null);
        setSelectedFileName("CSV file Uploaded here");
        setAlreadyExistsRealtor([]);
        setError("");
        if (res?.notSentDueToLimit) {
          onSetNotSentDueToLimit(res?.notSentDueToLimit?.length);
          onOpenLimitModal();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.alreadyExistsRealtor) {
          setAlreadyExistsRealtor(err?.response?.data?.alreadyExistsRealtor);
        }
        setIsDisable(false);
        onSetNotSentDueToLimit(0);
        setError(err?.response?.data?.message);
        setSelectedFile(null);
        setSelectedFileName("CSV file Uploaded here");
      });
  };

  return (
    <div className={`modal fade ${isCsvModalOpen ? "show" : ""}`} id="uploadCsvModal" style={{ display: isCsvModalOpen ? "block" : "none" }} tabIndex="-1" aria-hidden={!isCsvModalOpen}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body csv-upload-modal">
            <div className="csv-input-wrapper">
              <input type="file" id="csvFileInput" accept=".csv" hidden onChange={handleFileSelect} />
              <label htmlFor="csvFileInput" className="csv-upload-input">
                {selectedFileName}
                <img src={Upload} alt="Upload" />
              </label>
              <a href="https://docs.google.com/spreadsheets/d/1_6R32v4QxpWrn1m4AAr7n8MpynyCi75-CLA3kgHMZ30/export?format=csv" className="download-sheet-link pl-1">
                Download Sheet Format here
              </a>
            </div>
            {error !== "" && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>}
            {/*  {alreadyExistsRealtor?.length !== 0 && <div style={{ color: "red", fontSize: "12px" }}>{alreadyExistsRealtor?.map((el, i) => (i === alreadyExistsRealtor.length - 1 ? el : `${el}, `))} already exists</div>}
             */}
          </div>

          <div className="modal-footer csv_footer d-flex justify-content-between">
            <button type="button" className="btn btn-upload" onClick={uploadCSVFile} disabled={isDisable}>
              Upload
            </button>

            <button type="button" className="btn btn-cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CsvUploadModal;
