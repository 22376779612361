import React from "react";
import "../LoginRegister/LoginRegister.css";
import { useSelector } from "react-redux";
import Timer from "../../assets/timer.svg";
import LoginLayout from "../../layouts/LoginLayout";

const EmailVerification = ({ route }) => {
  const emaill = useSelector((state) => state.regEmail);

  return (
    <LoginLayout>
      <div className="email_verification set_verification">
        <h3>Email Verification</h3>
        <p>We noticed your email address has not been verified. By doing so, you will receive important notifications and information about your account.</p>
        <img src={Timer} alt="timer" />

        <h4>Please Verify Your Email Address</h4>
        <p>The email for your account is:</p>
        <div className="email_verify">{emaill}</div>
        <div className="update_link_email">
          <h3>Please check your email.</h3>
        </div>
      </div>
    </LoginLayout>
  );
};

export default EmailVerification;
