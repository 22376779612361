import React, { useEffect, useState } from "react";
import "./Realtor.css";
import axios from "axios";
import Url from "../Element/Api";
import "rc-slider/assets/index.css";
import ReactSlider from "react-slider";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import useAuth from "../../hooks/useAuth";
import { ClipLoader } from "react-spinners";
import Dollar from "../../assets/dollar.svg";
import Percentage from "../../assets/percentage.svg";

function PaymentSet() {
  const defaultMargin = 0;
  const defaultLoanTerm = 0;
  const defaultSetUpCost = 0;
  const [config, configFormData] = useAuth();
  const [agentType, setAgentType] = useState();
  const [loading, setLoading] = useState(true);
  const [buttonKey, setButtonKey] = useState(0);
  const [margin, setMargin] = useState(defaultMargin);
  const [loanTerm, setLoanTerm] = useState(defaultLoanTerm);
  const [setUpCost, setSetUpCost] = useState(defaultSetUpCost);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [imagePreview, setImagePreview] = useState("https://via.placeholder.com/150");

  const handleNumericChange = (value, setterFunction, maxValue) => {
    let numericValue = parseFloat(value) || 0;
    if (numericValue > maxValue) {
      numericValue = maxValue;
    }
    setterFunction(numericValue);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    axios
      .get(`${Url.BASEURL}/broker/profile`, configFormData)
      .then((response) => {
        setAgentType(response.data.agentType);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleSave = () => {
    const params = {
      margin: parseFloat(margin),
      setUpFee: parseFloat(setUpCost),
      loanInterest: parseFloat(loanTerm),
      buttonKey: 3,
    };

    axios
      .post(Url.BASEURL + `/broker/set-payment`, params, config)
      .then((response) => {
        if (response.data.success == true) {
          setShowSaveConfirmation(false);
          if (params.margin === defaultMargin) {
            setMargin(defaultMargin);
          }
          if (params.setUpFee === defaultSetUpCost) {
            setSetUpCost(defaultSetUpCost);
          }
          if (params.loanInterest === defaultLoanTerm) {
            setLoanTerm(defaultLoanTerm);
          }
        }
      })
      .catch((error) => {
        console.error("Error saving payment settings:", error);
      });
  };

  const handleSaveClick = (e, key) => {
    setButtonKey(key);
    setShowSaveConfirmation(true);
  };

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container_bg margintop custom_principal_page">
        <div className="row ">
          <div className="col-lg-12">
            <div className="principal_broker">
              <div className="">
                <div className="delete-modal-content">
                  <div className="mutual_blocks amp-pages set_payments">
                    <h2 style={{ textAlign: "center" }}>Set Payments</h2>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "50vh",
                        }}
                      >
                        <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                      </div>
                    ) : (
                      <div className="margins_blocks">
                        {agentType == 1 && (
                          <>
                            <div className="single_margin_block">
                              <div className="margin_headings">
                                Margin <span>0% - 100%</span>
                              </div>
                              <div className="payment_slider">
                                <div className="left_slader maxWidth">
                                  <input type="text" className="form-control" id="interestRate" value={margin} onChange={(e) => handleNumericChange(e.target.value, setMargin, 100)} />
                                  <div className="icon_savees">
                                    <img src={Percentage} alt="" />
                                  </div>
                                  <ReactSlider className="horizontal-slider test" thumbClassName="thumb colorChnage focusChange" trackClassName="track" min={0} max={100} step={1} value={margin} onChange={(value) => setMargin(value)} />
                                </div>
                                <div className="right_slade"></div>
                              </div>
                              <div className="paymnent_texting urlChange">
                                You have the capability to set percentage amount, inclusive of the base
                                <span className="pricing_pay">$25</span> established by Wovnn, along with an additional margin.
                              </div>
                            </div>

                            <div className="bdr_center"></div>

                            <div className="single_margin_block">
                              <div className="margin_headings">
                                Set Up Fees <span>$0 - $5000</span>
                              </div>
                              <div className="payment_slider">
                                <div className="left_slader maxWidth">
                                  <input type="text" className="form-control" id="propertyCost" value={setUpCost} onChange={(e) => handleNumericChange(e.target.value, setSetUpCost, 5000)} />
                                  <div className="icon_savees">
                                    <img src={Dollar} alt="" />
                                  </div>
                                  <ReactSlider className="horizontal-slider test" thumbClassName="thumb colorChnage focusChange" trackClassName="track" min={0} max={5000} step={10} value={setUpCost} onChange={(value) => setSetUpCost(value)} />
                                </div>
                                <div className="right_slade"></div>
                              </div>
                              <div className="paymnent_texting urlChange">You have to set an amount for realtors adding their profiles under your brokerage on the website.</div>
                            </div>

                            <div className="bdr_center"></div>
                          </>
                        )}
                        <div className="single_margin_block">
                          <div className="margin_headings">
                            Set Loan Interest <span>0% - 25%</span>
                          </div>
                          <div className="payment_slider">
                            <div className="left_slader maxWidth">
                              <input type="text" className="form-control" id="loanTerm" value={loanTerm} onChange={(e) => handleNumericChange(e.target.value, setLoanTerm, 25)} />
                              <div className="icon_savees">
                                <img src={Percentage} alt="" />
                              </div>
                              <ReactSlider className="horizontal-slider test" thumbClassName="thumb colorChnage focusChange" trackClassName="track" min={0} max={25} step={1} value={loanTerm} onChange={(value) => setLoanTerm(value)} />
                            </div>
                            <div className="right_slade"></div>
                          </div>
                          <div className="paymnent_texting urlChange">Setting a clear loan percentage is crucial. It directly affects buyers making property purchases on our platform.</div>
                        </div>
                      </div>
                    )}
                    <Link to="/choose-package" style={{ textDecoration: "none" }}>
                      <button onClick={handleSave} className="btn update_btn btnclr" style={{ display: "block", margin: "0 auto", marginTop: "30px", width: "216px" }}>
                        Continue
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentSet;
