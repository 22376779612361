import React, { useState } from "react";
import "./Step.css";
import axios from "axios";
import Url from "../Element/Api";
import User from "../../assets/users.png";
import { useSelector } from "react-redux";
import Account from "../../assets/account.png";

const priceIdForAgent = "price_1O9KxwSECkIUp6TqipK5ttJz";

const Step2 = () => {
  const emaill = useSelector((state) => state.regEmail);
  const [error, setError] = useState("");
  const [showMain, setShowMain] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [validationError, setValidationError] = useState("");
  const [selectedAgentRange, setSelectedAgentRange] = useState("");
  const [emailForRegistration, setEmailForRegistration] = useState("");

  // const handleIndependentSubmitt = async () => {
  //   const params = {
  //     email: emaill,
  //     priceId: priceIdForAgent,
  //     numberOfAgents: "",
  //     agentType: userType,
  //   };
  //   axios
  //     .post(Url.BASEURL + "/" + "broker/" + "create-subscription", params)
  //     .then((value) => {
  //       if (value.data.success === true) {
  //         setEmailForRegistration(value.data);
  //         navigate("/email-verify");
  //       } else {
  //         // Handle successful response with a different condition, if needed
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response && err.response.data && err.response.data.message) {
  //         setError(err.response.data.message);
  //       } else {
  //         setError("An error occurred while processing your request.");
  //       }
  //     });
  // };

  const handleAgentRangeChange = (e) => {
    setSelectedAgentRange(e.target.value);
    setShowInput(true);
  };

  const isValidInput = (inputNumber, selectedAgentRange) => {
    const agentRangeParts = selectedAgentRange.split("-");
    if (agentRangeParts.length === 2) {
      const minRange = parseInt(agentRangeParts[0]);
      const maxRange = parseInt(agentRangeParts[1]);
      return inputNumber >= minRange && inputNumber <= maxRange && !isNaN(inputNumber);
    } else if (selectedAgentRange === "100+") {
      return inputNumber >= 100 && !isNaN(inputNumber);
    }
    return false;
  };

  // function for brokerage solution
  const handleBrokerageSolutionSubmit = () => {
    let validationError = "";
    if (!selectedAgentRange) {
      validationError = "Please select an option from the dropdown.";
    } else if (showInput) {
      const inputNumber = parseInt(inputValue, 10);
      if (isNaN(inputNumber) || !isValidInput(inputNumber, selectedAgentRange)) {
        validationError = `Please enter a valid number based on the selected range: ${selectedAgentRange}`;
      }
    }
    setValidationError(validationError);
    setShowError(!!validationError);
    if (!validationError) {
      const params = {
        email: emaill,
        priceId: priceIdForAgent,
        numberOfAgents: inputValue,
        agentType: 1,
      };

      axios
        .post(Url.BASEURL + "/broker/create-subscription", params)
        .then((value) => {
          if (value.data.success === true) {
            setEmailForRegistration(value.data);
            // navigate("/email-verify");
            window.location.href = value.data.session.url;
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            setError(err.response.data.message);
          } else {
            setError("An error occurred while processing your request.");
          }
        });
    }
  };

  // function for independent broker
  const handleIndependentSubmit = () => {
    const params = {
      email: emaill,
      priceId: priceIdForAgent,
      numberOfAgents: 1,
      agentType: 0,
    };

    axios
      .post(Url.BASEURL + "/broker/create-subscription", params)
      .then((value) => {
        if (value.data.success === true) {
          setEmailForRegistration(value.data);
          // navigate("/email-verify");
          window.location.href = value.data.session.url;
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        }
      });
  };

  return (
    <div>
      <div className={`container container_bg second-options ${showMain ? "d-block" : ""}`}>
        <div className="row">
          <div className="col-lg-12">
            {/* Top Three Options */}
            <div className="top-options">
              <button
                style={{
                  backgroundColor: "#80C342",
                  color: "#fff",
                  borderBottomRightRadius: selectedOption === 2 ? "0px" : "inherit",
                  borderTopRightRadius: selectedOption === 2 ? "0px" : "inherit",
                }}
              >
                Signup
              </button>
              <button
                style={{
                  backgroundColor: selectedOption === 2 ? "#80C342" : "#D4EEDA",
                  color: selectedOption === 2 ? "#fff" : "#141928",
                  borderTopLeftRadius: selectedOption === 2 ? "0px" : "inherit",
                  borderBottomLeftRadius: selectedOption === 2 ? "0px" : "inherit",
                }}
              >
                Choose your Package
              </button>
              <button
                style={{
                  backgroundColor: "#D4EEDA",
                  color: selectedOption === 2 ? "#141928" : "#B4B9C8",
                }}
              >
                What is your MLS?
              </button>
            </div>

            {/* Package Selection */}
            {selectedOption === 0 && (
              <div className="package-selection">
                <div className="websites_data d-flex">
                  <div className="websites agent-website">
                    <h3>Agent Website</h3>
                    <div className="web_icon">
                      <img src={Account} alt="Agent Website" />
                    </div>
                    <div className="webtext">Perfect for an independent agent or broker.</div>
                    <button
                      className="clicker first_click"
                      onClick={() => {
                        handleIndependentSubmit();
                      }}
                    >
                      Select
                    </button>
                  </div>
                  <div className="websites broker_sol">
                    <h3>Brokerage Solutions</h3>
                    <div className="web_icon">
                      <img src={User} alt="Brokerage Solutions" />
                    </div>
                    <div className="webtext">Website and back office tools for the entire team.</div>
                    <div className="web_select_option">
                      <p>How many agents are on your team?</p>
                      <select className="form-select" aria-label="Default select example" onChange={handleAgentRangeChange} value={selectedAgentRange}>
                        <option value="">Select an option</option>
                        <option value="1-10">1-10 Agents</option>
                        <option value="11-50">11-50 Agents</option>
                        <option value="51-100">51-100 Agents</option>
                        <option value="100+">+100 Agents</option>
                      </select>
                    </div>

                    {showInput && (
                      <div className="web_select_option">
                        <p>Enter a custom range:</p>
                        <input
                          type="text"
                          className="form-control input-small"
                          value={inputValue}
                          onChange={(e) => {
                            setInputValue(e.target.value);
                          }}
                          placeholder="Enter Number of Agents"
                        />
                      </div>
                    )}

                    <button
                      className="clicker second_click"
                      onClick={() => {
                        handleBrokerageSolutionSubmit();
                      }}
                    >
                      Select
                    </button>

                    {showError && <p style={{ color: "red" }}>{validationError}</p>}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
