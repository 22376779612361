import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { Modal } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";

function PackageInfo({ show, onClose, billingCycle, handleUpdatePlan, packageInfoData, handlePackageApi }) {
  const [config] = useAuth();
  const [isDisable, setIsDisable] = useState(false);
  const [pricingData, setPricingData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (show) {
          const response = await axios.post(
            `${Url.BASEURL}/broker/pricing-review`,
            {
              billingCycle: billingCycle,
            },
            config
          );

          setPricingData(response.data.brokerDetails);
        }
      } catch (error) {
        console.error("Error fetching pricing data:", error);
      }
    };

    fetchData();
  }, [show]);

  const handleSubmit = () => {
    handleUpdatePlan();
    onClose();
  };

    return (
        <Modal show={show} onHide={onClose} backdrop="static" className="realtor-payment-modal rpm">
            <Modal.Body style={{ padding: "30px 0px" }}>
                <div>
                    <h6>Billing info</h6>

          {pricingData && (
            <div className="table-responsive mt-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>MLS Name</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {pricingData.selectedMLS.map((mls) => (
                    <tr key={mls._id}>
                      <td>{mls.mlsName}</td>
                      <td>{mls.price}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="2" className="text-end">
                      Total Cost: ${pricingData.totalCost}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}

          <div className="d-flex justify-content-center mt-2">
            <button disabled={isDisable} onClick={onClose} className="continue-button">
              cancel
            </button>
            <button disabled={isDisable} onClick={handleSubmit} className="continue-button">
              Change Subscription
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PackageInfo;
