import React, { useState, useEffect } from "react";
import "./Branding.css";
import axios from "axios";
import "../SettingPages.css";
import Url from "../../../Element/Api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";
import useAuth from "../../../../hooks/useAuth";
import Close from "../../../../assets/closer.png";
import Shield from "../../../../assets/shield.svg";
import ModalMessage from "../../../ModalMessage/Modal";
import BaseLayout from "../../../../layouts/BaseLayout";
import UploaderIcon from "../../../../assets/uploader.svg";
import Brand from "../../../../assets/black-default-logo.svg";
import { setBrandingData } from "../../../../Redux/Action/Authaction";
import { exceptThisSymbols, getFaxIdFormat } from "../../../../utils/utils";

function Branding() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedFavicon, setUploadedFavicon] = useState(null);
  const [customURLSelected, setCustomURLSelected] = useState(false);
  const [brandingInfo, setBrandingInfo] = useState({
    websiteLogo: null,
    logoAlternateText: "",
    logoLink: null,
    favicon: null,
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phone: "",
    faxId: "",
    email: "",
  });

  const handleImageUpload = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setBrandingInfo({
      ...brandingInfo,
      [name]: file,
    });
    setFormErrors({ ...formErrors, [name]: "" });
    if (file && file.type.substr(0, 5) === "image") {
      if (name === "websiteLogo") {
        setUploadedImage(URL.createObjectURL(file));
      } else {
        setUploadedFavicon(URL.createObjectURL(file));
      }
    } else {
      if (name === "websiteLogo") {
        setUploadedImage(null);
      } else {
        setUploadedFavicon(null);
      }
    }
    e.target.value = null;
  };

  const handleSave = async (event) => {
    event.preventDefault();

    // Convert Blob URLs to File objects
    // const websiteLogoFile = await fetch(uploadedImage).then((res) =>
    // res.blob()
    // );
    // const faviconFile = await fetch(uploadedFavicon).then((res) => res.blob());

    const formData = new FormData();
    if (brandingInfo?.websiteLogo !== "") formData.append("websiteLogo", brandingInfo?.websiteLogo);
    if (brandingInfo?.logoAlternateText !== "") formData.append("logoAlternateText", brandingInfo?.logoAlternateText);
    if (brandingInfo?.logoLink !== "") formData.append("logoLink", brandingInfo?.logoLink);
    if (brandingInfo?.favicon !== "") formData.append("favicon", brandingInfo?.favicon);
    if (brandingInfo?.address !== "") formData.append("address", brandingInfo?.address);
    if (brandingInfo?.city !== "") formData.append("city", brandingInfo?.city);
    if (brandingInfo?.state !== "") formData.append("state", brandingInfo?.state);
    if (brandingInfo?.zipCode !== "") formData.append("zipCode", brandingInfo?.zipCode);
    if (brandingInfo?.faxId !== "") formData.append("faxId", brandingInfo?.faxId);
    if (brandingInfo?.email !== "") formData.append("email", brandingInfo?.email);
    if (brandingInfo?.phone !== "") formData.append("phone", brandingInfo?.phone);

    try {
      const response = await axios.post(Url.BASEURL + "/broker/branding", formData, config);
      if (response?.data) {
        const websiteLogo = Url.IMGURL + response?.data?.branding?.websiteLogo;
        const favIcon = Url.IMGURL + response?.data?.branding?.favicon;
        dispatch(setBrandingData(favIcon, websiteLogo));
        navigate("/");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        setFormErrors(error.response.data.errors);
      }
      setShowModal(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Url.BASEURL + "/broker/get-branding", config);
        const brandingData = response.data.branding;

        if (brandingData.logoLink && brandingData.logoLink !== "Not linked" && brandingData.logoLink !== "/") {
          setCustomURLSelected(true);
        }
        setUploadedImage(brandingData.imageURL || null);
        setUploadedFavicon(brandingData.faviconURL || null);
        setBrandingInfo({
          websiteLogo: brandingData?.websiteLogo || null,
          logoAlternateText: brandingData?.logoAlternateText || "",
          logoLink: brandingData?.logoLink || "",
          favicon: brandingData?.favicon || null,
          address: brandingData?.officeLocation?.address || "",
          city: brandingData?.officeLocation?.city || "",
          state: brandingData?.officeLocation?.state || "",
          zipCode: brandingData?.officeLocation?.zipCode || "",
          phone: brandingData?.contactInformation?.phone || "",
          faxId: brandingData?.contactInformation?.faxId || "",
          email: brandingData?.contactInformation?.email || "",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const onlyAlphabets = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const onlyNumber = e.target.value.replace(/[^0-9 ]/, "");
    const numberAlphabets = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    const valueToSet = name === "city" || name === "state" ? onlyAlphabets : name === "zipCode" || name === "phone" ? onlyNumber : name === "faxId" ? numberAlphabets : value;

    setFormErrors((prev) => ({ ...prev, [name]: "" }));
    setBrandingInfo({
      ...brandingInfo,
      [name]: valueToSet,
    });
  };

  const handleZipCodeKeyDown = (e) => {
    if (e.target.value.length >= 5 && e.key !== "Backspace") {
      e.preventDefault();
    }
    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages branding-page">
        <h2>Branding</h2>
        {loading ? (
          <div className="d-flex justify-content-center my-5">
            <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
          </div>
        ) : (
          <form>
            <div className="title_bar_setting">
              <h3>Website Logo</h3>
              <p className="mt-top">Click "Upload" to select an image from your file manager. The preview image will update to show the new logo.</p>

              <div className="logo-uploader d-flex">
                <div className="web_logo">
                  {uploadedImage && (
                    <div
                      className="closer"
                      onClick={() => {
                        setBrandingInfo({
                          ...brandingInfo,
                          websiteLogo: null,
                        });
                        setUploadedImage(null);
                      }}
                    >
                      <img src={Close} alt="remove" />
                    </div>
                  )}
                  <img src={uploadedImage || Brand} alt="Brand Logo" />
                </div>
                <div className="logo_uploader">
                  <label
                    htmlFor="fileInput"
                    style={{
                      display: "inline-block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img src={UploaderIcon} alt="Brand Logo Uploader" />
                    <p>Upload New Logo</p>
                  </label>

                  <input type="file" style={{ display: "none" }} id="fileInput" accept=".png, .jpg, .jpeg" name="websiteLogo" onChange={handleImageUpload} />
                  <div className="branding-error">{formErrors.websiteLogo}</div>
                </div>
              </div>

              <div className="row align-items-center  search-custom brand_alt">
                <label htmlFor="sortOrder" className="col-form-label col-auto labelling">
                  Logo alternate text
                </label>
                <div className="col-auto">
                  <input type="text" id="urltext" name="logoAlternateText" className="form-control" value={brandingInfo?.logoAlternateText} onChange={handleChange} />
                  <div className="branding-error">{formErrors.logoAlternateText}</div>
                </div>
              </div>
            </div>

            <div className="center_border"></div>

            <div className="company_links d-flex title_bar_setting ">
              <div className="left_link_comp">
                <div className="round_checks">
                  <h3>Logo Link</h3>
                  <p className="mtset">Set your logo to link to your home page or any link you like.</p>
                  <p className="logo_link_to">Link your logo to:</p>
                  <div className="allchecks">
                    <label className="custom-checkbox-container">
                      <input
                        type="radio"
                        className="custom-checkbox"
                        id="Not linked"
                        name="logoLink"
                        value="Not linked"
                        onChange={(e) => {
                          handleChange(e);
                          setCustomURLSelected(false);
                        }}
                        checked={brandingInfo?.logoLink === "Not linked"}
                      />
                      <span className="checkmark"></span>
                      <span className="spannerr">Logo not linked</span>
                    </label>
                    <label className="custom-checkbox-container">
                      <input
                        type="radio"
                        className="custom-checkbox"
                        id="homepage"
                        name="logoLink"
                        value="/"
                        onChange={(e) => {
                          handleChange(e);
                          setCustomURLSelected(false);
                        }}
                        checked={brandingInfo?.logoLink === "/"}
                      />
                      <span className="checkmark"></span>
                      <span className="spannerr">Your Home Page</span>
                    </label>

                    <label className="custom-checkbox-container last_check">
                      <input
                        type="radio"
                        className="custom-checkbox"
                        id="customUrl"
                        name="logoLink"
                        value=""
                        onChange={(e) => {
                          handleChange(e);
                          setCustomURLSelected(true);
                        }}
                        checked={brandingInfo?.logoLink !== "Not linked" && brandingInfo?.logoLink !== "/" && customURLSelected}
                      />
                      <span className="checkmark"></span>
                      <span className="spannerr">Custom URL</span>
                      {customURLSelected ? <input type="text" className="form-control" value={brandingInfo?.logoLink} name="logoLink" onChange={handleChange} placeholder="Custom URL" /> : <input type="text" className="form-control" value="" disabled name="logoLink" />}
                    </label>
                    <div className="branding-error">{formErrors.logoLink}</div>
                  </div>
                </div>
              </div>
              <div className="right_link_comp">
                <h3>Fav Icon</h3>
                <div className="favicon_uploader">
                  {uploadedFavicon ? (
                    <>
                      <div
                        className="close_fav"
                        onClick={() => {
                          // setFavicon(null);
                          setBrandingInfo({
                            ...brandingInfo,
                            favicon: null,
                          });
                          setUploadedFavicon(null);
                        }}
                      >
                        <img src={Close} alt="remove" />
                      </div>
                      <img src={uploadedFavicon} alt="Uploaded Fav Icon" className="favicon_image" />
                    </>
                  ) : (
                    <label htmlFor="faviconInput" className="favicon_label">
                      <img src={UploaderIcon} alt="Favicon Uploader" />
                      <p>Upload Fav Icon</p>
                    </label>
                  )}
                  <input name="favicon" type="file" accept=".png, .jpg, .jpeg" onChange={handleImageUpload} className="favicon_input" id="faviconInput" />
                  <div className="branding-error">{formErrors.favicon}</div>
                </div>

                <p>Your favicon is the icon that shows in browser tabs when someone is viewing one of your website pages. Recommended size is 16x16 or 32x32 pixels.</p>
              </div>
            </div>

            <div className="center_border"></div>

            <div className="company_links d-flex title_bar_setting ">
              <div className="left_link_comp">
                <div className="round_checks">
                  <h3>Office Location</h3>
                  <p className="mtset">The office location defined here is used for display purposes on your website.</p>

                  <div className="contant_location_form kailow">
                    <div className="row">
                      <div className="col-lg-6">
                        <label>Address</label>
                        <input type="text" name="address" className="form-control" value={brandingInfo.address} placeholder="Address" onChange={handleChange} />
                        <div className="branding-error">{formErrors.address}</div>
                      </div>
                      <div className="col-lg-6">
                        <label>City</label>
                        <input type="text" name="city" className="form-control" value={brandingInfo.city} placeholder="City" onChange={handleChange} />
                        <div className="branding-error">{formErrors.city}</div>
                      </div>
                      <div className="col-lg-6">
                        <label>State</label>
                        <input type="text" name="state" className="form-control" value={brandingInfo.state} placeholder="State" onChange={handleChange} />
                        <div className="branding-error">{formErrors.state}</div>
                      </div>
                      <div className="col-lg-6">
                        <label>Zip Code</label>
                        <input
                          type="text"
                          name="zipCode"
                          className="form-control"
                          value={brandingInfo.zipCode}
                          placeholder="Zipcode"
                          onChange={(e) => {
                            if (e.target.value.length <= 5) {
                              handleChange(e);
                            }
                          }}
                          onKeyDown={handleZipCodeKeyDown}
                        />
                        <div className="branding-error">{formErrors.zipCode}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="right_link_comp">
                <h3>Contact Information</h3>
                <p>This information will appear on your website widgets.</p>

                <div className="contant_location_form hallow yellow">
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Phone No.</label>
                      <input
                        type="text"
                        id="phone"
                        className="form-control"
                        pattern="[0-9]*"
                        maxLength={15}
                        name="phone"
                        value={brandingInfo.phone}
                        placeholder="Phone No."
                        onChange={(e) => {
                          if (e.target.value.length <= 15) {
                            handleChange(e);
                          }
                        }}
                      />
                      <div className="branding-error">{formErrors.phone}</div>
                    </div>

                    <div className="col-lg-6">
                      <label>Fax Id</label>
                      <input type="text" id="faxId" name="faxId" maxLength={15} className="form-control" value={getFaxIdFormat(brandingInfo.faxId)} placeholder="Fax Id" onChange={handleChange} />
                      <div className="branding-error">{formErrors.faxId}</div>
                    </div>

                    <div className="col-lg-6">
                      <label>Email</label>
                      <input type="email" id="email" name="email" className="form-control" value={brandingInfo.email} placeholder="Email" onChange={handleChange} />
                      <div className="branding-error">{formErrors.email}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="two_btns">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(true);
                }}
                className="btn update_btn"
              >
                Submit
              </button>
            </div>
          </form>
        )}
        <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={handleSave} message="Do you really want to save the Branding informations?" img={Shield} leftButton="Cancel" rightButton="Save" />
      </div>
    </BaseLayout>
  );
}

export default Branding;
