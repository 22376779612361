// ProtectedRoute.js
import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedRoutewithoutSubscribed = () => {
  const isAuthenticated = useSelector((state) => state.regToken);
  console.log('isAuthenticated',isAuthenticated)
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export const ProtectedRoute = () => {
  const isAuthenticated = useSelector((state) => state.regToken);
  const isSubscribed = useSelector((state) => state.subscribed);
console.log(isAuthenticated)
console.log(isSubscribed)
  return isAuthenticated && isSubscribed ? <Outlet /> : <Navigate to="/login" replace />;
};
