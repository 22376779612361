import React from "react";
import DeleteLarge from "../../assets/big-cross.svg";

const PageNotFound = () => {
  return (
    <div className=" page-not-found">
      {/* <img src={DeleteLarge} alt="" /> */}
      <h1>404</h1>
      <h3>Page Not Found</h3>
      <p className="mt-3">Sorry, the page you are looking for could not be found.</p>
    </div>
  );
};

export default PageNotFound;
