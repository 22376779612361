import React, { useState, useEffect, useRef } from "react";
import "./Realtor.css";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import { LineChart, Line } from "recharts";
import { useParams } from "react-router-dom";
import Arrow from "../../assets/upper-arrow.svg";
import BaseLayout from "../../layouts/BaseLayout";
import ClipLoader from "react-spinners/ClipLoader";
import ProfileData from "./components/ProfileData";
import Verified from "../../assets/verified-logo.svg";
import { getInitials, handleDropdownClose } from "../../utils/utils";
import ActiveInactiveRealtorModal from "../ModalMessage/ActiveInactiveRealtorModal";

const blockData = [
  {
    iconClass: "icon-vector4",
    number: 0,
    text: "Revenue",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 400 }],
    color: "#007AFF",
    blockClass: "block-color1",
  },
  {
    iconClass: "icon-vector0",
    number: 0,
    text: "Leads",
    chartData: [{ uv: 200 }, { uv: 100 }, { uv: 200 }, { uv: 310 }, { uv: 20 }, { uv: 325 }, { uv: 800 }],
    color: "#FF9600",
    blockClass: "block-color2",
  },
  {
    iconClass: "icon-Vector1",
    number: 0,
    text: "Total Transction",
    chartData: [{ uv: 300 }, { uv: 400 }, { uv: 250 }, { uv: 310 }, { uv: 280 }, { uv: 225 }, { uv: 1200 }],
    color: "#28AC47",
    blockClass: "block-color3",
  },
  {
    iconClass: "icon-Vector2",
    number: 0,
    text: "Active Listings",
    chartData: [{ uv: 300 }, { uv: 500 }, { uv: 100 }, { uv: 600 }, { uv: 50 }, { uv: 100 }, { uv: 400 }],
    color: "#ED4949",
    blockClass: "block-color4",
  },
  {
    isDifferent: true,
  },
];

const options = ["Active", "Inactive"];

const RealtordownOptions = [
  { type: "heading", content: "Payment" },
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

const Profile = () => {
  const { id } = useParams();
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [leadsCount, setLeadsCount] = useState(0);
  const [modalType, setModalType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [realtorword, setRealtorword] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [realtorStatus, setRealtorStatus] = useState("");
  const [activeListings, setActiveListings] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptionRealtor, setSelectedOptionRealtor] = useState(" ");
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const [realtorData, setRealtorData] = useState({
    name: "",
    tagline: "",
    description: "",
    status: "",
    phoneNumber: "",
    address: "",
    email: "",
    license: "",
    isVerified: "",
    serviceArea: "",
    propertyCount: "",
    image: "",
    imageURL: "",
  });

  const updatedBlockData = [...blockData];
  updatedBlockData[3].number = activeListings;
  updatedBlockData[2].number = transactionCount;
  updatedBlockData[1].number = leadsCount;
  updatedBlockData[0].number = totalPaidAmount?.toFixed(2);
  updatedBlockData[0].text = realtorword;

  useEffect(() => {
    setLoading(true);
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const queryValue = RealtordownOptions.find((item) => item.content === selectedOptionRealtor)?.value || "";
        const response = await axios.get(Url.BASEURL + `/broker/realtor-transaction/${id}?page=${currentPage}&status=${queryValue}`, config);
        if (response.data.success) {
          setTransactions(response.data.allTransactions);
          setCurrentPage(response.data.currentPage);
          setTotalPages(response.data.totalPages);
        } else {
          console.error("Failed to fetch transactions:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [currentPage, selectedOptionRealtor]);

  const handleOptionSelect = (option) => {
    setSelectedOptionRealtor(option);
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const onOptionClicked = (option) => {
    // if (option === "Active" || option === "Inactive") {
    setModalType(option.toLowerCase());
    setShowModal(true);
    setSelectedOption(option);
    setIsOpen(false);
    // } else {
    //   setSelectedOption(option);
    //   setIsOpen(false);
    // }
  };

  const fetchRealtorData = async () => {
    try {
      const response = await axios.get(Url.BASEURL + `/broker/get-realtor/${id}`, config);
      setRealtorData(response.data.realtorWithImageURL);
      const status = response.data.realtorWithImageURL?.status;
      setSelectedOption(status == 1 ? "Active" : realtorData.status == 0 ? "Inactive" : "Select an option");
      setActiveListings(response.data.propertyCount);
      setTransactionCount(response.data.transactionCount);
      setLeadsCount(response.data.leadsCount);
      setTotalPaidAmount(response.data.totalPaidAmount);
      setRealtorword(response.data.realtorword);
    } catch (error) {
      console.error("Error fetching realtor data:", error);
    }
  };

  useEffect(() => {
    fetchRealtorData();
  }, [id, selectedOption]);

  const PostsDropdown = () => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
    }
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
      setIsOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  //API for change the realtor status
  const handleModalButtonClick = async () => {
    const newStatus = modalType === "active" ? "Active" : "Inactive";
    try {
      setShowModal(false);
      setIsOpen(false);
      setModalType(null);
      setSelectedOption(newStatus);
      if (id) {
        await handleConfirmStatusChange(newStatus);
      }
    } catch (error) {
      console.error("Error on Modal Button Click:", error);
    }
  };

  const handleConfirmStatusChange = async (newStatus) => {
    if (id) {
      const status = newStatus === "Active" ? 1 : 0;
      axios
        .post(Url.BASEURL + `/broker/change-realtor-status`, { realtorId: id, status: status }, config)
        .then((res) => console.log("res", res))
        .catch((err) => console.error("Error on Updating status:", err));
    }
  };

  useEffect(() => {
    if (selectedOption === "Active") {
      setRealtorStatus("Active");
    } else if (selectedOption === "Inactive") {
      setRealtorStatus("Inactive");
    }
  }, [selectedOption]);

  return (
    <BaseLayout>
      <div className="main_flexing ">
        <div className="sub_flexing d-flex">
          {blockData.map((block, index) =>
            block.isDifferent ? (
              <div className={`custom_structure ${block.blockClass}`} key={index}>
                <div className="new-block">
                  <h2>Realtor Status</h2>
                  <div ref={dropdownRef} className="custom-dropdown">
                    <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
                      {selectedOption || "Select an option"}
                      <img src={Arrow} alt="Dropdown Arrow" className="dropdown-arrow" onChange={(e) => setSelectedOption(e.target.value)} />
                    </div>
                    {isOpen && (
                      <div className="dropdown-list-container">
                        <ul className="dropdown-list">
                          {options.map((option, idx) => (
                            <li key={idx} onClick={() => onOptionClicked(option)}>
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <ActiveInactiveRealtorModal
                  type={modalType === "active" ? "Active" : "Inactive"}
                  show={showModal}
                  onClose={() => setShowModal(false)}
                  onConfirm={() => {
                    fetchRealtorData();
                    handleModalButtonClick();
                  }}
                />
              </div>
            ) : (
              <div className={` ${block.blockClass} kamla`} key={index}>
                <div className="block">
                  <div className="icon-bg">
                    <span className={block.iconClass}></span>
                  </div>
                  <div className="chart-space">
                    <LineChart width={100} height={50} data={block.chartData}>
                      <Line type="monotone" dataKey="uv" stroke={block.color} strokeWidth={3} dot={false} />
                    </LineChart>
                    <p>{block.chartText}</p>
                  </div>
                  <div className="number">{index === 0 ? `$${block.number}` : index === 1 ? `${block.number}` : block.number}</div>
                  <p className="blockp">{block.text}</p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
            </div>
          ) : (
            <div className="mutual_blocks jjj">
              <div className="real_prop_flex d-flex">
                <div className="real_image">
                  <div className="real_image">{realtorData?.image ? <img src={`${Url.IMGURL}/${realtorData?.image}`} alt="user" /> : <div className="teammate-initials">{getInitials(realtorData.firstname + " " + realtorData.lastname)}</div>}</div>
                </div>
                <div className="real_teaxing flex-grow-1">
                  <div className="top_row_real d-flex">
                    <h2>
                      {realtorData.firstname && realtorData.lastname ? realtorData.firstname + " " + realtorData.lastname : realtorData.firstname || "N/A"}
                      <span>{realtorData.profileTagline}</span>
                    </h2>
                  </div>
                  <p>{realtorData.bio ? realtorData.bio : "N/A"}</p>
                </div>
                <div className="del_active d-flex">
                  <div className="delete_icon"></div>
                  <div className="status_view">{realtorData.status == 1 ? "Active" : realtorData.status == 0 ? "Inactive" : null}</div>
                </div>
              </div>
              <div className="contact_realtor">
                <h2>Contact Details</h2>
                <div className="contact_row_flex d-flex">
                  <div className="left_real_contact cr1">{realtorData.phone ? realtorData.phone : "N/A"}</div>
                  <div className="right_real_contact cr2">{realtorData.contactDetails && realtorData.contactDetails.address ? realtorData.contactDetails.address : "N/A"}</div>
                </div>
                <div className="contact_row_flex d-flex">
                  <div className="left_real_contact cr3">{realtorData.email ? realtorData.email : "N/A"}</div>
                  <div className="right_real_contact cr4">
                    License : {realtorData.contactDetails && realtorData.contactDetails.license ? realtorData.contactDetails.license : "N/A"}{" "}
                    <span className="verification">
                      {realtorData.isVerified && <img src={Verified} alt="verified" />}
                      verified
                    </span>
                  </div>
                </div>
              </div>
              <div className="contact_realtor ser_areas_realtor">
                <h2>Service Area</h2>
                <div className="area_listing_realtor d-flex">
                  {realtorData?.serviceArea && realtorData?.serviceArea.length > 0 ? (
                    realtorData.serviceArea.map((area, index) => (
                      <div className="list_con" key={index}>
                        {area}
                      </div>
                    ))
                  ) : (
                    <div className="list_con">N/A</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ProfileData
        loading={loading}
        transactions={transactions}
        totalPages={totalPages}
        onSetSelectedOptionRealtor={(value) => {
          setSelectedOptionRealtor(value);
        }}
        onSetSelectedOption={(value) => setSelectedOption(value)}
      />
    </BaseLayout>
  );
};
export default Profile;
