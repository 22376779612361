import React, { useState, useEffect } from "react";
import "./BrokerLogin.css";
import axios from "axios";
import Slider from "rc-slider";
import Url from "../Element/Api";
import Select from "react-select";
import "rc-slider/assets/index.css";
import Logo from "../../assets/logo.png";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/useAuth";
import Edit from "../../assets/pencil.svg";
import { useNavigate } from "react-router-dom";
import { exceptThisSymbols, getFaxIdFormat } from "../../utils/utils";
import { setUserType, setProfileImage } from "../../Redux/Action/Authaction";

function BrokerProfileSetup() {
  const [config, configFormData] = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [value, setValue] = useState(10);
  const [inputValue, setInputValue] = useState(0);
  const [imageFile, setImageFile] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedCities, setSelectedCities] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  const [agentType, setAgentType] = useState("independent");
  const [numberOfAgentsError, setNumberOfAgentsError] = useState("");
  const [userInputForprofileSetup, setUserInputForprofileSetup] = useState({
    companyName: "",
    bio: "",
    faxId: "",
    address: "",
    license: "",
    numberOfAgent: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleChangeForProfileSetup = (e) => {
    const { name, value } = e.target;
    const onlyAlphabets = e.target.value.replace(/[^a-zA-Z ]/g, "");
    const onlyNumber = e.target.value.replace(/[^0-9 ]/, "");
    const numberAlphabets = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    const valueToSet = name == "companyName" || name == "city" || name == "state" ? onlyAlphabets : name == "zipCode" ? onlyNumber : name == "faxId" || name == "license" ? numberAlphabets : value;

    setError((prevErrors) => ({
      ...prevErrors,
      [name]: "",
      numberOfAgentsError: "",
    }));
    setUserInputForprofileSetup({
      ...userInputForprofileSetup,
      [name]: valueToSet,
    });
  };

  const handleCityChange = (selectedOptions) => {
    setError((prevErrors) => ({
      ...prevErrors,
      serviceArea: "",
    }));
    if (selectedOptions.some((option) => option.value === "all")) {
      const allCityValues = cityOptions.map((option) => option.value);
      setSelectedCities(allCityValues);
    } else {
      const valuesArray = selectedOptions.map((option) => option.value);
      setSelectedCities(valuesArray);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setError((prevErrors) => ({
        ...prevErrors,
        image: "",
      }));
      // formData.append("image", file);
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // function for profile setup of broker
  const submitGetWebsite = (e) => {
    e.preventDefault();

    if (agentType === "multi" && value && inputValue <= 0) {
      setNumberOfAgentsError("Number of agents cannot be zero for Multi Agents");
      return;
    }

    formData.append("image", imageFile);
    formData.append("companyName", userInputForprofileSetup.companyName);
    formData.append("bio", userInputForprofileSetup.bio);
    formData.append("faxId", userInputForprofileSetup.faxId);
    formData.append("address", userInputForprofileSetup.address);
    formData.append("license", userInputForprofileSetup.license);
    formData.append("city", userInputForprofileSetup.city);
    formData.append("state", userInputForprofileSetup.state);
    formData.append("zipCode", userInputForprofileSetup.zipCode);
    formData.append("numberOfAgents", agentType === "independent" ? 0 : value);
    formData.append("agentType", agentType === "independent" ? 0 : 1);
    formData.append("serviceArea", selectedCities);
    axios
      .post(Url.BASEURL + "/broker/add-details", formData, configFormData)
      .then((value) => {
        console.log("value", value.data);
        if (value.data.success === true) {
          dispatch(setUserType(value.data.userType));
          localStorage.setItem("userType", value.data?.userType);
          dispatch(setProfileImage(Url.IMGURL + value.data.broker.image));
          setFormData(new FormData());
          // Redirect based on userType
          if (value.data.userType == 1) {
            navigate("/payment");
          } else if (value.data.userType == 0) {
            // navigate("/realtor-dashboard");
            navigate("/");
          }
        } else {
          // Handle successful response with a different condition, if needed
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.errors) {
          setFormData(new FormData());
          setError(err.response.data.errors);
        } else {
          setError("An error occurred while processing your request.");
        }
      });
  };

  const submitBranding = async (e) => {
    e.preventDefault();

    try {
      formData.append("image", imageFile);
      formData.append("companyName", userInputForprofileSetup.companyName);
      formData.append("bio", userInputForprofileSetup.bio);
      formData.append("faxId", userInputForprofileSetup.faxId);
      formData.append("address", userInputForprofileSetup.address);
      formData.append("city", userInputForprofileSetup.city);
      formData.append("state", userInputForprofileSetup.state);
      formData.append("zipCode", userInputForprofileSetup.zipCode);
      formData.append("numberOfAgents", agentType === "independent" ? 0 : value);
      formData.append("agentType", agentType === "independent" ? 0 : 1);
      formData.append("serviceArea", selectedCities);

      const response = await axios.post(Url.BASEURL + "/broker/add-details", formData, configFormData);
      console.log("value", response.data);

      if (response.data.success === true) {
        dispatch(setUserType(response.data.userType));
        dispatch(setProfileImage(Url.IMGURL + response.data.broker.image));
        setFormData(new FormData());
        // Redirect based on userType
        if (response.data.userType == 1) {
          navigate("/payment");
        } else if (response.data.userType == 0) {
          navigate("/realtor-dashboard");
        }
      } else {
        // Handle successful response with a different condition, if needed
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        setFormData(new FormData());
        setError(err.response.data.errors);
      } else {
        setError("An error occurred while processing your request.");
      }
    }
  };

  const onSliderChange = (newValue) => {
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 2000) {
      newValue = 2000;
    }

    setValue(newValue);
    setInputValue(newValue);
  };

  const onInputChange = (e) => {
    // Constrain input value within the slider range
    // let newValue = event.target.value;
    // if (newValue > 2000) {
    //   newValue = 2000;
    // } else if (newValue < 0) {
    //   newValue = 0;
    // }

    let newValue = parseInt(e.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 2000) {
      newValue = 2000;
    }
    newValue = newValue.toString();
    setValue(newValue);
    setInputValue(newValue);
  };
  /* Range-slider */

  /* hide show */
  useEffect(() => {
    // Fetch cities from the API
    const fetchCities = async () => {
      try {
        const response = await axios.get(Url.BASEURL + "/broker/get-all-cities");
        const cities = response.data; // Assuming the API response contains an array of cities
        const formattedCities = cities.map((city) => ({ value: city, label: city }));
        setCityOptions(formattedCities);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: "478px !important",
    }),
  };

  const selectAllCities = () => {
    const allCityValues = cityOptions.map((option) => option.value);
    setSelectedCities(allCityValues);
  };

  const handleZipCodeKeyDown = (e) => {
    if (e.target.value.length >= 5 && e.key !== "Backspace") {
      e.preventDefault();
    }

    exceptThisSymbols.includes(e.key) && e.preventDefault();
  };

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container_bg custom_principal_page">
        <div className="row ">
          <div className="col-lg-12">
            <div className="principal_broker">
              <div className="top_principal d-flex">
                <div className="left_principal image_uploader">
                  <img src={imagePreview === "" ? "https://via.placeholder.com/150" : imagePreview} alt="Uploaded" className="responsive_image" />
                  <input type="file" onChange={handleImageChange} style={{ display: "none" }} id="imageInput" accept=".png, .jpg, .jpeg" />
                  <label htmlFor="imageInput" className="edit-icon-image">
                    <img src={Edit} alt="" role="button" />
                  </label>
                  {error.image && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.image}</p>}
                </div>
                <div className="right_principal title_bar_setting">
                  <div className="single_entry">
                    <label>
                      Organisation Name*
                      <input name="companyName" value={userInputForprofileSetup.companyName} onChange={handleChangeForProfileSetup} type="text" className="form-control light-grey-input" placeholder="Enter your Organisation" style={{ textTransform: "capitalize" }} />
                      {error.companyName && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.companyName}</p>}
                    </label>
                  </div>
                  <div className="principal_text_area">
                    <h3>Bio data* </h3>
                    <div className="form-group">
                      <textarea name="bio" maxLength={150} value={userInputForprofileSetup.bio} onChange={handleChangeForProfileSetup} id="ampHeaderTitle" className="form-control area_control" placeholder="Enter your bio data" />
                      {error.bio && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.bio}</p>}
                    </div>
                  </div>
                </div>
                <div className="contact_detail title_bar_setting">
                  <h3>Contact Details</h3>
                  <div className="contract_rows d-flex">
                    <div className="left_contact_dt">
                      <div className="single_entry">
                        <label>
                          Fax ID*
                          <input name="faxId" maxLength={15} value={getFaxIdFormat(userInputForprofileSetup.faxId)} onChange={handleChangeForProfileSetup} type="text" className="form-control" placeholder="Enter your Fax ID: " />
                          {error.faxId && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.faxId}</p>}
                        </label>
                      </div>
                    </div>
                    <div className="right_cont_dt">
                      <div className="single_entry">
                        {/* <label>
                          Skype ID*
                          <input name="skypeId" value={userInputForprofileSetup.skypeId} onChange={handleChangeForProfileSetup} type="text" className="form-control" placeholder="Enter your Skype ID: " />
                          {error.skypeId && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.skypeId}</p>}
                        </label> */}

                        <label>
                          License No.*
                          <input name="license" maxLength={15} value={userInputForprofileSetup.license} onChange={handleChangeForProfileSetup} type="text" className="form-control" placeholder="License no:" />
                          {error.license && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.license}</p>}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="contract_rows d-flex">
                    <div className="left_contact_dt ctbt">
                      <div className="single_entry">
                        <label>
                          Address*
                          <input name="address" value={userInputForprofileSetup.address} onChange={handleChangeForProfileSetup} type="text" className="form-control" placeholder="Enter your Address here" />
                          {error.address && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.address}</p>}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="contract_rows d-flex">
                    <div className=" only_forms_set d-flex ctbt">
                      <div className="single_entry">
                        <label>
                          City*
                          <input type="text" name="city" className="form-control" placeholder="City" value={userInputForprofileSetup.city} onChange={handleChangeForProfileSetup} />
                          {error.city && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.city}</p>}
                        </label>
                      </div>
                      <div className="single_entry">
                        <label>
                          State*
                          <input type="text" name="state" className="form-control" placeholder="State" value={userInputForprofileSetup.state} onChange={handleChangeForProfileSetup} />
                          {error.state && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.state}</p>}
                        </label>
                      </div>
                      <div className="single_entry">
                        <label>
                          Zip Code*
                          <input
                            type="number"
                            name="zipCode"
                            className="form-control"
                            placeholder="Zip Code"
                            value={userInputForprofileSetup.zipCode}
                            onChange={(e) => {
                              if (e.target.value.length <= 5) {
                                handleChangeForProfileSetup(e);
                              }
                            }}
                            onKeyDown={handleZipCodeKeyDown}
                          />
                          {error.zipCode && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.zipCode}</p>}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="selectors">
                    <h3>Number of Agents</h3>
                    <div className="contract_rows d-flex">
                      <div className="left_contact_dt">
                        <label className="custom-radio-container">
                          Independent
                          <input type="radio" name="agentType" className="custom-radio" onChange={() => setAgentType("independent")} checked={agentType === "independent"} />
                          <span className="radio-checkmark"></span>
                        </label>
                      </div>
                      <div className="right_cont_dt d-flex">
                        <label className="custom-radio-container">
                          Multi Agents
                          <input type="radio" name="agentType" className="custom-radio" onChange={() => setAgentType("multi")} checked={agentType === "multi"} />
                          <span className="radio-checkmark"></span>
                        </label>
                        {agentType === "multi" && (
                          <div className="hidden_selector">
                            <div className="chauka">
                              <input type="number" value={inputValue} onChange={onInputChange} className="form-control" />
                              <Slider min={0} max={2000} value={value} onChange={onSliderChange} className="my-slider" />
                            </div>
                            {numberOfAgentsError && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{numberOfAgentsError}</p>}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="selectors">
                  <h5>Service Area</h5>
                  <Select
                    isMulti
                    name="cities"
                    menuPlacement="top"
                    menuPosition="fixed"
                    closeMenuOnSelect={false}
                    options={[{ value: "all", label: "Select All Cities" }, ...cityOptions]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={customStyles}
                    value={selectedCities.map((city) => ({ value: city, label: city }))}
                    onChange={(selectedOptions) => {
                      handleCityChange(selectedOptions);
                    }}
                  />
                  {error.serviceArea && <p style={{ color: "red", fontWeight: "400", marginLeft: "10px", fontSize: "13px" }}>{error.serviceArea}</p>}
                </div>
                <div className="selector_btn">
                  <button onClick={submitGetWebsite} className="btn update_btn">
                    Explore Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BrokerProfileSetup;
