import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Blog/BlogPages.css";
import Url from "../Element/Api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAuth from "../../hooks/useAuth";
import DatePicker from "react-datepicker";
import Close from "../../assets/closer.png";
import BaseLayout from "../../layouts/BaseLayout";
import { useNavigate, useParams } from "react-router-dom";
import defaultUploaderIcon from "../../assets/uploader.svg";
import { editorStyle, formats, modules } from "../../utils/utils";

function EditBlog() {
  const navigate = useNavigate();
  const { blog_id } = useParams();
  const id = blog_id;
  const [image, setImage] = useState("");
  const [config, configFormData] = useAuth();
  const [publish, setPublish] = useState(false);
  const [manageTags, setManageTags] = useState([]);
  const [description, setDescription] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [allowComment, setAllowComment] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  var selectedTagsString = selectedTags.join(", ");
  const [blogData, setBlogData] = useState({
    title: "",
    Author: "",
    description: description,
    tags: "",
    image: "",
    meta_title: "",
    meta_description: "",
    allow_comments: publish,
    publish_post: allowComment,
  });

  const handleDescriptionOnChange = (e) => {
    setDescription(e);
  };

  const toggleEdit = () => {
    setIsReadOnly(!isReadOnly);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImage(event.target.files[0]);

    if (file && file.type.substr(0, 5) === "image") {
      setBlogData({
        ...blogData,
        imageURL: URL.createObjectURL(file),
        image: event.target.files[0],
      });
    } else {
      // setImage(null);
      setBlogData({
        ...blogData,
        imageURL: null,
        image: null,
      });
    }
  };

  const handleFeaturedRemove = () => {
    setBlogData({
      ...blogData,
      imageURL: null,
    });
  };

  const handleCheckboxChange = (tag) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
    setBlogData({
      ...blogData,
      ["tags"]: selectedTags.join(","),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(Url.BASEURL + `/blog/${id}`, blogData, configFormData)
      .then((response) => {
        if (response.data.success == true) {
          navigate("/blogs");
        }
      })
      .catch((error) => {
        console.error("Error updating blog", error);
      });
  };

  useEffect(() => {
    axios
      .get(Url.BASEURL + `/blog/${id}`, config)
      .then((value) => {
        const res = value?.data?.data;
        setBlogData(res);
        setSelectedTags(res?.tags);
        setPublish(res?.publish_post);
        setAllowComment(res?.allow_comments);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(Url.BASEURL + `/manage-tag/all`, config)
      .then((value) => {
        setManageTags(value.data.tags);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOnCheckBoxChange = (e, value) => {
    const { name } = e.target;
    if (name === "publish_post") {
      setPublish(value);
    } else {
      setAllowComment(value);
    }
    setBlogData({
      ...blogData,
      [name]: value,
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setBlogData({
      ...blogData,
      [name]: value,
    });
  };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages single_posts table_view_only">
        <h2>Blog Posts</h2>

        <p>Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>

        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="post-data title_bar_setting ">
            <div className="post_detail d-flex ">
              <div className="left_post_detail">
                <div className="single_entry">
                  <label>
                    Title
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={blogData?.title}
                      // onChange={(e) => setBlogData(e.target.value)}
                      onChange={handleOnChange}
                      placeholder="Enter your title here"
                    />
                  </label>
                  <div className="edit_link d-flex">
                    <div className="left_link_edit">
                      <input type="text" className="edit_control" defaultValue="https://www.allprofessionalsre.com/banks-real-estate-all-professional-real-estates" readOnly={isReadOnly} onBlur={() => setIsReadOnly(true)} />
                    </div>
                    <div className="edit_icon" onClick={toggleEdit}>
                      <span className="icon-Pencil1"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right_post_detail">
                <div className="single_entry">
                  <label>
                    Author Name
                    <input
                      type="text"
                      className="form-control"
                      value={blogData.Author}
                      name="Author"
                      // onChange={(e) => setBlogData(e.target.value)}
                      onChange={handleOnChange}
                      placeholder="Enter Author Name"
                    />{" "}
                  </label>
                </div>
              </div>
            </div>

            <div className="editor" style={editorStyle}>
              <ReactQuill
                name="description"
                value={blogData.description}
                // onChange={(html) => setDescription(html)}
                onChange={handleDescriptionOnChange}
                modules={modules}
                formats={formats}
              />
            </div>

            <div className="tefe d-flex">
              <div className="l_tefe">
                {/* full-dropdown */}
                <div className="tags_selector">
                  <div className="single_entry select_single">
                    <label>
                      Tags
                      <input
                        type="text"
                        className="form-control"
                        id="tags"
                        name="selectedTagsString"
                        value={selectedTagsString}
                        placeholder="Select Tags for this Post"
                        readOnly
                        onClick={() => {
                          setIsDropdownOpen(!isDropdownOpen);
                        }}
                      />
                    </label>

                    {isDropdownOpen && (
                      <div className="tags_dropdown">
                        {manageTags.map((tag) => (
                          <div className="list_checks" key={tag.name}>
                            <label className="custom-checkbox-container">
                              <input type="checkbox" className="custom-checkbox" checked={selectedTags.includes(tag.tag.name) ? true : false} onChange={() => handleCheckboxChange(tag.tag.name)} />
                              <span className="checkmark"></span>
                              {tag.tag.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {/* full-dropdown */}
                <div className="date_published">
                  <div className="single_entry date_icon">
                    <label>
                      Publish Date and Time
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeSelect dateFormat="Pp" className="all_dates" />
                    </label>
                    {/* <label>
                          Published Date and Time
                          <input
                          type="text"
                          className="form-control"
                          readOnly={isReadOnly}
                          placeholder="07-10-23, 10:00am"
                          />
                        </label> */}
                  </div>
                </div>

                <div className="two labels_checks d-flex">
                  <label className="custom-checkbox-container">
                    <input type="checkbox" className="custom-checkbox" name="allow_comments" checked={allowComment} onChange={(e) => handleOnCheckBoxChange(e, allowComment ? false : true)} />
                    <span className="checkmark"></span>
                    Allow Comments
                  </label>
                  <label className="custom-checkbox-container mdl">
                    <input type="checkbox" className="custom-checkbox" name="publish_post" checked={publish} onChange={(e) => handleOnCheckBoxChange(e, publish ? false : true)} />
                    <span className="checkmark"></span>
                    Publish this post
                  </label>
                </div>
              </div>
              <div className="r_tefe">
                <div className="right_link_comp">
                  <h3>Featured Image</h3>
                  <div className="favicon_uploader">
                    {blogData.imageURL ? (
                      <>
                        <div className="close_fav" onClick={handleFeaturedRemove}>
                          <img src={Close} alt="remove" />
                        </div>
                        <img src={blogData.imageURL} alt="Uploaded featured Icon" className="Featured_image" />
                      </>
                    ) : (
                      <label htmlFor="featured" className="favicon_label">
                        <img src={defaultUploaderIcon} alt="Featured Uploader" />
                        <p>Upload Featured Image</p>
                      </label>
                    )}
                    <input
                      type="file"
                      // accept="image/*"
                      // onChange={handleFeaturedUpload}

                      onChange={handleFileChange}
                      className="favicon_input"
                      id="featured"
                      name="image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="center_border"></div>

            <div className="tags_set">
              <div className="title_bar_setting">
                <h3>Meta Title</h3>

                <div className="form-group">
                  <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                  <div className="d-flex custom_fcontrol">
                    <div className="left_farea">
                      {" "}
                      <input
                        type="text"
                        id="metatitle"
                        className="form-control"
                        value={blogData.meta_title}
                        name="meta_title"
                        placeholder="Enter your meta title here"
                        //   onChange={(e) => setBlogData(e.target.value)}
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className="right_farea">Should be no more than 8-10 words</div>
                  </div>
                </div>
              </div>
              <div className="title_bar_setting second_input">
                <h3>Meta Description</h3>

                <div className="form-group">
                  <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                  <div className="d-flex custom_fcontrol">
                    <div className="left_farea">
                      {" "}
                      <input
                        type="text"
                        id="metaDescription"
                        className="form-control"
                        value={blogData.meta_description}
                        name="meta_description"
                        placeholder="Enter your meta description here"
                        //   onChange={(e) => setBlogData(e.target.value)}
                        onChange={handleOnChange}
                      />
                    </div>
                    <div className="right_farea">Should be no more than 8-10 words</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="two_btns">
              <button onClick={handleSubmit} type="submit" className="btn update_btn">
                Update Blog
              </button>
              {/* <button className="btn cancel_btn">Draft</button> */}
            </div>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
}

export default EditBlog;
