import "./Realtor.css";
import axios from "axios";
import Url from "../Element/Api";
import Logo from "../../assets/logo.png";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubDomain from "../../assets/sub-domain.svg";

function RealtorSubDomain() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const regtoken = useSelector((state) => state.regToken);
  const [setupFeePaymentStatus, setSetupFeePaymentStatus] = useState(false);

  useEffect(() => {
    const checkSetupFeePaymentStatus = async () => {
      try {
        const response = await axios.get(`${Url.BASEURL}/broker/realtor/get-setup-fee-payment-status`, config);
        if (response.data.success) {
          setSetupFeePaymentStatus(true);
        }
      } catch (error) {
        console.error("Error checking setup fee payment status:", error.message);
      }
    };

    checkSetupFeePaymentStatus();
  }, [regtoken]);

  const handleSetSubDomain = async () => {
    try {
      const response = await axios.post(`${Url.BASEURL}/broker/realtor/realtor-subdomain-fee`, {}, config);
      if (response.data.success) {
        window.location.href = response.data.session.url;
      } else {
        console.error("Error setting up subdomain:", response.data.message);
      }
    } catch (error) {
      console.error("Error setting up subdomain:", error.message);
    }
  };

  const handleSkip = () => {
    navigate("/");
  };

  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo">
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container_bg custom_principal_page profile_setup_only">
        <div className="row ">
          <div className="col-lg-12">
            <div className="principal_broker">
              <div className="realtor_sub_domain">
                {setupFeePaymentStatus ? (
                  <>
                    <h2>Welcome to the Subdomain Setup Page</h2>
                    <p>If you choose not to set your subdomain, your profile will remain under the broker's page. While you'll still have access to essential features, you'll miss out on the full range of marketing tools available exclusively to individual realtors.</p>
                    <div className="sub_domain_image">
                      <img src={SubDomain} alt="sub-domain" />
                    </div>

                    <div className="sub_btn">
                      <button className="sub_one" onClick={handleSetSubDomain}>
                        Set Sub Domain
                      </button>
                      <button className="sub_one sub_two_btn" onClick={handleSkip}>
                        Skip
                      </button>
                    </div>
                  </>
                ) : (
                  <p>Setup fee payment is not done.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealtorSubDomain;
