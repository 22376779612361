import React, { useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import Eye from "../../assets/eye.svg";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import Shield from "../../assets/shield.svg";
import { getInitials } from "../../utils/utils";
import ModalMessage from "../ModalMessage/Modal";
import Eyeslash from "../../assets/eye-slash.svg";

const MlsListingData = ({ mls, onSetMlsData }) => {
  const [config] = useAuth();
  const [mlsId, setMlsId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const userType = useSelector((state) => state.userType);
  const [propertyStatus, setPropertyStatus] = useState(null);

  const handleEyeButtonClick = (mlsId, propertyStatus) => {
    setMlsId(mlsId);
    setPropertyStatus(propertyStatus);
    setShowModal(true);
  };

  const handleSave = () => {
    const updatedStatus = !propertyStatus;
    axios
      .put(Url.BASEURL + `/mls/reso/update-status/${mlsId}`, { status: updatedStatus }, config)
      .then(() => {
        onSetMlsData((prevMlsData) => prevMlsData.map((mls) => (mls._id === mlsId ? { ...mls, PropertyStatus: updatedStatus } : mls)));
        setShowModal(false);
      })
      .catch((err) => {
        setShowModal(false);
      });
  };

  return (
    <>
      <tr key={mls._id}>
        <td className="first_t_member">{mls.UnparsedAddress}</td>

        <td className="second_t_member">
          {new Date(mls.OnMarketDate).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}
          <span>&nbsp;&nbsp; - &nbsp;&nbsp;</span>
          {new Date(mls.PropertyRecordUpdateTimestamp).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" })}
        </td>

        <td className="thirld_t_member">
          {mls.Member.length > 0 ? (
            mls.Member.map((member, index) => <div className="teammate-initials">{getInitials(member.MemberFullName)}</div>)
          ) : (
            <div className="d-block" style={{ color: "black" }}>
              N/A
            </div>
          )}
          {/* <span className="members_counting" >+10</span> */}
        </td>

        <td className=" fourth_t_member">
          <div className="d-block">{"$" + parseFloat(mls.CurrentPriceForStatus.$numberDecimal).toLocaleString("en-US")}</div>
        </td>

        {userType == 1 && (
          <td className="font_set fifth_t_member">
            <div className="d-block">
              <div role="button" className="eye-button" onClick={() => handleEyeButtonClick(mls._id, mls.PropertyStatus)}>
                <img src={mls.PropertyStatus ? Eye : Eyeslash} alt="toggle visibility" />
              </div>
            </div>
          </td>
        )}
      </tr>

      <ModalMessage
        show={showModal}
        img={Shield}
        leftButton="Cancel"
        rightButton="Save"
        handleSave={handleSave}
        handleClose={() => {
          setShowModal(false);
        }}
        message="Do you really want to change the listing status?"
      />
    </>
  );
};

export default MlsListingData;
