import { useState } from "react";
import "../LoginRegister/LoginRegister.css";
import LoginLayout from "../../layouts/LoginLayout";
import LoginSection from "../LoginRegister/LoginSection";
import RegisterSection from "../LoginRegister/RegisterSection";

function Step1() {
  const [error, setError] = useState({});
  const [loginError, setLoginError] = useState({});
  const [activeSection, setActiveSection] = useState("login");

  const wovnnAmount = 0.83;
  const finalAmountWithProcessingFee = 119.6;
  const wovnnApplicationFeeInPercentage = parseFloat((wovnnAmount / finalAmountWithProcessingFee) * 100).toFixed(2);

  return (
    <LoginLayout>
      <div className="d-flex log_reg">
        <button
          className={`btn border-end px-4 ${activeSection === "login" ? "active" : ""}`}
          onClick={() => {
            setActiveSection("login");
            setError({});
          }}
        >
          Login
        </button>
        <button
          className={`btn px-4 ${activeSection === "register" ? "active" : ""}`}
          onClick={() => {
            setActiveSection("register");
            setLoginError({});
          }}
        >
          Register
        </button>
      </div>

      <LoginSection activeSection={activeSection} loginError={loginError} onSetLoginError={(err) => setLoginError(err)} />
      <RegisterSection activeSection={activeSection} error={error} onSetError={(err) => setError(err)} />
    </LoginLayout>
  );
}
export default Step1;
