import React from "react";
import "./Listings.css";
import BaseLayout from "../../../layouts/BaseLayout";

function Listings() {
  return (
    <BaseLayout>
      <div className="mutual_blocks listing-tables"></div>
    </BaseLayout>
  );
}

export default Listings;
