import React, { useState } from "react";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";
import DashboardLogoSmall from "../../../assets/ap.png";
import ArrowRight from "../../../assets/left-arrow.png";
import DashboardLogo from "../../../assets/dashboard-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const SidebarRealtor = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isBlogsExpanded, setIsBlogsExpanded] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false);

  const options = [
    { name: "Dashboard", iconClass: "icon-dashboard", link: "/" },
    { name: "Listings", iconClass: "icon-location", link: "/listing-mls" },
    { name: "Leads", iconClass: "icon-magnet", link: "/leads" },
    {
      name: "Blogs",
      iconClass: "icon-blogs",
      link: "#",
      hasBottomBorder: true,
      specialClass: true,
      setting_only: true,
      blog_only: true,
      children: [
        { name: "Posts", link: "/blogs", iconClass: "icon-tag1" },
        {
          name: "Manage Tags",
          link: "/manage-tags",
          iconClass: "icon-document",
        },
      ],
    },
    {
      name: "Transactions",
      iconClass: "icon-document",
      link: "/transactions",
      hasBottomBorder: true,
      specialClass: true,
    },
  ];

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleOptionClick = (e, option) => {
    e.preventDefault();
    if (option.name === "Settings") {
      setIsSettingsExpanded(!isSettingsExpanded);
    } else if (option.name === "Blogs") {
      setIsBlogsExpanded(!isBlogsExpanded);
    }
  };

  return (
    <>
      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isSidebarVisible ? faTimes : faBars} />
      </button>
      <div className={`sidebar ${isCollapsed ? "collapsed" : ""} ${isSidebarVisible ? "visible" : ""}`}>
        <div className="logo main-logo">
          <NavLink to="/">
            <img src={isCollapsed ? DashboardLogoSmall : DashboardLogo} alt="Logo" className={isCollapsed ? "logo-image-small" : "logo-image"} />
          </NavLink>
        </div>
        <ul className="options">
          <div className={`middle_sidebar ${isCollapsed ? "collapsed" : ""}`}>
            {options.map((option, index) => (
              <li
                key={index}
                className={`option ${option.hasBottomBorder ? "border-bottom-class" : ""}
                            ${option.specialClass ? "special-class" : ""} 
    ${option.setting_only ? "setting_only" : ""}
    ${option.blog_only ? "blog_only" : ""}`}
                onClick={(e) => handleOptionClick(e, option)}
              >
                <span className={`option-icon ${option.iconClass}`}></span>
                <NavLink to={option.link} className={`option-link ${isCollapsed ? "collapsed" : ""}`} activeClassName="active">
                  {!isCollapsed && (
                    <>
                      {option.children ? (
                        <>
                          {option.name}
                          <FontAwesomeIcon icon={faChevronDown} className={`icon-chevron-down ${option.name === "Settings" && isSettingsExpanded ? "rotated" : ""} ${option.name === "Blogs" && isBlogsExpanded ? "rotated" : ""}`} />
                        </>
                      ) : (
                        option.name
                      )}
                    </>
                  )}
                </NavLink>

                {option.children && option.name === "Settings" && isSettingsExpanded && !isCollapsed && (
                  <ul className="sub-options">
                    {option.children.map((child, idx) => (
                      <li key={idx} className="sub-option">
                        <span className={child.iconClass}></span>
                        <NavLink to={child.link} activeClassName="active">
                          {child.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}

                {option.children && option.name === "Blogs" && isBlogsExpanded && !isCollapsed && (
                  <ul className="sub-options">
                    {option.children.map((child, idx) => (
                      <li key={idx} className="sub-option">
                        <span className={child.iconClass}></span>
                        <NavLink to={child.link} activeClassName="active">
                          {child.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </div>
        </ul>
        <button className={`collapse-btn btn-custom ${isCollapsed ? "collapsed" : ""}`} onClick={() => setIsCollapsed(!isCollapsed)}>
          <img src={ArrowRight} alt="Collapse Icon" className="arrow-icon" />
          {!isCollapsed && " Collapse"}
        </button>
      </div>
    </>
  );
};

export default SidebarRealtor;
