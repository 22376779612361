import React, { useState } from "react";
import axios from "axios";
import "./LoginRegister.css";
import Url from "../Element/Api";
import Yes from "../../assets/yes.png";
import { Link } from "react-router-dom";
import LoginLayout from "../../layouts/LoginLayout";

function ForgotPassword() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentScreen, setCurrentScreen] = useState("send-otp");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [passwordConfirmError, setPassworsConfirmError] = useState({});
  const [forgotPasswordStage, setForgotPasswordStage] = useState("initial");

  const handleForgotPasswordSubmit = () => {
    axios
      .post(`${Url.BASEURL}/broker/forgot-password`, {
        email: forgotPasswordEmail,
      })
      .then((response) => {
        if (response.status === 200) {
          setCurrentScreen("get-otp");
          setForgotPasswordStage("enterOTP");
        } else {
          setError(response.data.message);
        }
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.message);
        }
      });
  };

  const handleVerifyOTPSubmit = () => {
    const dataToSend = {
      email: forgotPasswordEmail,
      otp: otp,
    };

    axios
      .post(`${Url.BASEURL}/broker/verify-otp`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          setCurrentScreen("change-password");
          setForgotPasswordStage("changePassword");
        }
      })
      .catch((err) => {
        if (err.response) {
          const errorMessage = err.response.data.message;
          setError(errorMessage);
        }
      });
  };

  // function for on save of new password
  const handleResetPasswordSubmit = (e) => {
    e.preventDefault();
    const dataToSend = {
      email: forgotPasswordEmail,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };

    axios
      .post(`${Url.BASEURL}/broker/reset-password`, dataToSend)
      .then((response) => {
        if (response.status === 200) {
          setCurrentScreen("password-changed-successfully");
          setForgotPasswordStage("passwordChanged");
        }
      })
      .catch((err) => {
        const apiErrors = err.response.data.errors;
        setPassworsConfirmError(apiErrors || {});
      });
  };

  return (
    <LoginLayout>
      {/* sent-otp */}
      {currentScreen === "send-otp" && (
        <div className="forgot_password">
          <div className="d-flex log_reg forgeet_only">
            <button className="btn border-end px-4">Forgot Password</button>
          </div>
          <div className="p_text">
            <p>Enter your register email to get Verification Code</p>
          </div>
          <div className="forgot_fields">
            <div className="mb-3">
              <input
                name="email"
                value={forgotPasswordEmail}
                onChange={(e) => {
                  setForgotPasswordEmail(e.target.value);
                  setError("");
                }}
                type="email"
                className="form-control"
                placeholder="Email Address*"
              />
              {error ? (
                <div
                  style={{
                    fontSize: "13px",

                    color: "red",
                  }}
                  className="alert "
                  role="alert"
                >
                  {error}
                </div>
              ) : null}
            </div>

            <button type="submit" className="logreg_btn btn btn-primary forget-btns" onClick={() => handleForgotPasswordSubmit()}>
              Get Verification Code
            </button>
          </div>
        </div>
      )}

      {/* get-otp */}
      {currentScreen === "get-otp" && (
        <div className="forgot_password">
          <div className="d-flex log_reg forgeet_only">
            <button className="btn border-end px-4">Get Verification Code</button>
          </div>
          <div className="p_text">
            <p>Write Verification Code to change the password, Verification Code sent on mail.</p>
          </div>
          <div className="forgot_fields">
            <div className="mb-3">
              <input
                pattern="[1-9]{1}[0-9]{9}"
                maxLength={6}
                value={otp}
                name="phone"
                type="text"
                className="form-control"
                placeholder="Verification Code"
                onChange={(e) => {
                  setOtp(e.target.value);
                  setError("");
                }}
                onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
              />

              {error ? (
                <div
                  style={{
                    fontSize: "13px",
                    color: "red",
                  }}
                  className="alert "
                  role="alert"
                >
                  {error}
                </div>
              ) : null}
            </div>
            <button type="submit" className="logreg_btn btn btn-primary forget-btns" onClick={() => handleVerifyOTPSubmit()}>
              Submit
            </button>
          </div>
        </div>
      )}

      {/* Change-Password */}
      {currentScreen === "change-password" && (
        <div className="forgot_password">
          <div className="d-flex log_reg forgeet_only">
            <button className="btn border-end px-4">Change Password</button>
          </div>
          <div className="p_text">
            <p>Make sure to use a strong password.</p>
          </div>
          <div className="forgot_fields">
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Write Password*"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setPassworsConfirmError("");
                }}
              />

              {passwordConfirmError.newPassword && <p style={{ color: "red", fontSize: "13px" }}>{passwordConfirmError.newPassword}</p>}
            </div>
            <div className="mb-3">
              <input
                name="confirmNewPassword"
                type="password"
                className="form-control"
                placeholder="Write Password*"
                value={confirmNewPassword}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                  setPassworsConfirmError("");
                }}
              />
              {passwordConfirmError.confirmNewPassword && <p style={{ color: "red", fontSize: "13px" }}>{passwordConfirmError.confirmNewPassword}</p>}
            </div>
            <button type="submit" className="logreg_btn btn btn-primary forget-btns" onClick={handleResetPasswordSubmit}>
              Save
            </button>
          </div>
        </div>
      )}

      {/* Password-Changed-Successfully */}
      {currentScreen === "password-changed-successfully" && (
        <div className="forgot_password final_changed">
          <div className="d-flex log_reg forgeet_only">
            <button className="btn border-end px-4">Password Changed Successfully!</button>
          </div>
          <div className="forgot_fields image_specific">
            <img src={Yes} alt="Pasword-changed" />
          </div>
          <div className="p_changed">
            You can now <Link to="/">login</Link> Login with your new password.
          </div>
        </div>
      )}
    </LoginLayout>
  );
}

export default ForgotPassword;
