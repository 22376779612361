import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../Element/Api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAuth from "../../hooks/useAuth";
import Shield from "../../assets/shield.svg";
import { useNavigate } from "react-router-dom";
import ModalMessage from "../ModalMessage/Modal";
import BaseLayout from "../../layouts/BaseLayout";
import { editorStyle, formats, modules } from "../../utils/utils";

const Support = () => {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Url.BASEURL + `/broker/support`, config);
        const { data } = response;
        setTitle(data.support.title || "");
        setEmail(data.support.email || "");
        setPhone(data.support.phone || "");
        setDescription(data.support.description || "");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const postData = {
        title,
        email,
        phone,
        description,
      };
      const response = await axios.post(Url.BASEURL + `/broker/support`, postData, config);
      navigate("/");
    } catch (error) {
      setShowModal(false);
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else {
        setErrors({ general: "Failed to update data. Please try again." });
      }
    }
  };

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages single_posts table_view_only">
        <h2>For Support</h2>

        <p>Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>

        <div className="post-data title_bar_setting ">
          <div className="post_detail d-flex ">
            <div className="left_post_detail">
              <div className="single_entry">
                <label>
                  Title
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        title: "",
                      }));
                    }}
                    placeholder="Enter your title here"
                  />
                </label>
                {errors.title && <p style={{ color: "red", fontSize: "12px" }}>{errors.title}</p>}
              </div>
            </div>
            <div className="right_post_detail">
              <div className="single_entry">
                <label>
                  Email
                  <input
                    type="text"
                    className="form-control"
                    value={email}
                    onChange={(e) => {
                      const input = e.target.value;
                      setEmail(input);
                      if (input.trim() !== "") {
                        if (!/^\S+@\S+\.\S+$/.test(input)) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "Invalid email format",
                          }));
                        } else {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "",
                          }));
                        }
                      } else {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          email: "",
                        }));
                      }
                    }}
                    placeholder="Enter your email"
                  />
                </label>
                {errors.email && <p style={{ color: "red", fontSize: "12px" }}>{errors.email}</p>}
              </div>
            </div>
          </div>

          <div className="single_entry">
            <label>
              Phone No.
              <input
                type="text"
                className="form-control"
                pattern="[0-9]*"
                value={phone}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^[0-9]*$/.test(input) && input.length >= 0 && input.length <= 15) {
                    setPhone(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      phone: "",
                    }));
                  }
                }}
                placeholder="Enter your phone number"
              />
            </label>
            {errors.phone && <p style={{ color: "red", fontSize: "12px" }}>{errors.phone}</p>}
          </div>
        </div>

        <div className="editor" style={editorStyle}>
          <ReactQuill
            value={description}
            modules={modules}
            formats={formats}
            onChange={(html) => {
              setDescription(html);
              setErrors((prevErrors) => ({
                ...prevErrors,
                description: "",
              }));
            }}
          />
          {errors.description && <p style={{ color: "red", fontSize: "12px" }}>{errors.description}</p>}
        </div>

        <div className="two_btns" style={{ paddingLeft: "20px", paddingBottom: "20px" }}>
          <button type="submit" className="btn update_btn" onClick={handleUpdate}>
            Save
          </button>
        </div>

        <ModalMessage
          show={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          handleSave={handleSave}
          message="Do you really want to save your Support details?"
          img={Shield}
          leftButton="Cancel"
          rightButton="Save"
        />
      </div>
    </BaseLayout>
  );
};

export default Support;
