import React, { useState, useEffect } from "react";
import axios from "axios";
import "../SettingPages.css";
import Url from "../../../Element/Api";
import { useNavigate } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import Shield from "../../../../assets/shield.svg";
import ModalMessage from "../../../ModalMessage/Modal";
import BaseLayout from "../../../../layouts/BaseLayout";

function AmpPages() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    headertitle: "",
    phone: "",
    status: false,
  });
  const [errors, setErrors] = useState({
    headertitle: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value; //updated the value of checkbox
    const finalValue = name === "status" ? checked : newValue;
    setFormData({
      ...formData,
      [name]: finalValue,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to retrieve data
        const response = await axios.get(`${Url.BASEURL}/broker/amp-page`, config);
        // If data is present, update the state for prefilling the form
        if (response.data) {
          setFormData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data using GET request when the component mounts
    fetchData();
  }, []);

  const handleSave = async () => {
    setErrors("");
    try {
      await axios.post(`${Url.BASEURL}/broker/amp-page`, formData, config);
      navigate("/");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const apiErrors = error.response.data.errors;
        setErrors({
          headertitle: apiErrors.headertitle || "",
          phone: apiErrors.phone || "",
        });
      } else {
        console.error("Error updating data:", error);
      }
      setShowModal(false);
    }
  };

  return (
    <BaseLayout>
      <div className="mutual_blocks amp-pages">
        <h2>AMP Page Settings</h2>
        <p>Having AMP Pages enabled helps to capture leads from mobile devices. AMP pages are simplified lightweight versions of your saved search pages that are cached on lightning fast Google servers. This helps improve performance on mobile devices, which can improve your overall ranking in Google Searches.</p>
        <label className="custom-checkbox-container">
          <input type="checkbox" className="custom-checkbox" name="status" checked={formData.status} onChange={handleChange} />
          <span className="checkmark"></span>
          <span className="spannerr">Enable Google Amp Saved Search Pages</span>
        </label>

        <div className="title_bar_setting">
          <h3>Title Bar Settings</h3>
          <div className="form-group">
            <label htmlFor="headertitle">Amp Header Title:</label>
            <input type="text" id="headertitle" className="form-control" name="headertitle" value={formData.headertitle} onChange={handleChange} placeholder="Enter your Title" />
            {errors.headertitle && <p style={{ color: "red", fontSize: "12px", marginTop: "2px" }}>{errors.headertitle}</p>}
          </div>
          <div className="small_text">This is the text that will appear in the fixed header bar for all of your amp pages. If nothing is entered here it will display your company name (if set) or your name.</div>
        </div>

        <div className="title_bar_setting second_input">
          <div className="form-group">
            <label htmlFor="callMeNowPhone">"Call Me Now" Phone Number:</label>
            <input type="text" id="callMeNowPhone" className="form-control" name="phone" value={formData.phone} onChange={handleChange} placeholder="Enter no. here" />
            {errors.phone && <p style={{ color: "red", fontSize: "12px", marginTop: "2px" }}>{errors.phone}</p>}
          </div>

          <div className="small_text">Entering a value here will cause a phone icon to appear in the amp page title bar that will allow a potential client to call you directly. This is a great feature to enable since amp pages are designed for users already on mobile devices.</div>
        </div>

        <div className="two_btns">
          <button
            className="btn update_btn"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Save
          </button>
          <button
            className="btn cancel_btn"
            onClick={() => {
              navigate("/");
            }}
          >
            Cancel
          </button>
        </div>

        <ModalMessage show={showModal} handleClose={() => setShowModal(false)} handleSave={handleSave} message="Do you really want to save AMP Page Settings?" img={Shield} leftButton="Cancel" rightButton="Save" />
      </div>
    </BaseLayout>
  );
}

export default AmpPages;
