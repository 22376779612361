import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Url from "../../Element/Api";
import useAuth from "../../../hooks/useAuth";
import Close from "../../../assets/close.svg";
import Slide from "../../../assets/arrow_slide.svg";
import Verified from "../../../assets/verified.svg";
import Delete from "../../../assets/Recycle Bin.svg";

const AddRealtorModal = ({ isFormVisible, onSetIsFormVisible, fetchRealtors }) => {
  const [config] = useAuth();
  const modalRef = useRef(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [slides, setSlides] = useState([{}]);
  const [isDisable, setIsDisable] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [invitationSuccess, setInvitationSuccess] = useState([""]);
  const [alreadyExistsRealtor, setAlreadyExistsRealtor] = useState([]);

  const handleInputChange = (e, field, index) => {
    setSlides((prevSlides) => {
      const newSlides = [...prevSlides];
      newSlides[index] = {
        ...newSlides[index],
        [field]: e.target.value,
      };
      return newSlides;
    });
  };

  const handleAddSlide = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const currentSlideData = slides[currentSlide];
    if (!currentSlideData?.Email || !currentSlideData?.Firstname || !currentSlideData?.Lastname || !currentSlideData?.PhoneNo) {
      setError("Please fill all details for the current slide");
    } else if (!emailRegex?.test(currentSlideData?.Email)) {
      setError("Please enter a valid email");
    } else {
      setError("");
      setCurrentSlide((prevSlide) => prevSlide + 1);
      setSlides((prevSlides) => [
        ...prevSlides,
        {
          Firstname: "",
          Lastname: "",
          Email: "",
          PhoneNo: "",
        },
      ]);
    }
  };

  const prevSlide = () => {
    setError("");
    setCurrentSlide((current) => (current === 0 ? slides.length - 1 : current - 1));
  };

  const nextSlide = () => {
    setError("");
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  const deleteSlide = (index) => {
    if (index === 0) return;
    const newSlides = slides.filter((_, slideIndex) => slideIndex !== index);
    setSlides(newSlides);
    if (currentSlide >= newSlides.length) {
      setCurrentSlide(newSlides.length - 1);
    }
  };

  const handleSendClick = () => {
    setIsDisable(true);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const currentSlideData = slides[currentSlide];
    if (!currentSlideData.Email || !currentSlideData.Firstname || !currentSlideData.Lastname || !currentSlideData.PhoneNo) {
      setError("Please fill all details for the current slide");
      setIsDisable(false);
    } else if (!emailRegex.test(currentSlideData.Email)) {
      setError("Please enter a valid email");
      setIsDisable(false);
    } else {
      setError("");
      const params = {
        realtors: slides,
      };
      axios
        .post(Url.BASEURL + `/broker/invite-realtor`, params, config)
        .then((res) => {
          setMessage(res?.data?.message);
          setInvitationSuccess(res?.data?.invitationsSent);
          onSetIsFormVisible(false);
          setIsDisable(false);
          setAlreadyExistsRealtor([]);
          setError("");
          fetchRealtors();
        })
        .catch((err) => {
          console.log(err);
          setError(err?.response?.data?.message);
          if (err?.response?.data?.alreadyExistsRealtor) {
            setAlreadyExistsRealtor(err?.response?.data?.alreadyExistsRealtor);
          }
          setIsDisable(false);
          setMessage("");
        });
    }
  };

  const closeModal = () => {
    slides.map((slide) => {
      slide.Firstname = "";
      slide.Lastname = "";
      slide.Email = "";
      slide.PhoneNo = "";
    });
    setIsDisable(false);
    setAlreadyExistsRealtor([]);
    setMessage("");
    setError("");
  };

  /* code to clear prefilled form data */
  useEffect(() => {
    const handleModalClose = () => {
      setIsDisable(false);
      const newSlides = slides.map((slide) => ({
        ...slide,
        Firstname: "",
        Lastname: "",
        Email: "",
        PhoneNo: "",
      }));
      setSlides(newSlides);
      setError("");
      setAlreadyExistsRealtor([]);
      setMessage("");
    };

    const modalElement = modalRef.current;
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleModalClose);
    }
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
      }
    };
  }, [slides]);

  return (
    <div className="modal fade realtor_modal" id="addRealtorModal" tabIndex="-1" aria-labelledby="addRealtorModalLabel" aria-hidden="true" ref={modalRef}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="dotting">{slides.length > 1 && slides.map((_, index) => <div key={index} className={currentSlide === index ? "dot_list active" : "dot_list"} onClick={() => setCurrentSlide(index)}></div>)}</div>

            {isFormVisible ? (
              <div className="title_bar_setting tag_add_new slider_serting">
                <div className="slades">
                  {slides.map((slide, index) => (
                    <div key={index} className={currentSlide === index ? "slides active" : "slides"}>
                      <div>
                        <div className="your-component">
                          <div className="realtor-form">
                            <div className="real_row d-flex">
                              <div className="real_left">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    value={slide.Firstname}
                                    name="Firstname"
                                    className="form-control"
                                    placeholder="First Name*"
                                    style={{ textTransform: "capitalize" }}
                                    onChange={(e) => {
                                      e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                                      handleInputChange(e, "Firstname", index);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="real_right">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    style={{ textTransform: "capitalize" }}
                                    value={slide.Lastname}
                                    name="Lastname"
                                    className="form-control"
                                    placeholder="Last Name*"
                                    onChange={(e) => {
                                      e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                                      handleInputChange(e, "Lastname", index);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="real-full-width">
                              <div className="form-group">
                                <input type="text" value={slide.Email} name="Email" className="form-control" placeholder="Email Address*" onChange={(e) => handleInputChange(e, "Email", index)} />
                              </div>
                            </div>
                            <div className="real-full-width">
                              <div className="form-group">
                                <input maxLength={15} value={slide.PhoneNo} name="PhoneNo" type="text" className="form-control" placeholder="Phone No." onChange={(e) => handleInputChange(e, "PhoneNo", index)} onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex justify-content-between">
                  <button className="add_multiple_realt" onClick={handleAddSlide}>
                    + Add multiple Realtor
                  </button>

                  {slides.length > 1 && currentSlide !== 0 && (
                    <>
                      <button className="add_multiple_realt" onClick={() => deleteSlide(currentSlide)}>
                        x Remove
                      </button>
                      {/*<button className="btn delete_realtor" onClick={() => deleteSlide(currentSlide)}>
                        <img src={Delete} alt="delete" />
                      </button>*/}
                    </>
                  )}
                </div>

                {error !== "" && (
                  <div className="mt-1" style={{ color: "red", fontSize: "12px" }}>
                    {error}
                  </div>
                )}

                {/* {alreadyExistsRealtor?.length !== 0 && <div style={{ color: "red", fontSize: "12px" }}>{alreadyExistsRealtor?.map((el, i) => (i === alreadyExistsRealtor.length - 1 ? el : `${el}, `))} already exists</div>} */}

                <div className="two_btns raltor_btns d-flex justify-content-between" style={{ marginTop: "30px" }}>
                  <button disabled={isDisable} className="btn update_btn saver raltor_btnss" onClick={handleSendClick}>
                    Send
                  </button>
                  <button className="btn cancel_btn raltor_btnss" data-bs-dismiss="modal" onClick={closeModal}>
                    Cancel
                  </button>
                </div>

                {slides.length > 1 && (
                  <div className="slides_arrows">
                    <div className="previous_aro" onClick={prevSlide}>
                      <img src={Slide} className="close" alt="" />
                    </div>
                    <div className="next_aro" onClick={nextSlide}>
                      <img src={Slide} className="close" alt="" />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className=" verification_done add_realtor_message emails_verification">
                <div className="cross_btn faloos" data-bs-toggle="modal" data-bs-target="#addRealtorModal">
                  <img src={Close} className="close" alt="" />
                </div>
                <h3>Invitation Sent</h3>
                <p>
                  You've successfully extended an invitation to{" "}
                  <div className="emailer_name">
                    <strong>{invitationSuccess.map((el, i) => (i === invitationSuccess?.length - 1 ? el?.email : `${el?.email}, `))}</strong>
                  </div>{" "}
                </p>
                <img src={Verified} alt="verified" />

                <h4>
                  Await their response and welcome them to your real <br></br>estate network.
                </h4>
                {message !== "" && alreadyExistsRealtor?.length !== 0 && (
                  <p>
                    <strong style={{ textDecoration: "underline", color: "#000" }}>Note:</strong> {message}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRealtorModal;
