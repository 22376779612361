import React from "react";
import "../Realtor/Realtor.css";
import { Modal } from "react-bootstrap";
import Cross from "../../assets/close.svg";
import Shield from "../../assets/shield.svg";

const ActiveInactiveRealtorModal = ({ type, onClose, onConfirm, show }) => {
  return (
    <Modal className="pending_realtor activate-modal inactive_dropdown" show={show} onHide={onClose}>
      <Modal.Body>
        <div className="delete-modal-content">
          <div className="delete-modal-header">
            <img src={Shield} alt="" />
            <h5>Are you sure?</h5>
            <p>Do you really want to {type === "Active" ? "active" : "Inactivate"} this realtor? This process can change anytime .</p>
            <div className="cross_btn" onClick={onClose}>
              <img src={Cross} className="close" alt="" />
            </div>
          </div>
          <p></p>
          <div className="two_tube_btns activate_inactivate_only d-flex">
            <div className=" halks_btn" onClick={onClose}>
              Cancel
            </div>
            <div className="btn_tt_one" onClick={onConfirm}>
              {type}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ActiveInactiveRealtorModal;
