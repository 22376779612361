import React, { useState, useEffect, useRef } from "react";
import "./TeamMates.css";
import axios from "axios";
import Url from "../Element/Api";
import Loader from "../../hooks/Loader";
import Sliders from "../../assets/s3.svg";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import Upload from "../../assets/upload.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import TeamMate from "./RealtorModals/TeamMate";
import BaseLayout from "../../layouts/BaseLayout";
import CsvUploadModal from "./RealtorModals/CsvUploadModal";
import AddRealtorModal from "./RealtorModals/AddRealtorModal";
import InviteAgainModal from "./RealtorModals/InviteAgainModal";
import LimitExceedModal from "./RealtorModals/LimitExceedModal";
import UploadedRealtorDataModal from "./RealtorModals/UploadedRealtorDataModal";
import UploadingRealtorDataModal from "./RealtorModals/UploadingRealtorDataModal";
import { NextLabel, PreviousLabel, handleDropdownClose } from "../../utils/utils";

const AgentdropdownOptions = ["All", "Active", "Inactive", "Pending"];

function TeamMates() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const agentType = useSelector((state) => state.agentType);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [teamMates, setTeamMates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadPercentage, setYourValue] = useState(0);
  const [hiddenBlocks, setHiddenBlocks] = useState({});
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All");
  const [isCsvModalOpen, setIsCsvModalOpen] = useState(false);
  const [notSentDueToLimit, setNotSentDueToLimit] = useState(0);
  const [onSaveTeammateData, setOnSaveTeammateData] = useState("");
  const [csvFileName, setCsvFileName] = useState("No file chosen");
  const [invitationsuccess, setInvitationsuccess] = useState(false);
  const [isUploadingModalOpen, setIsUploadingModalOpen] = useState(false);
  const [isPendingPopupVisible, setIsPendingPopupVisible] = useState(false);
  const [currentUploadPercentage, setCurrentUploadPercentage] = useState(0);
  const [invitationagainsuccess, setInvitationagainsuccess] = useState(false);
  const [isLimitExceedModalOpen, setIsLimitExceedModalOpen] = useState(false);
  const [isUploadCompleteModalOpen, setIsUploadCompleteModalOpen] = useState(false);
  const [isInvitationSentPopupVisible, setIsInvitationSentPopupVisible] = useState(false);

  /* new-added */
  const pageNumbers = [];
  const itemsPerPage = 12;
  const totalResults = totalPage;
  const currentPageAdjusted = Math.max(currentPage, 1);
  const totalPages = Math.ceil(totalResults / itemsPerPage);
  const indexOfFirstItem = (currentPageAdjusted - 1) * itemsPerPage + 1;
  const indexOfLastItem = Math.min(currentPageAdjusted * itemsPerPage, totalItem);
  // const currentTeammates = teamMates.slice(indexOfFirstItem, indexOfLastItem);
  // const pageCount = Math.ceil(teamMates.length / itemsPerPage);

  const handleSliderChange = (value) => {
    setYourValue(value);
  };

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  const handleInviteAgain = () => {
    setIsPendingPopupVisible(false);
    setIsInvitationSentPopupVisible(true);
  };

  const toggleVisibility = (name) => {
    setHiddenBlocks((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  useEffect(() => {
    fetchRealtors();
  }, [currentPage, searchTerm, invitationsuccess]);

  const fetchRealtors = (statusParam) => {
    setLoading(true);
    const queryParams = [];
    let url = Url.BASEURL + "/broker/all-realtors";
    if (statusParam) queryParams.push(`status=${statusParam}`);
    if (currentPage) queryParams.push(`page=${currentPage}`);
    if (searchTerm) queryParams.push(`term=${searchTerm}`);
    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    axios
      .get(url, config)
      .then((response) => {
        setTeamMates(response.data.realtors);
        setTotalPage(response.data.totalPages);
        setTotalItem(response.data.totalItems);
        setInvitationsuccess(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching realtors:", error);
      });
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
    // Make the API request with the selected filter option
    let statusParam = "";
    if (option === "Active") {
      statusParam = "1"; // Active status
      setStatus(1);
    } else if (option === "Inactive") {
      statusParam = "0"; // Inactive status
      setStatus(0);
    } else if (option === "Pending") {
      statusParam = "2"; // Pending status
      setStatus(2);
    }
    console.log("statusParam", statusParam);
    fetchRealtors(statusParam);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected);
  };

  const onCsvFileChange = (e) => {
    const file = e.target.files[0];
    setCsvFileName(file.name);
  };

  const onCsvUpload = () => {
    setIsCsvModalOpen(false);
    setIsUploadingModalOpen(true);
    const uploadSimulator = setInterval(() => {
      setCurrentUploadPercentage((prevPercentage) => {
        if (prevPercentage >= 100) {
          clearInterval(uploadSimulator);
          setIsUploadingModalOpen(false);
          setIsUploadCompleteModalOpen(true);
          return 100;
        }
        return prevPercentage + 10;
      });
    }, 1000);
  };

  const onUploadProgressChange = (value) => {
    setCurrentUploadPercentage(value);
  };

  const closeModal = () => {
    setIsCsvModalOpen(false);
    setIsUploadingModalOpen(false);
    setIsUploadCompleteModalOpen(false);
  };

  useEffect(() => {
    let timer;
    if (!isFormVisible || invitationagainsuccess) {
      timer = setTimeout(() => {
        const closeButton = document.querySelector(".cross_btn");
        if (closeButton) {
          closeButton.click();
        }
        setInvitationagainsuccess(false);
        setIsFormVisible(true);
        fetchRealtors();
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [isFormVisible, invitationagainsuccess]);

  return (
    <BaseLayout>
      <div className="mutual_blocks team_mamett">
        <div className="heading_inline d-flex team-matew-page">
          <div className="left_head right_head">
            <h2 style={{ fontSize: "32px", color: "#212529" }}>Realtor</h2>
          </div>
          <div className="right_head">
            <div className="btn csv_upload" onClick={() => setIsCsvModalOpen(true)}>
              <img src={Upload} alt="upload" /> Upload CSV
            </div>

            {agentType == 1 && (
              <div className="Add_new_blog btn update_btn" data-bs-toggle="modal" data-bs-target="#addRealtorModal">
                Add Realtor
              </div>
            )}
          </div>
        </div>

        <div className="row mb-3  realtor_spec_only">
          <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
            <div className="lisingenumbers">{`${indexOfFirstItem}-${indexOfLastItem} of ${totalItem} Results`}</div>
          </div>
          <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
            <div className="search_area d-flex">
              <div className="search_bloxk">
                <input
                  type="search"
                  className="form-control me-2"
                  placeholder="Search by Name"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="filter_area">
              <div className="agent_dropdown">
                <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                  <div
                    className="selected_option"
                    onClick={() => {
                      if (!isDropdownOpen) {
                        setIsDropdownOpen(true);
                      }
                    }}
                    style={{ backgroundImage: `url(${Sliders})` }}
                  >
                    {selectedOption}
                  </div>
                  {isDropdownOpen && (
                    <div className="drop_selection">
                      {AgentdropdownOptions.map((option, index) => (
                        <p key={index} onClick={() => handleOptionSelect(option)}>
                          {option}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ minHeight: "55vh" }}>
          {loading ? (
            <Loader />
          ) : teamMates?.length === 0 ? (
            <p style={{ textAlign: "center" }}>No Realtor Found</p>
          ) : (
            <div className="teammate_list d-flex team-mate-page-only">
              {teamMates?.map((mate, idx) => (
                <TeamMate key={idx} mate={mate} onToggleVisibility={() => toggleVisibility(mate.name)} onSetIsPendingPopupVisible={(value) => setIsPendingPopupVisible(value)} onSetOnSaveTeammateData={(value) => setOnSaveTeammateData(value)} hiddenBlocks={hiddenBlocks} />
              ))}
            </div>
          )}
        </div>

        {totalPage > 1 && (
          <div className="all_pagination">
            <ReactPaginate
              previousLabel={<PreviousLabel />}
              nextLabel={<NextLabel />}
              breakLabel={"..."}
              pageCount={totalPage}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={(e) => {
                setCurrentPage(e.selected + 1);
              }}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        )}

        {/* modal-3-data-uploaded */}
        <UploadedRealtorDataModal closeModal={closeModal} isUploadCompleteModalOpen={isUploadCompleteModalOpen} uploadPercentage={uploadPercentage} handleSliderChange={handleSliderChange} />

        <UploadingRealtorDataModal
          uploadPercentage={uploadPercentage}
          handleSliderChange={handleSliderChange}
          isUploadingModalOpen={isUploadingModalOpen}
          closeModal={closeModal}
          onSetIsUploadingModalOpen={(value) => {
            setIsUploadingModalOpen(value);
          }}
        />

        {/* invite_send_popup-pending */}
        <InviteAgainModal
          onSaveTeammateData={onSaveTeammateData}
          isInvitationSentPopupVisible={isInvitationSentPopupVisible}
          isFormVisible={isFormVisible}
          isPendingPopupVisible={isPendingPopupVisible}
          onSetIsPendingPopupVisible={(value) => setIsPendingPopupVisible(value)}
          onSetIsFormVisible={(value) => {
            setIsFormVisible(value);
          }}
        />

        {/* Add Realtor Modal */}
        <AddRealtorModal
          isFormVisible={isFormVisible}
          onSetIsFormVisible={(value) => {
            setIsFormVisible(value);
          }}
          fetchRealtors={fetchRealtors}
        />

        {/* CSV Upload Modal */}
        <CsvUploadModal
          isCsvModalOpen={isCsvModalOpen}
          closeModal={closeModal}
          onOpenLimitModal={() => setIsLimitExceedModalOpen(true)}
          onSetNotSentDueToLimit={(value) => {
            setNotSentDueToLimit(value);
          }}
        />

        {/* Limit Exceed Modal */}
        <LimitExceedModal
          isOpen={isLimitExceedModalOpen}
          notSentDueToLimit={notSentDueToLimit}
          closeModal={() => {
            setIsLimitExceedModalOpen(false);
            setNotSentDueToLimit(0);
          }}
        />
      </div>
    </BaseLayout>
  );
}
export default TeamMates;
