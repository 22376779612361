import React, { useState, useEffect } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { useDispatch } from "react-redux";
import "../LoginRegister/LoginRegister.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoginLayout from "../../layouts/LoginLayout";
import { setRefreshToken, setRegToken, setToken, setTokenExpTime } from "../../Redux/Action/Authaction";

function Register() {
  const id = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [activeSection, setActiveSection] = useState("login");
  const [userInputForRegister, setUserInputForRegister] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${id.token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        const response = await axios.get(Url.BASEURL + `/broker/realtor/realtor-by-invtation/${id.token}`, config);
        const dataToSet = {
          email: response.data.email,
          firstname: response.data.firstname,
          lastname: response.data.lastname,
          phone: response.data.phone,
          password: "",
        };
        setUserInputForRegister(dataToSet);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleChangeForRegister = (e) => {
    const { name, value } = e.target;
    setUserInputForRegister({
      ...userInputForRegister,
      [name]: value,
    });
    setError((prevError) => {
      const { [name]: removedError, ...rest } = prevError;
      return rest;
    });
  };

  const submitGetWebsite = (e) => {
    // if (userInputForRegister.firstname && userInputForRegister.lastname && userInputForRegister.phone && userInputForRegister.email && userInputForRegister.password) {
    const params = {
      firstname: userInputForRegister.firstname,
      lastname: userInputForRegister.lastname,
      phone: userInputForRegister.phone,
      // email: userInputForRegister.email,
      password: userInputForRegister.password,
    };
    axios
      .post(Url.BASEURL + `/broker/realtor/${id.id}/register/${id.token}`, params)
      .then((value) => {
        if (value.data.success === true) {
          console.log("value", value.data);
          dispatch(setRegToken(value.data.accessToken));
          dispatch(setToken(value.data.accessToken));
          dispatch(setRefreshToken(value?.data?.refreshToken));
          dispatch(setTokenExpTime(value?.data?.expirationTime));
          // dispatch(setUserType(value?.data?.userType ? value?.data?.userType : 0));
          setTimeout(() => {
            navigate("/realtor-profile");
          }, 2000);
        }
      })
      .catch((err) => {
        const apiErrors = err.response.data.errors;
        setError(apiErrors || {});
      });
  };

  return (
    <LoginLayout>
      <div className="d-flex log_reg">
        <button className={`btn px-4 active`} onClick={() => setActiveSection("register")}>
          Sign In
        </button>
      </div>

      <div className={`section mt-4 register active`}>
        <div>
          <div className="row mb-3">
            <div className="col mcol">
              <input disabled required type="text" name="firstname" value={userInputForRegister.firstname} className="form-control" placeholder="First Name" onChange={handleChangeForRegister} />
            </div>
            <div className="col mcol">
              <input disabled type="text" name="lastname" value={userInputForRegister.lastname} className="form-control" placeholder="Last Name" onChange={handleChangeForRegister} />
            </div>
          </div>

          <div className="mb-3">
            <input disabled type="text" name="phone" value={userInputForRegister.phone} className="form-control" placeholder="Phone No*" onChange={handleChangeForRegister} />
          </div>

          <div className="mb-3">
            <input disabled type="email" readOnly name="email" value={userInputForRegister.email} className="form-control" placeholder="Email Address*" onChange={handleChangeForRegister} />
          </div>

          <div className="mb-3">
            <input type="password" name="password" value={userInputForRegister.password} className="form-control" placeholder="Password*" onChange={handleChangeForRegister} />
            {error.password && <p style={{ color: "red", fontSize: "13px" }}>{error.password}</p>}
          </div>

          <button onClick={submitGetWebsite} className="btn btn-primary logreg_btn">
            Get Your Website
          </button>
        </div>
      </div>
    </LoginLayout>
  );
}

export default Register;
