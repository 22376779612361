import React, { useState, useEffect, useRef } from "react";
import "./Transactions.css";
import axios from "axios";
import Url from "../Element/Api";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import Sliders from "../../assets/s3.svg";
import ReactPaginate from "react-paginate";
import TransactionData from "./TransactionData";
import BaseLayout from "../../layouts/BaseLayout";
import ClipLoader from "react-spinners/ClipLoader";
import { NextLabel, PreviousLabel, handleDropdownClose } from "../../utils/utils";

const AgentdropdownOptions = [
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

function Transactions() {
  const [config] = useAuth();
  const dropdownRef = useRef(null);
  const userType = useSelector((state) => state.userType);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [dropdownType, setDropdownType] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [selectedOption, setSelectedOption] = useState("Filters");

  const itemsPerPage = 12;
  const totalPages = Math.ceil(totalResults / itemsPerPage);
  const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalTransactions);
  const indexOfFirstItem = Math.min((currentPage - 1) * itemsPerPage, totalTransactions);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleOptionSelect = (option) => {
    setSelectedOption(option === " " ? "All" : option);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
      setOpenDropdown(null);
      setDropdownType("");
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  useEffect(() => {
    setLoading(true);
    // const queryValue = AgentdropdownOptions.find((option) => option.content === selectedOption)?.value || "";
    let url = userType == 1 ? `/broker/all-transaction?page=${currentPage}&searchTerm=${searchTerm}` : `/broker/realtor/get-all-transactions?page=${currentPage}`;
    if (selectedOption !== "All") {
      const queryValue = AgentdropdownOptions.find((option) => option.content === selectedOption)?.value || "";
      url += `&status=${queryValue}`;
    }

    axios
      .get(`${Url.BASEURL}${url}`, config)
      .then((response) => {
        setTransactions(response.data.allTransactions || []);
        setTotalPage(response.data.totalPages);
        setTotalTransactions(response.data.totalTransactions);
        setLoading(false);
        setTotalResults(response.data.totalTransactions);
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedOption, currentPage, userType, searchTerm]);

  return (
    <BaseLayout addClass={true}>
      <div className="mutual_blocks amp-pages traansaction table_view_only ">
        <h2>Transactions</h2>
        <div className="posting_area">
          <div className="row mb-2 curlter ">
            <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett jeter">
              <div className="lisingenumbers">{`${indexOfFirstItem + 1}-${indexOfLastItem} of ${totalTransactions} Results`}</div>
            </div>
            <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
              <div className="search_area d-flex">
                <div className="search_bloxk">
                  <input
                    type="search"
                    className="form-control me-2"
                    placeholder="Search by Realtor Name"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="btn_block">
                  <button className="btn btn-primary">Search</button>
                </div> */}
              </div>
              <div className="filter_area">
                <div className="agent_dropdown">
                  <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                    <div
                      className="selected_option"
                      onClick={() => {
                        if (!isDropdownOpen) {
                          setIsDropdownOpen(true);
                        }
                      }}
                      style={{ backgroundImage: `url(${Sliders})` }}
                    >
                      {selectedOption}
                    </div>
                    {isDropdownOpen && (
                      <div className="drop_selection">
                        {AgentdropdownOptions.map((option, index) => (
                          <p key={index} onClick={() => handleOptionSelect(option.content)}>
                            {option.content}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Table */}
          <div className="payment_type_status main_transaction_page">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Payment Type</th>
                    {userType == 1 && <th className="alignment">Realtor</th>}
                    {userType == 1 && <th className="alignment">Wovnn</th>}
                    <th className="alignment_right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "50vh",
                        marginLeft: "23em",
                      }}
                    >
                      <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                    </div>
                  ) : (
                    <>
                      {transactions.length > 0 ? (
                        transactions.map((allTransactions, idx) => <TransactionData key={idx} allTransactions={allTransactions} />)
                      ) : (
                        <tr className="text-center">
                          <td
                            colSpan="9"
                            style={{
                              fontSize: 16,
                              marginTop: "10px",
                              alignSelf: "center",
                              textAlign: "center",
                            }}
                          >
                            No Transactions Found
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>

              {totalPage > 1 && (
                <div className="all_pagination">
                  <ReactPaginate
                    previousLabel={<PreviousLabel />}
                    nextLabel={<NextLabel />}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => {
                      const selectedPage = data.selected + 1;
                      setCurrentPage(selectedPage);
                    }}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Transactions;
