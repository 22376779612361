import React, { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import Sliders from "../../../assets/s3.svg";
import LeftArrow from "../../../assets/larrow.svg";
import Closer from "../../../assets/close-grey.svg";
import { handleDropdownClose } from "../../../utils/utils";

const RealtordownOptions = [
  { type: "heading", content: "Payment" },
  { type: "option", content: "All", value: " " },
  { type: "option", content: "Successful", value: "succeeded" },
  { type: "option", content: "Failed", value: "failed" },
];

const TransctionDetailModal = ({ loading, transactions, totalPages, onSetSelectedOptionRealtor, onSetSelectedOption }) => {
  const dropdownRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const nextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleOptionSelect = (option) => {
    onSetSelectedOptionRealtor(option);
    onSetSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObj);
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  useEffect(() => {
    const handleClose = () => {
      setIsDropdownOpen(false);
    };
    handleDropdownClose(dropdownRef, handleClose);
  }, [dropdownRef]);

  return (
    <div className="modal fade realtor_modal all_transactionsal" id="addRealtorTransactionModal" tabIndex="-1" aria-labelledby="addRealtorTransactionModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body custo_real-modal">
            <div className="modal_closer" data-bs-dismiss="modal" aria-label="Close">
              <img src={Closer} alt="" />
            </div>
            <div className="full_rel_trans d-flex">
              <div className="left_real_heading">Realtor Transaction</div>
              <div className="sedu">
                <div className="filter_area">
                  <div className="agent_dropdown">
                    <div ref={dropdownRef} className={`drop_data ${isDropdownOpen ? "open" : ""}`}>
                      <div
                        className="selected_option corporations"
                        onClick={() => {
                          setIsDropdownOpen(!isDropdownOpen);
                        }}
                        style={{
                          backgroundImage: `url(${Sliders})`,
                        }}
                      >
                        <label>Filters</label>
                      </div>
                      {isDropdownOpen && (
                        <div className="drop_selection">
                          {RealtordownOptions.map((item, index) => {
                            if (item.type === "heading") {
                              return <h3 key={index}>{item.content}</h3>;
                            } else {
                              return (
                                <p key={index} onClick={() => handleOptionSelect(item.content)}>
                                  {item.content}
                                </p>
                              );
                            }
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment_type_status all_realtor_payment_transactions">
              <div className="table-responsive">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <ClipLoader color="#22A9E0" loading={loading} size={150} aria-label="Loading Spinner" data-testid="loader" />
                  </div>
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th>Payment Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {/* <tbody>
                          {transactions.map(
                            (allTransactions, index) => (
                              <tr key={index}>
                                <td
                                  // dangerouslySetInnerHTML={{
                                    // __html: transaction.detail,
                                  // }}
                                ></td>
                                <td>
                                  <div className="status">
                                    {allTransactions.amount}
                                  </div>
                                  <div className="success">
                                    {allTransactions.status}:
                                  </div>
                                  <div className="date_set">
                                    {allTransactions.date}
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody> */}
                    <tbody>
                      {transactions.slice(0, 4).map((allTransactions, index) => (
                        <tr key={index}>
                          <td>
                            {/* Condition 2: RealtorName with setup fee */}
                            {allTransactions.realtorName && allTransactions.feetype ? (
                              <>
                                <div>
                                  Set Up Fees (set by broker) : <span className="blacking">${allTransactions.brokerSetUpFee}</span>
                                </div>
                              </>
                            ) : null}
                            {/* Condition 3: RealtorName with no setup fee */}
                            {allTransactions.realtorName && !allTransactions.feetype ? (
                              <>
                                <div>
                                  Monthly Subscription for {formatDate(allTransactions.date)} : <span className="blacking">({allTransactions.adminSetUpFee})</span>
                                </div>
                              </>
                            ) : null}
                          </td>
                          <td>
                            <div className="status">
                              ${allTransactions.amount}
                              <br />
                              <span className="success">
                                {allTransactions.status === "succeeded" ? "Successful" : "Failed"}:{" "}
                                <span className="date_set">
                                  {new Date(allTransactions.date).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long", // Change to "long" for full month name
                                    day: "2-digit",
                                  })}
                                </span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              {totalPages > 1 && (
                <div className="custom-pagination">
                  <ul>
                    <li className="left_page laro" onClick={prevPage}>
                      <img src={LeftArrow} alt="Next page" />
                      Prev
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                      <li key={number} onClick={() => setCurrentPage(number)} className={currentPage === number ? "active-page" : ""}>
                        {number}
                      </li>
                    ))}
                    <li className="right_page raro" onClick={nextPage}>
                      Next
                      <img src={LeftArrow} alt="Next page" />
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransctionDetailModal;
