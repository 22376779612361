import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import "../PackagesProcess/Packages.css";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { Oval } from "react-loader-spinner";
import Stripe from "../../assets/stripe.svg";
import { Breadcrumbs } from "../../utils/utils";
import ClipLoader from "react-spinners/ClipLoader";
import Verified from "../../assets/verified-logo.svg";
import DefaultLayout from "../../layouts/DefaultLayout";

function BillingInfo() {
  const [config] = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [brokerData, setBrokerData] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const navigateFrom = localStorage.getItem("navigateFrom");
  const isPollingStart = localStorage.getItem("isPollingStart");
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [isDifferentAddress, setIsDifferentAddress] = useState(false);
  const [isStripeAccountCreated, setIsStripeAccountCreated] = useState(false);
  const brokerProfileDetails = useSelector((state) => state.brokerProfileDetails);
  const [addressDetails, setAddressDetails] = useState({ address: "", city: "", state: "", zipCode: "" });
  const isOnBoarded = brokerProfileDetails?.stripe_account_id && brokerProfileDetails?.stripe_customer_id;

  // Breadcrumbs data
  const breadcrumbData = [{ path: "/choose-package", label: "Packages" }, { path: "/packages-mls", label: "Mls" }, { path: "/register-domain", label: "Domain" }, { path: "/pricing-review", label: "Pricing Review" }, { label: "Billing Info" }];

  // Breadcrumbs data for choose package
  const breadcrumbDataPackage = [{ path: "/", label: "Dashboard" }, { path: "/choose-package", label: "Packages" }, { path: "/pricing-review", label: "Pricing Review" }, { label: "Billing Info" }];

  // Breadcrumbs data for mls
  const breadcrumbDataMls = [{ path: "/", label: "Dashboard" }, { path: "/packages-mls", label: "Mls" }, { path: "/pricing-review", label: "Pricing Review" }, { label: "Billing Info" }];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[A-Za-z\s]*$/;
    const addressregex = /^[A-Za-z0-9,\s]*$/;
    const zipCodeRegex = /^[0-9]*$/;

    if (name === "address" && !addressregex.test(value)) {
      return;
    }
    if ((name === "city" || name === "state") && !regex.test(value)) {
      return;
    }

    if (name === "zipCode") {
      if (!zipCodeRegex.test(value) || value.length > 5) {
        return;
      }
    }

    setUpdateError((prevError) => ({
      ...prevError,
      [name]: null,
    }));

    setAddressDetails({
      ...addressDetails,
      [name]: value,
    });
  };

  const fetchBrokerProfile = async () => {
    try {
      const response = await axios.get(Url.BASEURL + `/broker/profile`, config);
      setBrokerData(response.data);

      if (response.data.stripe_account_id && response.data.stripe_customer_id) {
        setShowCreateAccount(false);
        setIsStripeAccountCreated(true);
        localStorage.setItem("isPollingStart", "false");
      } else {
        setShowCreateAccount(true);
        setIsStripeAccountCreated(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchBrokerBillinginfo = async () => {
    try {
      const response = await axios.get(Url.BASEURL + `/broker/billing-address`, config);
      setAddressDetails({
        address: response.data.billingAddress?.address,
        city: response.data.billingAddress?.city,
        state: response.data.billingAddress?.state,
        zipCode: response.data.billingAddress?.zipcode,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrokerProfile();
    fetchBrokerBillinginfo();
  }, []);

  const UpdateBrokerBillinginfo = async () => {
    try {
      const dataToSend = {
        address: addressDetails.address,
        city: addressDetails.city,
        state: addressDetails.state,
        zipCode: addressDetails.zipCode,
      };
      const response = await axios.post(Url.BASEURL + `/broker/update-billing-address`, dataToSend, config);
      console.log(response);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setUpdateError(error.response.data.error);
      } else {
        console.error("Error updating billing address:", error);
      }
    }
  };

  const handleCreateAccount = async () => {
    localStorage.setItem("isPollingStart", "true");
    setIsCreatingAccount(true);
    const dataToSend = {
      address: addressDetails.address,
      city: addressDetails.city,
      state: addressDetails.state,
      zipcode: addressDetails.zipCode,
    };
    try {
      const response = await axios.post(Url.BASEURL + `/broker/create-stripe-account`, dataToSend, config);
      if (response.data.success) {
        const linkToNavigate = response.data.link;
        setIsStripeAccountCreated(true);
        setShowCreateAccount(false);
        window.location.href = linkToNavigate;
      } else {
        console.error("Account creation failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error creating account:", error);
    } finally {
      setIsCreatingAccount(false);
    }
  };

  const handleBuyNow = async () => {
    setIsDisable(true);
    setError("");
    try {
      const response = await axios.post(Url.BASEURL + `/broker/create-checkout`, {}, config);
      setIsDisable(false);
      if (response.data.success) {
        const linkToNavigate = response.data.session.sessionUrl;
        window.location.href = linkToNavigate;
      }
    } catch (err) {
      setIsDisable(false);
      setError(err?.response?.data?.error ? err?.response?.data?.error : err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (isPollingStart === "true") {
      const interval = setInterval(() => {
        fetchBrokerProfile();
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [isPollingStart]);

  const handleCreateAndUpdate = async () => {
    await UpdateBrokerBillinginfo();
    if (!updateError) {
      handleCreateAccount();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumbs crumbs={isOnBoarded ? (navigateFrom === "package" ? breadcrumbDataPackage : breadcrumbDataMls) : breadcrumbData} />
      <h1>Billing Information</h1>
      <p className="text-center ttp">Please enter your billing information and check that you agree to the terms of service. Clicking Buy Now will finalize your purchase.</p>

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader color="#22A9E0" loading={loading} size={100} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <div className="full_billing_info d-flex">
              <div className="left_billing_info">
                <div className="all_bblock_data">
                  <h3>Account Information</h3>
                  <div className="single_bill_data ba1">{brokerData.firstname + " " + brokerData.lastname}</div>
                  <div className="single_bill_data ba2">{brokerData.phone}</div>
                  <div className="single_bill_data ba3">{brokerData.email}</div>
                  <div className="single_bill_data ba4">
                    License : {brokerData.contactDetails && brokerData.contactDetails.license ? brokerData.contactDetails.license : ""}
                    <span className="verifies_image"></span> <img src={Verified} alt="verified" />
                    <span className="verifies_text">verified</span>
                  </div>
                  <div className="single_bill_data ba5">{brokerData.contactDetails && brokerData.contactDetails.address ? brokerData.contactDetails.address : ""}</div>
                </div>
              </div>
              <div className="right_billing_info">
                <h3>Address Details</h3>
                <input type="checkbox" checked={isDifferentAddress} onClick={() => setIsDifferentAddress(!isDifferentAddress)} className="invoice-checks billing_checks" />
                <span className="checker">Different Address on Billing</span>
                <div className="contant_location_form hallow company_links d-flex title_bar_setting ">
                  <div className="row">
                    <div className="col-lg-6">
                      <label>Address</label>
                      <input name="address" type="text" className="form-control" placeholder="Enter your address" readOnly={!isDifferentAddress} value={addressDetails.address} onChange={handleChange} />
                      {updateError && (
                        <div className="error-message" style={{ color: "red", fontSize: "13px " }}>
                          {updateError.address}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label>City</label>
                      <input name="city" type="text" className="form-control" readOnly={!isDifferentAddress} value={addressDetails.city} placeholder="Enter your city" onChange={handleChange} />
                      {updateError && (
                        <div className="error-message" style={{ color: "red", fontSize: "13px " }}>
                          {updateError.city}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label>State</label>
                      <input name="state" type="text" className="form-control" placeholder="Enter your state" readOnly={!isDifferentAddress} value={addressDetails.state} onChange={handleChange} />
                      {updateError && (
                        <div className="error-message" style={{ color: "red", fontSize: "13px " }}>
                          {updateError.state}
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6">
                      <label>Zip Code</label>
                      <input name="zipCode" type="number" maxLength={5} className="form-control" placeholder="Enter your zip code" readOnly={!isDifferentAddress} value={addressDetails.zipCode} onChange={handleChange} />
                      {updateError && (
                        <div className="error-message" style={{ color: "red", fontSize: "13px " }}>
                          {updateError.zipCode}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {isPollingStart === "true" ? (
              <div className="full_striope">
                <div className="stripe_area d-flex py-3 gap-4">
                  <ClipLoader color="#22A9E0" loading={isPollingStart === "true"} size={50} aria-label="Loading Spinner" data-testid="loader" />
                  <div className="rstripe_text">
                    <span style={{ textDecoration: "underline", fontWeight: 600 }}>Note:</span> It will take some time to setup your stripe account. Please wait for few seconds...
                  </div>
                </div>
              </div>
            ) : (
              <>
                {showCreateAccount && (
                  <div className="full_striope">
                    <div className="stripe_area d-flex">
                      <div className="left_stripe d-flex">
                        <div className="lstripe_image">
                          <img src={Stripe} alt="mls" />
                        </div>
                        <div className="rstripe_text">Kindly note that a secure Stripe login is required for all payments.</div>
                      </div>
                      <div className="right_stripe">
                        <button onClick={handleCreateAndUpdate} className="login_stripe" disabled={isCreatingAccount}>
                          {isCreatingAccount ? (
                            <span className="loading-span">
                              <Oval visible={true} height="20" width="20" color="#4fa94d" ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" />
                              Create Account
                            </span>
                          ) : (
                            "Create Account"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {isStripeAccountCreated && (
                  <>
                    {error && (
                      <p className="mt-4" style={{ fontSize: "12px", color: "red" }}>
                        {error}
                      </p>
                    )}
                    <button disabled={isDisable} className="next_btns curtain d-flex justify-content-center" onClick={handleBuyNow}>
                      Buy Now
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </DefaultLayout>
  );
}

export default BillingInfo;
