import React from "react";
import { useSelector } from "react-redux";

const TransactionData = ({ allTransactions }) => {
  const userType = useSelector((state) => state.userType);

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObj);
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <tr>
      {/* Condition 1: No realtorName */}
      {!allTransactions.realtorName && (
        <>
          {userType === 1 ? (
            <td>
              MLS <span className="blacking">({allTransactions.selectedMlsCount})</span> : <span className="blacking">${allTransactions.selectedMlsPrice}</span> + Wovnn Idx Fees : <span className="blacking">${allTransactions.idxMlsfee}</span>
              <br />
              Realtor Seats <span className="blacking">({allTransactions.totalRealtorcountunderBroker})</span> : <span className="blacking">${allTransactions.totalRealtorpriceunderBroker}</span>
            </td>
          ) : (
            <td>
              Amount <span className="blacking">(${allTransactions.amount})</span>
            </td>
          )}
          {userType === 1 && (
            <>
              <td>
                <div className="pamentse_link">
                  <div className="price_numberss">--</div>
                </div>
              </td>
              <td>
                <div className="wovnn_amount">
                  ${allTransactions.wovnnFees}
                  <p>{allTransactions.brokerName}</p>
                </div>
              </td>
            </>
          )}
        </>
      )}

      {/* Condition 2: RealtorName with setup fee */}
      {allTransactions.realtorName && allTransactions.feetype && (
        <>
          <td>
            Set Up Fees (set by me) : <span className="blacking">${allTransactions.brokerSetUpFee}</span> <span className="percentByadmin">-{allTransactions.setupfeemarginpercentbyAdmin}% to Wovnn</span>
          </td>
          <td>
            <div className="pamentse_link">
              <div className="price_numberss">${allTransactions.amount}</div>
              <p className="text-capitalize " style={{ color: "#22A9E0", fontSize: "14px" }}>
                {allTransactions.realtorName}
              </p>
            </div>
          </td>
          <td>
            <div className="wovnn_amount">
              ${allTransactions.wovnnCut}
              <p>{allTransactions.brokerName}</p>
            </div>
          </td>
        </>
      )}

      {/* Condition 3: RealtorName with no setup fee */}
      {allTransactions.realtorName && !allTransactions.feetype && (
        <>
          <td>
            Wovnn Seat fee for {formatDate(allTransactions.date)}: <span className="blacking">${allTransactions.adminSetUpFee}</span>
          </td>
          <td>
            <div className="pamentse_link">
              <div className="price_numberss">${allTransactions.amount}</div>
              <p className="text-capitalize" style={{ color: "#22A9E0", fontSize: "14px" }}>
                {allTransactions.realtorName}
              </p>
            </div>
          </td>
          <td>
            <div className="wovnn_amount">
              ${allTransactions.wovnnCut}
              <p>{allTransactions.brokerName}</p>
            </div>
          </td>
        </>
      )}

      {/* Render status cell */}
      <td className={`${allTransactions.status === "Successful" ? "successess" : allTransactions.status === "Delinquent" ? "delquent" : allTransactions.status === "Pending" ? "pendinggs" : ""}`}>
        <div className="status">
          {allTransactions.realtorName ? (
            // Display realtor transaction data
            <>
              +${allTransactions.brokerFinalAmount}
              <br />
              <span className={allTransactions.status}>
                {allTransactions.status === "succeeded" ? "Successful" : "Failed"}:{" "}
                <span className="date_set">
                  {new Date(allTransactions.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>
              </span>
            </>
          ) : (
            // Display non-realtor transaction data
            <>
              ${allTransactions.amount}
              <br />
              <span className={allTransactions.status}>
                {allTransactions.status === "succeeded" ? "Successful" : "Failed"}:{" "}
                <span className="date_set">
                  {new Date(allTransactions.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>
              </span>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default TransactionData;
