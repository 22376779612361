import React, { useEffect, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Payment from "../../assets/payment.gif";
import DefaultLayout from "../../layouts/DefaultLayout";
import { setAgentType, setBrokerProfileDetails, setCompanyname, setEmail, setFirstName, setFullName, setLastName, setProfileImage, setRefreshToken, setRegToken, setSubscribed, setTokenExpTime, setUserType } from "../../Redux/Action/Authaction";

const PaymentSuccessfull = () => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const { stripe_id } = useParams();
  const [loading, setLoading] = useState(true);

  const handleFetchData = () => {
    axios
      .get(Url.BASEURL + `/broker/broker-login-stripe_customer_id?stripeCustomerId=${stripe_id}`)
      .then((value) => {
        const res = value.data;
        dispatch(setUserType(res?.userType));
        localStorage.setItem("userType", res?.userType);
        dispatch(setRegToken(res?.accessToken));
        dispatch(setSubscribed(value?.data?.subscribed));
        dispatch(setRefreshToken(res?.refreshToken));
        dispatch(setTokenExpTime(res?.expirationTime));
        dispatch(setEmail(res?.user?.email));
        dispatch(setFirstName(res?.user?.firstname));
        dispatch(setLastName(res?.user?.lastname));
        dispatch(setCompanyname(res?.user?.companyName));
        dispatch(setAgentType(res?.user?.agentType));
        dispatch(setProfileImage(Url.IMGURL + res?.user?.image));
        dispatch(setBrokerProfileDetails(res?.user));
        const fullName = `${res?.user?.firstname} ${res?.user?.lastname}`;
        dispatch(setFullName(fullName));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    localStorage.removeItem("isPollingStart");
    if (stripe_id) {
      handleFetchData();
    } else {
      setLoading(false);
    }
  }, [stripe_id]);

  const handleContinue = () => {
    naviagte("/");
  };

  return (
    <DefaultLayout>
      <div className="payment-success">
        <h1>Your Payment is Successful.</h1>
        <div className="d-flex justify-content-center">
          <img src={Payment} alt="" height={200} />
        </div>
        <p className="text-center ttp mt-5 ">We're delighted to inform you that your payment has been successfully processed and confirmed. Thank you for your transaction, and we look forward to serving you further.</p>

        <div className="row">
          <button className="next_btns curtain d-flex justify-content-center" onClick={handleContinue}>
            Continue
          </button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default PaymentSuccessfull;
