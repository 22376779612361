import React from "react";
import { Link } from "react-router-dom";
import TransctionDetailModal from "./TransctionDetailModal";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

const data = [
  { month: "Jan 2023", medianList: 10000, medianSold: 200000 },
  { month: "Feb 2023", medianList: 200000, medianSold: 100000 },
  { month: "Mar 2023", medianList: 30000, medianSold: 1250 },
  { month: "Apr 2023", medianList: 502144, medianSold: 260000 },
  { month: "May 2023", medianList: 0, medianSold: 260000 },
  { month: "June 2023", medianList: 310000, medianSold: 521254 },
  { month: "July 2023", medianList: 310000, medianSold: 260000 },
  { month: "Aug 2023", medianList: 340000, medianSold: 290000 },
];

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#B4B9C8" className="tick-label">
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props;
  const formattedTick = `$${payload.value / 1000}K`;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={-16} textAnchor="end" fill="#B4B9C8" className="tick-label">
        {formattedTick}
      </text>
    </g>
  );
};

const dateOptions = {
  day: "2-digit",
  month: "long",
  year: "numeric",
};

const ProfileData = ({ loading, transactions, totalPages, onSetSelectedOptionRealtor, onSetSelectedOption }) => {
  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(dateObj);
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className="row">
      <div className="col-lg-7 col-md-12 col-sm-12 scf">
        <div className="mutual_blocks borrom-blocks">
          <div className="heading_inline d-flex">
            <div className="left_head">
              <h2>Activity Log</h2>
            </div>
            <div className="right_head">
              <Link to="" className="details-link">
                More Details
              </Link>
            </div>
          </div>

          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeWidth={0.5} vertical={false} stroke="#efefef" />
              <XAxis dataKey="month" tick={<CustomizedXAxisTick />} />
              <YAxis tick={<CustomizedYAxisTick />} domain={[0, 1000000]} />
              <Tooltip />
              <Area type="monotone" dataKey="medianSold" stackId="1" stroke="#007AFF" fill="#007AFF" fillOpacity={1} />
              <Area type="monotone" dataKey="medianList" stackId="1" stroke="#22A9E0" fill="#22A9E0" fillOpacity={1} />
            </AreaChart>
          </ResponsiveContainer>
          <div className="mean_median d-flex">
            <div className="left_mean">
              <span></span> Median List Price
            </div>
            <div className="right_mean">
              <span></span> Median Sold Price
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5 col-md-12 col-sm-12">
        <div className="mutual_blocks ">
          <div className="heading_inline d-flex">
            <div className="left_head">
              <h2>Realtor Transaction</h2>
            </div>
            <div className="right_head">
              <div className="details-link" data-bs-toggle="modal" data-bs-target="#addRealtorTransactionModal">
                View all
              </div>
            </div>
          </div>
          <div className="payment_type_status">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Payment Type</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.slice(0, 3).map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        {/* Condition 2: RealtorName with setup fee */}
                        {transaction.realtorName && transaction.feetype ? (
                          <>
                            <div>
                              Set Up Fees (set by broker) : <span className="blacking">${transaction.brokerSetUpFee}</span>
                            </div>
                          </>
                        ) : null}
                        {/* Condition 3: RealtorName with no setup fee */}
                        {transaction.realtorName && !transaction.feetype ? (
                          <div>
                            Monthly Subscription for {formatDate(transaction.date)} : <span className="blacking">({transaction.adminSetUpFee})</span>
                          </div>
                        ) : null}
                      </td>
                      <td>
                        <div className="status">
                          ${transaction.amount}
                          <br />
                          <span className="success">
                            {transaction.status === "succeeded" ? "Successful" : "Failed"}: <span className="date_set">{new Date(transaction.date).toLocaleString(undefined, dateOptions)}</span>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* The Modal */}
      <TransctionDetailModal loading={loading} transactions={transactions} totalPages={totalPages} onSetSelectedOptionRealtor={onSetSelectedOptionRealtor} onSetSelectedOption={onSetSelectedOption} />
    </div>
  );
};

export default ProfileData;
