import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Url from "../Element/Api";
import "../PackagesProcess/Packages.css";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Breadcrumbs } from "../../utils/utils";
import DefaultLayout from "../../layouts/DefaultLayout";

function Mls() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [mlsidxfeebyWovnn, setMlsidxfeebyWovnn] = useState([]);
  const brokerProfileDetails = useSelector((state) => state.brokerProfileDetails);
  const isOnBoarded = brokerProfileDetails?.stripe_account_id && brokerProfileDetails?.stripe_customer_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = searchTerm.trim() !== "" ? `${Url.BASEURL}/broker/get-mls-info?search=${searchTerm}` : `${Url.BASEURL}/broker/get-mls-info`;
        const response = await axios.get(url, config);
        if (searchTerm.trim() !== "") {
          setSearchResults(response.data.mlsInfo);
          setMlsidxfeebyWovnn(response.data.mlsidxfee.price);
        } else {
          setData(response.data.mlsInfo);
          setMlsidxfeebyWovnn(response.data.mlsidxfee.price);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchTerm]);

  const handleAddToSelected = (item) => {
    setSelectedItems([...selectedItems, item]);
  };

  // Breadcrumbs data
  const breadcrumbData = [{ path: "/choose-package", label: "Packages" }, { label: "MLS" }];

  const breadcrumbData2 = [{ path: "/", label: "Dashboard" }, { label: "MLS" }];

  const handleDelete = (id) => {
    setData((prevData) => prevData.filter((item) => item._id !== id));
  };

  const calculateTotal = () => {
    const items = searchTerm ? searchResults : data;
    return items?.reduce((total, item) => total + item?.price, 0)?.toFixed(2);
  };

  const handleNext = async () => {
    try {
      // const selectedMLS = data.map(({ _id, price}) => ({ _id, price}));
      const selectedMLS = data.map(({ _id }) => _id);
      await axios.post(`${Url.BASEURL}/broker/choose-mls`, { selectedMLS }, config);
      localStorage.setItem("navigateFrom", "mls");
      if (isOnBoarded) {
        navigate("/pricing-review");
      } else {
        navigate("/register-domain");
      }
    } catch (error) {
      console.error("Error Selecting MLS: ", error);
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumbs crumbs={isOnBoarded ? breadcrumbData2 : breadcrumbData} />
      <h1>Choose your MLS</h1>
      <div className="row mb-3 ">
        <div className="col-lg-12">
          <div className="full_table_area">
            <div className="top_table_row d-flex">
              <div className="left_t_row">
                <div className="search_area d-flex">
                  <div className="search_bloxk">
                    <input
                      type="search"
                      className="form-control me-2"
                      placeholder="Search MLS here"
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                      }}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="right_t_row">
                Wovnn MLS Charges <span className="number_list_count">(1)</span> : <span className="dollar_symbol">$</span>
                <span className="wovnn_commission">{mlsidxfeebyWovnn}</span>
              </div>
            </div>

            <div className="mls_listing_invoice">
              {loading ? (
                <div className="text-center">
                  <ClipLoader color="#22A9E0" loading={loading} size={100} aria-label="Loading Spinner" data-testid="loader" />
                </div>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">MLS List</th>
                      <th scope="col">Location</th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        Price
                      </th>
                      {/* <th scope="col"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {(searchTerm ? searchResults : data)?.map((item) => (
                      <tr key={item._id} onClick={() => handleAddToSelected(item)}>
                        <td className="td-image">
                          {/* <img src={item.image} alt="MLS" /> */}
                          <span>{item.mlsName}</span>
                        </td>
                        <td className="td-locations">
                          {/* {item.locations.join(", ")}
                                    {item.locations
                                      ? item.locations.join(", ")
                                      : ""} */}
                          <span>{item.location}</span>
                        </td>
                        <td className="td-price" style={{ textAlign: "right" }}>
                          ${item.price}
                        </td>
                        {/* <td className="td-delete">
                                    <img
                                      src={Close}
                                      onClick={() => handleDelete(item._id)}
                                      alt="Delete"
                                    />
                                  </td> */}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="mls_listing_invoice ">
                      <td className="td-image">
                        <div className="Total_price">Total price</div>
                      </td>
                      <td></td>
                      <td className="tb_calc" style={{ textAlign: "right" }}>
                        <div className="table_calc">${calculateTotal()}</div>
                      </td>
                      {/* <td></td> */}
                    </tr>
                    <tr>
                      <td className="next_button"></td>
                      <td className="next_button"></td>
                      {/* <td className="next_button"></td> */}
                      <td className="next_button" style={{ textAlign: "right" }}>
                        <button className="next_btns" onClick={handleNext}>
                          Next
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default Mls;
