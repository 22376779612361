import axios from "axios";
import Url from "../../Element/Api";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import useAuth from "../../../hooks/useAuth";
import Password from "../../../assets/a2.svg";

const ChangePasswordModal = ({ show, onHide }) => {
  const [config] = useAuth();
  const [error, setError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleSubmitPasswordChange = (e) => {
    const params = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };
    axios
      .post(`${Url.BASEURL}/broker/change-password`, params, config)
      .then((value) => {
        if (value.data.success !== false) {
          onHide();
        } else {
          // Handle different condition
        }
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError("An error occurred while processing your request.");
        }
      });
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" className="milko">
      <Modal.Body>
        <div className="profile_form_entries change_pass passw_changing">
          <div className="forms_area">
            {error ? (
              <div
                style={{
                  textAlign: "center",
                  margin: "10px 0px",
                  background: "#FFCCCB",
                  color: "red",
                }}
                className="alert "
                role="alert"
              >
                {error}
              </div>
            ) : null}
            <div className="single_fields">
              <label>Current Password</label>
              <input value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} type="password" className="form-control" placeholder="Write current password*" />
              <div className="u_icon">
                <img src={Password} alt="user" />
              </div>
            </div>
            <div className="single_fields">
              <label>New Password</label>
              <input value={newPassword} onChange={(e) => setNewPassword(e.target.value)} type="password" className="form-control" placeholder="Write new password*" />
              <div className="u_icon">
                <img src={Password} alt="user" />
              </div>{" "}
            </div>
            <div className="single_fields">
              <label>New Password Again</label>
              <input value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} type="password" className="form-control" placeholder="Write new password again*" />
              <div className="u_icon">
                <img src={Password} alt="user" />
              </div>{" "}
            </div>
            <div className="btns_call d-flex">
              <button onClick={handleSubmitPasswordChange} className="logout save_btn">
                Save
              </button>
              <button className="hanul" onClick={onHide}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
