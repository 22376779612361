import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import Url from "../Element/Api";
import "../PackagesProcess/Packages.css";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../utils/utils";
import Register from "../../assets/register-domain.svg";
import DefaultLayout from "../../layouts/DefaultLayout";

function RegisterDomain() {
  const [config] = useAuth();
  const navigate = useNavigate();
  const [domainInput, setDomainInput] = useState("");
  const navigateFrom = localStorage.getItem("navigateFrom");
  const [checkDomainInput, setCheckDomainInput] = useState("");
  const [err, setErr] = useState({ checkDomain: "", addDomain: "" });
  const [addDomainRes, setAddDomainRes] = useState({ message: "", success: true });
  const [checkDomainRes, setCheckDomainRes] = useState({ message: "", success: true });

  const breadcrumbData = [
    // { path: "/", label: "Dashboard" },
    { path: "/choose-package", label: "Packages" },
    { path: "/packages-mls", label: "Mls" },
    { label: "Domain" },
  ];

  const handleAdminDomain = async () => {
    setErr({ ...err, addDomain: "" });
    setAddDomainRes({ message: "", success: true });
    if (domainInput !== "") {
      try {
        const requestData = {
          subdomainName: domainInput,
        };
        const response = await axios.post(`${Url.BASEURL}/broker/choose-subdomain`, requestData, config);
        if (response.status === 200) {
          navigate("/pricing-review");
        }
        setAddDomainRes(response?.data);
      } catch (error) {
        setAddDomainRes(error.response.data);
      }
    } else {
      setErr({ ...err, addDomain: "Field is required" });
    }
  };

  const handleCheckDomian = async () => {
    setErr({ ...err, checkDomain: "" });
    setCheckDomainRes({ message: "", success: true });
    if (checkDomainInput !== "") {
      try {
        const requestData = {
          subdomainName: checkDomainInput,
        };
        const response = await axios.post(`${Url.BASEURL}/broker/check-domain`, requestData, config);
        setCheckDomainRes(response?.data);
      } catch (error) {
        setCheckDomainRes(error.response.data);
      }
    } else {
      setErr({ ...err, checkDomain: "Field is required" });
    }
  };

  const handleSkipAndChooseDefaultSubdomain = async () => {
    try {
      await handleSkip();
      await handleChooseDefaultSubdomain();
    } catch (error) {
      console.error("Error occurred while calling functions:", error);
    }
  };

  const handleSkip = () => {
    axios
      .get(`${Url.BASEURL}/broker/get-broker-current-package-info`, config)
      .then((res) => {
        if (res?.data?.data?.subscriptionEndDate) {
          let remainingTime = moment(res?.data?.data?.subscriptionEndDate).diff(moment(new Date()), "days");
          if (remainingTime < 1) {
            console.log("1");
            navigate("/pricing-review");
          } else {
            navigate("/");
          }
        } else {
          console.log("2");
          navigate("/pricing-review");
        }
      })
      .catch((err) => {
        console.log("3");
        navigate("/pricing-review");
      });
  };

  const handleChooseDefaultSubdomain = async () => {
    try {
      const res = await axios.post(`${Url.BASEURL}/broker/choose-default-subdomain`, {}, config);
      return res.data;
    } catch (error) {
      console.error("Error choosing default subdomain:", error.response.data);
      throw error;
    }
  };

  return (
    <DefaultLayout>
      {navigateFrom !== "login" && <Breadcrumbs crumbs={breadcrumbData} />}

      <h1>Register a Domain</h1>
      <div className="row mb-3">
        <div className="col-lg-12">
          <div className="register_domain d-flex">
            <div className="domain_left">
              <p>Here you can select the domain that you want attached to your account. We can register a new domain for you or you can use a domain that you already have. If you're not sure what you want to do, you can leave this step for later.</p>
              <div className="dos_image">
                <img src={Register} className="domain_image" alt="domain" />
              </div>
            </div>
            <div className="domain_left domain_check">
              <div className="single_domain">
                <h3>Enter the Domain you want :</h3>
                <div className="search_area d-flex">
                  <div className="www">www.</div>
                  <div className="search_bloxk">
                    <input disabled type="search" className="form-control me-2" placeholder="Search domain" value={checkDomainInput} onChange={(e) => setCheckDomainInput(e.target.value)} />
                  </div>
                  <div className="btn_block">
                    <button disabled className="btn btn-primary domain-btn" onClick={handleCheckDomian}>
                      Check
                    </button>
                  </div>
                </div>
                {err.checkDomain !== "" && <p style={{ fontSize: "13px", color: "red" }}>{err.checkDomain}</p>}
                {checkDomainRes?.message?.length !== "" && <p style={{ fontSize: "13px", color: `${checkDomainRes?.success ? "green" : "red"}` }}>{checkDomainRes?.message}</p>}
              </div>
              <div className="or">Or</div>

              <div className="single_domain second_domain">
                <h3>Use Domain you already :</h3>
                <div className="search_area d-flex">
                  <div className="www">www.</div>
                  <div className="search_bloxk">
                    <input disabled type="search" className="form-control me-2" placeholder="Add your domain" value={domainInput} onChange={(e) => setDomainInput(e.target.value)} />
                  </div>
                  <div className="btn_block">
                    <button disabled className="btn btn-primary domain-btn" onClick={handleAdminDomain}>
                      Add Domain
                    </button>
                  </div>
                </div>
                {addDomainRes?.message?.length !== "" && <p style={{ fontSize: "13px", marginTop: "2px", color: `${addDomainRes?.success ? "green" : "red"}` }}>{addDomainRes?.message}</p>}
                {err.addDomain !== "" && <p style={{ fontSize: "13px", color: "red", marginTop: "2px" }}>{err.addDomain}</p>}
                <p>If you choose to add your domain and verify with your hosting provider for seamless service continuation.</p>
              </div>
            </div>
          </div>
          <div className="bot_tab_btns text-center">
            <button className="next_btns" onClick={handleSkipAndChooseDefaultSubdomain}>
              Skip for Now
            </button>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}

export default RegisterDomain;
