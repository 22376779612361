import React, { useRef, useState } from "react";
import axios from "axios";
import Url from "../Element/Api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";
import { setRegEmail } from "../../Redux/Action/Authaction";

const RegisterSection = ({ activeSection, error, onSetError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nameInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userInputForRegister, setUserInputForRegister] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    password: "",
  });

  const handleChangeForRegister = (e) => {
    const { name, value } = e.target;
    setUserInputForRegister({
      ...userInputForRegister,
      [name]: value,
    });
    if (value.trim() !== "") {
      onSetError((prevError) => {
        const { [name]: removedError, ...rest } = prevError;
        return rest;
      });
    }
  };

  const submitGetWebsite = async (e) => {
    try {
      setLoading(true);
      const params = {
        firstname: userInputForRegister.firstname,
        lastname: userInputForRegister.lastname,
        phone: userInputForRegister.phone,
        email: userInputForRegister.email,
        password: userInputForRegister.password,
      };
      const response = await axios.post(Url.BASEURL + "/broker/register", params);
      if (response.data.success === true) {
        setLoading(false);
        dispatch(setRegEmail(response.data.email));
        navigate("/email-verify");
      }
    } catch (err) {
      setLoading(false);
      if (err.response.data.errors) {
        const apiErrors = err.response.data.errors;
        onSetError(apiErrors || {});
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitGetWebsite();
    }
  };

  return (
    <>
      {error?.message && <p style={{ color: "red", fontSize: "13px", textAlign: "center" }}>{error?.message}</p>}
      <div className={`section mt-4 register ${activeSection === "register" ? "active" : "d-none"}`}>
        <div>
          <div className="row mb-3">
            <div className="col mcol">
              <input required type="text" name="firstname" value={userInputForRegister.firstname} className="form-control" placeholder="First Name*" onChange={handleChangeForRegister} onKeyDown={handleKeyDown} ref={nameInputRef} onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))} style={{ textTransform: "capitalize" }} />
              {error.firstname && <p style={{ color: "red", fontSize: "13px" }}>{error.firstname}</p>}
            </div>
            <div className="col mcol">
              <input type="text" name="lastname" value={userInputForRegister.lastname} className="form-control" placeholder="Last Name*" onChange={handleChangeForRegister} onKeyDown={handleKeyDown} onInput={(e) => (e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, ""))} style={{ textTransform: "capitalize" }} />
              {error.lastname && <p style={{ color: "red", fontSize: "13px" }}>{error.lastname}</p>}
            </div>
          </div>
          <div className="mb-3">
            <input pattern="[1-9]{1}[0-9]{9}" maxLength={15} value={userInputForRegister.phone} name="phone" type="text" className="form-control" placeholder="Phone No.*" onChange={handleChangeForRegister} onInput={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))} />
            {error.phone && <p style={{ color: "red", fontSize: "13px" }}>{error.phone}</p>}
          </div>
          <div className="mb-3">
            <input type="email" name="email" value={userInputForRegister.email} className="form-control" placeholder="Email Address*" onChange={handleChangeForRegister} onKeyDown={handleKeyDown} />
            {error.email && <p style={{ color: "red", fontSize: "13px" }}>{error.email}</p>}
          </div>
          <div className="mb-3">
            <input type="password" name="password" value={userInputForRegister.password} className="form-control" placeholder="Password*" onChange={handleChangeForRegister} onKeyDown={handleKeyDown} />
            {error.password && <p style={{ color: "red", fontSize: "13px" }}>{error.password}</p>}
          </div>
          <button
            onClick={submitGetWebsite}
            onKeyUp={(e) => e.key === "enter" && submitGetWebsite(e)}
            onKeyDown={(e) => e.key === "enter" && e.preventDefault()}
            className="btn btn-primary logreg_btn"
            tabIndex="0" // Ensure the button is focusable
            disabled={loading}
          >
            {loading ? (
              <>
                <span>Get Your Website</span>
                <ClipLoader color="#ffffff" loading={loading} size={20} aria-label="Loading Spinner" data-testid="loader" />
              </>
            ) : (
              "Get Your Website"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default RegisterSection;
