import React from "react";
import { useNavigate } from "react-router";
import Payment from "../../assets/payment.gif";
import DefaultLayout from "../../layouts/DefaultLayout";

const PaymentFailed = () => {
  const naviagte = useNavigate();

  const handleContinue = () => {
    localStorage.removeItem("isPollingStart");
    naviagte("/billing-info");
  };

  return (
    <DefaultLayout>
      <div className="payment-success">
        <h1>Payment Failed</h1>
        <div className="d-flex justify-content-center">
          <img src={Payment} alt="" height={200} />
        </div>
        <p className="text-center ttp mt-5 ">Unfortunately, your payment could not be processed at this moment. Please try again to complete your transaction and continue with us. Thank you for your patience and understanding.</p>
        <div className="row">
          <button className="next_btns curtain d-flex justify-content-center" onClick={handleContinue}>
            Try Again
          </button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default PaymentFailed;
