import axios from "axios";
import Url from "../../Element/Api";
import useAuth from "../../../hooks/useAuth";
import Pending from "../../../assets/pending.gif";
import React, { useEffect, useState } from "react";
import Verified from "../../../assets/verified.svg";

const InviteAgainModal = ({ onSaveTeammateData, isFormVisible, onSetIsFormVisible, isInvitationSentPopupVisible, isPendingPopupVisible, onSetIsPendingPopupVisible }) => {
  const [config] = useAuth();
  const [invitationagainsuccess, setInvitationagainsuccess] = useState(false);

  const handleInviteAgainClick = () => {
    const realtors = [
      {
        Firstname: onSaveTeammateData.firstname,
        Lastname: onSaveTeammateData.lastname,
        Email: onSaveTeammateData.email,
        PhoneNo: onSaveTeammateData.phone,
      },
    ];
    axios
      .post(Url.BASEURL + "/broker/invite-again-realtor", { realtors }, config)
      .then((res) => {
        if (res.data) {
          onSetIsPendingPopupVisible(false);
          setInvitationagainsuccess(true);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    let timer;
    if (!isFormVisible || invitationagainsuccess) {
      timer = setTimeout(() => {
        const closeButton = document.querySelector(".cross_btn");
        if (closeButton) {
          closeButton.click();
        }
        setInvitationagainsuccess(false);
        onSetIsFormVisible(true);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [isFormVisible, invitationagainsuccess]);

  return (
    <div className="popup_bhe">
      {(isPendingPopupVisible || isInvitationSentPopupVisible) && <div className="modal-harddrop"></div>}
      {invitationagainsuccess && <div className="modal-harddrop"></div>}
      {isPendingPopupVisible && (
        <div className="modal fade show pending_realtor" id="pendingInvite" style={{ display: "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h2>This realtor's profile is still pending.</h2>
                <img src={Pending} alt="pending" />
                <p>Send another invitation to kickstart their journey on the platform and amplify your collaborative efforts.</p>
                <div className="two_tube_btns d-flex">
                  <div className="btn_tt_one" onClick={handleInviteAgainClick}>
                    Invite Again
                  </div>
                  <div className="halks_btn" onClick={() => onSetIsPendingPopupVisible(false)}>
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {invitationagainsuccess && (
        <div className="modal fade show pending_realtor" id="invitationSuccessModal" style={{ display: "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <div className=" verification_done add_realtor_message emails_verification">
                  {/* <div className="cross_btn faloos" data-bs-toggle="modal" data-bs-target="#addRealtorModal">
                  <img src={Close} className="close" />
                </div> */}
                  <h3>Invitation Sent</h3>
                  <p>
                    You've successfully extended an invitation to{" "}
                    <div className="emailer_name">
                      <strong>{onSaveTeammateData.Email}</strong>
                    </div>{" "}
                  </p>
                  <img src={Verified} alt="verified" />
                  <h4>
                    Await their response and welcome them to your real <br></br>estate network.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InviteAgainModal;
