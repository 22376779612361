import { Link } from "react-router-dom";
import LeftArrow from "../assets/larrow.svg";
import Arrow from "../assets/breads-aro.svg";
// #1 import quill-image-uploader
import ReactQuill, { Quill } from "react-quill";
import ImageCompress from 'quill-image-compress';

Quill.register('modules/imageCompress', ImageCompress);


export const editorStyle = {
  maxWidth: "100% !important",
};

export const exceptThisSymbols = ["e", "E", "+", "-", "."];

export const formats = ["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image", "video", "color", "background", "align", "direction"];

export const modules = {
  toolbar: [[{ header: ["1", "2", "3", "4", "5", "6", false] }], [{ align: [] }], ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], [{ color: [] }, { background: [] }], ["link", "image", "video"]],
  clipboard: {
    matchVisual: false,
  },
  imageCompress: {
    quality: 0.7, // default
    maxWidth: 1000, // default
    maxHeight: 1000, // default
    imageType: ['image/jpeg', 'image/png', 'image/jpg'],// default
    debug: true, // default
    suppressErrorLogging: false, // default
    insertIntoEditor: undefined, // default
  }
};

export const dateFormat = (data) => {
  const date = new Date(data).getDate();
  console.log("date", date);
  const month = new Date(data).toLocaleDateString("en-US", { month: "short" });
  const year = new Date(data).getFullYear();
  return `${date} ${month} ${year}`;
};

export const PreviousLabel = () => (
  <div className="laro">
    <img src={LeftArrow} alt="Previous page" />
    <span>Prev</span>
  </div>
);

export const NextLabel = () => (
  <div className="raro">
    <span>Next</span>
    <img src={LeftArrow} alt="Next page" />
  </div>
);

export const getInitials = (name) => {
  const parts = name.split(" ");
  if (parts.length > 1) {
    return `${parts[0][0].toUpperCase()}${parts[parts.length - 1][0].toUpperCase()}`;
  }
  return name[0].toUpperCase();
};

// Breadcrumbs Component
export const Breadcrumbs = ({ crumbs }) => {
  if (crumbs.length === 0) {
    return null;
  }
  return (
    <ul className="breads">
      {crumbs.map((crumb, index) => {
        const isLast = index === crumbs.length - 1;
        return (
          <li key={index} className="breadcrumb-item">
            {index > 0 && <img src={Arrow} alt="Arrow" className="breadcrumb-arrow" />}
            {isLast ? crumb.label : <Link to={crumb.path}>{crumb.label}</Link>}
          </li>
        );
      })}
    </ul>
  );
};

export const getFaxIdFormat2 = (str) => {
  const newVal = str?.substring(0, 3) + "-" + str?.substring(3, 6) + "-" + str?.substring(6);
  const formatValue = str.replace(/.{3}(?!$)/g, "$&-");
  // const formatValue = str.replace(/(?<=^\d{3}|^\d{7})(?=\d)/g, "$&-")
  // const formatValue = str.replace(/((?<!\d)\d{3}(?!\b)|(?<!\d)\d{3}(?!\b)|(?<=^\d{3})\d{4}(?!\b))/g, "$&-");
  return formatValue;
};

export const getFaxIdFormat = (str) => {
  if (str.length <= 3) {
    return str?.substring(0, 3);
  } else if (str.length > 3 && str.length <= 6) {
    return str?.substring(0, 3) + "-" + str?.substring(3, 6);
  } else {
    return str?.substring(0, 3) + "-" + str?.substring(3, 6) + "-" + str?.substring(6);
  }
};

export const formatCardNumber = (number) => {
  return number
    .replace(/\D/g, "")
    .slice(0, 16)
    .replace(/(\d{4})(?=\d)/g, "$1 ");
};

export const handleDropdownClose = (dropdownRef, handleClose) => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
};
