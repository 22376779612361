import React from "react";
import "./DashboardArea.css";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Dashboard from "./Dashboard/Dashboard";

function DashboardArea() {
  return (
    <div className="dashboard-container">
      <div className="sidebar-main">
        <Sidebar />
      </div>
      <div className="header-dashboard main_container">
        <Header />
        <Dashboard />
      </div>
    </div>
  );
}

export default DashboardArea;
